import React from 'react'
import "./scss/ProjectDescriptionImageInfo.css"
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";


const ProjectDescriptionImageInfo = (props) => {

    const { data } = props

    const settings = {
        dots: true,
        slidesToShow: 1,
        slidesToScroll: 1,
        autoplay: true,
        autoplaySpeed: 2500,
        cssEase: "linear",
        pauseOnHover: true,
        arrows: false,

    };
    return (
        <div className='ProjectDescriptionImageInfoMain'>
            <div className='ProjectDescriptionStart'>
                <div className='ProjectDescriptionImageInformation'>
                    <Box className='ProjectDescriptionInformation'>
                        <Box className='BoxLine'>
                            <Typography variant='h4'>Project Information</Typography>

                        </Box>
                        <Box className='BoxBorderLine'></Box>
                        <Box className='BoxLine'>
                            <Typography variant='h5'>Client :</Typography>
                            <Typography variant='h6'>{data.projClient}</Typography>
                        </Box>
                        <Box className='BoxLine'>
                            <Typography variant='h5'>Location :</Typography>
                            <Typography variant='h6'>{data.projLocation}</Typography>
                        </Box>
                        <Box className='BoxLine'>
                            <Typography variant='h5'>Project Date :</Typography>
                            <Typography variant='h6'>{data.projDate}</Typography>
                        </Box>
                        <Box className='BoxLine'>
                            <Typography variant='h5'>Category :</Typography>
                            <Typography variant='h6'>{data.projCategory}</Typography>
                        </Box>
                        <Box className='BoxLine'>
                            <Typography variant='h5'>Value :</Typography>
                            <Typography variant='h6'>{data.projValue}</Typography>
                        </Box>
                    </Box>
                </div>
                <div className='ProjectDescriptionBGMain'>
                    <Box className='ProjectDescriptionBG'>

                        <Slider {...settings}>
                            {
                                data?.photos?.map((photo) => {
                                    return (
                                        <div className='silderImgProjectDescSlide' >
                                            <img className='silderImgProjectDesc' alt='ProjectImage' src={`${process.env.REACT_APP_STEELADMIN}/project/files/${photo.newprojImageName}`} />
                                        </div>
                                    )
                                })
                            }



                        </Slider>
                    </Box>
                </div>
            </div>
        </div>
    )
}

export default ProjectDescriptionImageInfo
