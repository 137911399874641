import React from 'react'
import Box from '@mui/material/Box';
import { Typography } from '@mui/material';
import './scss/PartnerBenefits.css'
import { useState } from 'react';
import axios from 'axios';
import { useEffect } from 'react';
import { Image } from 'cloudinary-react'
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';

const benefits = [1, 2, 3, 4]

const PartnerBenefits = () => {
    const [data, setData] = useState([])

    const getBenefitsData = async () => {
        try {
            const res = await axios.get(`${process.env.REACT_APP_STEELADMIN}/benefits/getAllBenefits`)
            setData(res.data)
        } catch (error) {
            // console.log(error)
        }
    }

    useEffect(() => {
        getBenefitsData()
    }, [])
    return (
        <div className='partner-benefits'>
            <Box className="PartnerStart">
                <Box className='PartnerContent' sx={{mt:'2%'}}>
                    <Typography variant='h6'>Your benefits as a KJSSTEEL Partner</Typography>
                    {data.map((benefit) =>
                        <Box className='box2' sx={{display:'flex !important'}}>
                            <Box sx={{display:'flex'}}>
                                <CheckCircleOutlineIcon sx={{ color: '#16469d' }} />
                            </Box>
                            <Box className='partner-benefits1' >
                                {benefit.benefitsDesc}
                            </Box>
                        </Box>
                    )}
                </Box>
                <Box className='partnerImg'>
                    <Typography variant='h6'>Your benefits as a KJS STEEL Partner</Typography>
                    <Image className='benefit-image' publicId="https://res.cloudinary.com/dxluokypg/image/upload/v1681293314/pexels-fauxels_cwn7bq.svg" cloudName="dxluokypg" />
                </Box>
            </Box>
        </div>
    )
}

export default PartnerBenefits