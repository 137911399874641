import React from 'react'
import { Container, Box, Typography } from '@mui/material'
import '../OurProjectsPageBnr/scss/OurProjectsPageBnr.css'
import { ReadMore } from '@mui/icons-material'
import { useState } from 'react'



function OurProjectsPageBnr() {
    
    return (

        <div className="backgroundImg" >
            <Box className="bgImgContainer"
                sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    width: '60%',
                    paddingLeft: '6%',
                    alignItems: 'flex-start',
                }}
            >
                <Typography variant="h5">
                    Our Projects
                </Typography>
                <Typography variant='h6' sx={{textAlign:'justify',}}>
                We at KJSS provide end-to-end and customized solutions for our clients in setting up and automating steel plants by using the latest technologies and deploying skilled professionals. The team specializes in the design, installation, engineering, commission, instrumentation, electrical automation, and all steel-related projects. Our goal is to help our clients optimize operations, increase productivity, provide energy-saving solutions, reduce downtime, and improve the bottom line.
                </Typography>
            </Box>
            <Box className='bgImgContainerStart'>
                <Box className='ContainerStartPRoject'>
                    <Typography variant="h5" >
                        Our Projects
                    </Typography>
                    <Typography variant='h6' sx={{textAlign:'justify',}}>
                    We at KJSS provide end-to-end and customized solutions for our clients in setting up and automating steel plants by using the latest technologies and deploying skilled professionals.
                    </Typography>
                </Box>
            </Box>
        </div>

    )
}

export default OurProjectsPageBnr
