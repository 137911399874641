import React from 'react'
import "./scss/AboutUsPageBG.css"
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';



const AboutUsPageBG = () => {
    return (

        <div className='AboutUsPageMainBg'>
            <Box className='AboutUsPageBG'>
                <Typography variant='h6' sx={{ color: '#fff', fontSize: '40px', fontWeight: '700' }}>About Us</Typography>
            </Box>
        </div>
        
    )
}

export default AboutUsPageBG
