import { Typography } from '@mui/material'
import React from 'react'
import "../PartnerBg/scss/PartnerBg.css"

const PartnerBg = () => {
    return (
        <div className='PertnerBGMainStart'>
            <div className='PertnerBGStart'>
                <Typography variant='h4'>Join forces for success: </Typography>
                <Typography variant='h4'>Partner with us.</Typography>
            </div>
        </div>
    )
}

export default PartnerBg
