import { myAxios } from "./helper";

export const signUp = (user) => {
    return myAxios
        .post('http://192.168.0.101:1042/register', user)      
}

export const loginUser=(loginDetail) => {
     myAxios.post('/api/auth/login', loginDetail).then((response)=>{
        if(response.status===200)
        {
            
             return response.data
        }
        return false;
     })
}