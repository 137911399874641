import { Button, Typography } from '@mui/material'
import FaqLandingPage from 'components/FaqLandingPage/FaqLandingPage'
import FAQPageQ from 'components/FAQPageQ/FAQPageQ'
import Footer from 'components/Footer/Footer'
import NavBar from 'components/NavBar/NavBar'
import React, { useState } from 'react'
import { Box } from '@mui/system'
import FaqSection from 'components/FaqSection/FaqSection'
import Modal from '@mui/material/Modal';
import CallOutlinedIcon from '@mui/icons-material/CallOutlined';
import EmailOutlinedIcon from '@mui/icons-material/EmailOutlined';
import CancelIcon from '@mui/icons-material/Cancel';
import "../FAQPage/scss/FAQPage.css"
import { Image } from 'cloudinary-react'
import { Helmet } from "react-helmet";
const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 465,
    height: 275,
    textAlign: 'center',
    bgcolor: 'background.paper',
    borderTop: '8px solid #16469D',
    borderRadius: '4px',
    boxShadow: 24,
    p: 4,
};

const FAQPage = () => {
    const [open, setOpen] = useState(false);
    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);

    return (
        <>
          <Helmet>
                <html lang="en" />
                <title>FAQ | kjssteel| kjs steel</title>
                <meta name="description" content="To know more about our services and products please check out our FAQ page. We will be happy to answer any of your queries." />
                <meta name="title" content="FAQ | kjssteel| kjs steel" />
                <meta name="keywords" content="FAQ | kjssteel| kjs steel" />

            </Helmet>
        <div>
            <NavBar />

            <FaqLandingPage />
            <FaqSection />


            <div className='FaqsMainStart'
                style={{
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'center',
                    alignItems: 'center',
                }}
            >
                <Box className='FaqsMainBox'
                    sx={{
                        width: '70%',
                        my: '5%',
                        bgcolor: '#F6FAFF',
                        padding: '24px',
                        borderRadius: '15px',
                        display: 'flex',
                        justifyContent: 'space-between'
                    }}
                >
                    <div>
                    <Typography
                        variant="h4"
                        sx={{
                            fontWeight: '600',
                            fontSize: '15px',
                            lineHeight: '22px',
                            color: '#656363',
                        }}
                    >
                        Still have questions?
                    </Typography>
                    <Typography
                        variant="p"
                        sx={{
                            fontWeight: '400',
                            fontSize: '12px',
                            lineHeight: '20px',
                            color: '#656363',
                        }}
                    >
                        Can’t find the answer you’re looking for? Please chat to
                        our friendly team.
                    </Typography>
                    </div>
                    <Button
                        variant="contained"
                        onClick={handleOpen}
                        sx={{
                            bgcolor: '#16469D !important',
                            color: '#FFFFFF',
                            p: '7px 13px',
                            lineHeight: '17px',
                            fontSize: '11px',
                            display: { sm: 'block', lg: 'inline' },
                            justifyContent: 'center',
                            mt: { xs: '5%', lg: '0%' },
                            textTransform: 'capitalize',
                            height: '32.71px',
                            '&:hover': { backgroundColor: '#F6FAFF' },
                        }}
                    >
                        Get in touch
                    </Button>
                </Box>
                <Modal className='FAQModalSize'
                    open={open} 
                    onClose={handleClose}
                    aria-labelledby="modal-modal-title"
                    aria-describedby="modal-modal-description"
            
                >
                    <Box className='FaqsModal' sx={style}>
                        <Typography onClick={handleClose} sx={{textAlign: 'right', cursor: 'pointer'}}>
                        <CancelIcon sx={{color: 'gray'}}/>
                        </Typography>
                        <Typography sx={{ fontWeight: '600', fontSize: '20px', color: 'rgba(88, 88, 88, 1)'}} id="modal-modal-title" variant="h6" component="h2">
                            Didn't find what you were looking for?
                        </Typography>
                        <Typography sx={{ fontWeight: '300', fontSize: '12px', color: 'rgba(101, 99, 99, 1)', mt: 2 }} id="modal-modal-description">
                            Looks like you're not quite ready to accept the terms and conditions. That's alright, Could you please let us know what went wrong?
                        </Typography>
                        <div className='FaqsModalButton' style={{ display: 'flex', justifyContent: 'space-between', marginTop: '5%' }}>
                            <a href="https://api.whatsapp.com/send?phone=9321714104&text=I%20am%20interested!" target="_blank" style={{ width: '47%', textDecoration: 'none' }}>
                                <Button className='buttonInModal' sx={{
                                    display: 'flex', justifyContent: 'center', gap: '2%', background: '#16469D', borderRadius: '4px', color: '#ffffff', textTransform: 'capitalize', fontWeight: '500', fontSize: '15px', width: '100%', py: 2, '&:hover': { backgroundColor: '#16469D' },
                                }}><CallOutlinedIcon />Talk to us</Button></a>
                            <a href='mailto:info@kjssteel.com' style={{ width: '47%', textDecoration: 'none' }}>
                                <Button className='buttonInModal' sx={{
                                    display: 'flex', justifyContent: 'center', gap: '2%', background: '#16469D', borderRadius: '4px', color: '#ffffff', textTransform: 'capitalize', fontWeight: '500', fontSize: '15px', width: '100%', py: 2, '&:hover': { backgroundColor: '#16469D' },
                                }}><EmailOutlinedIcon />Ping us here</Button></a>
                                {/* <Button>Ping us here</Button> */}
                        </div>
                    </Box>
                </Modal>
            </div>
            <Footer />

            <div style={{position: 'fixed', bottom: '2rem', right: '2rem', zIndex: '999', backgroundImage: 'none', background: 'none', boxSizing: 'none', borderStyle: 'none',cursor:'pointer'}}>
                <a href="https://api.whatsapp.com/send?phone=9321714104&text=I%20am%20interested!" target="_blank">
                    <Image style={{height: '7vh', width: '7vh',cursor:'pointer',}} publicId="https://res.cloudinary.com/dxluokypg/image/upload/v1680763106/whatsapp_gqgneb.png" cloudName="dxluokypg" />
                </a>
            </div>
        </div>
        </>
    )
}

export default FAQPage
