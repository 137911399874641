import React, { useState, useEffect } from 'react';
import "./scss/VendorRegisterFirst.css"
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import { Typography } from '@mui/material';
import { Link, useParams } from 'react-router-dom'; // Import useParams
import 'react-phone-input-2/lib/style.css';
import PhoneInput from 'react-phone-input-2';
import Modal from '@mui/material/Modal';
import Fade from '@mui/material/Fade';
import { useNavigate } from 'react-router-dom';
import Backdrop from '@mui/material/Backdrop';
import axios from 'axios';
import Slider from "react-slick";

import { MuiOtpInput } from 'mui-one-time-password-input'

import CheckIcon from '@mui/icons-material/Check';
import ErrorIcon from '@mui/icons-material/Error';

import VendorEmailVerification from 'components/VendorEmailVerification/VendorEmailVerification';
import { retry } from '@reduxjs/toolkit/dist/query';
import { color } from 'echarts';
import { Visibility, VisibilityOff } from '@mui/icons-material';
import IconButton from '@mui/material/IconButton';
import useBackdrop from 'hooks/useBackdrop';

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 450,
    height: 304,
    backgroundColor: 'white',
    borderRadius: '4px',
    boxShadow: 4,
    p: 4,
};
const venderSlide = [
    {
        id: 1,
        linkImg: 'https://res.cloudinary.com/dtffniutw/image/upload/v1689518380/Mockup_1_1_vilx0a.svg',
    },
    {
        id: 2,
        linkImg: 'https://res.cloudinary.com/dtffniutw/image/upload/v1689518297/Mockup_PSD_FREE___iPad_Pro___Pencil_2018-removebg-preview_1_bnh73w.svg',
    },
    {
        id: 3,
        linkImg: 'https://res.cloudinary.com/dtffniutw/image/upload/v1689518380/Mockup_1_1_vilx0a.svg',
    },
    {
        id: 4,
        linkImg: 'https://res.cloudinary.com/dtffniutw/image/upload/v1689518297/Mockup_PSD_FREE___iPad_Pro___Pencil_2018-removebg-preview_1_bnh73w.svg',
    },

]
const VendorRegisterFirst = () => {

    const { openBackdrop, closeBackdrop, BackdropComponent } = useBackdrop();

    var settings = {
        fade: true,
        dots: true,
        arrows: false,
        infinite: true,
        slidesToShow: 1,
        slidesToScroll: 1,
        autoplay: true,
        autoplaySpeed: 2000,
        cssEase: 'cubic-bezier(0.7, 0, 0.3, 1)',


    };

    const { id, businessEmail } = useParams();

    const navigate = useNavigate()
    const [requestOtpOpen, setRequestOtpOpen] = useState(false);
    // const handleRequestOtpOpen = () => setRequestOtpOpen(true);
    // const handleRequestClose = () => setRequestOtpOpen(false);
    const [checkData, setCheckData] = useState(null);
    const [phoneVerified, setPhoneVerified] = useState(false);
    const [phone, setPhone] = useState('');
    const [password, setPassword] = useState('');
    const [errors, setErrors] = useState({});
    const [otp, setOtp] = useState('');
    const [emailExists, setEmailExists] = useState('');
    const [userNumberExists, setUserNumberExists] = useState(false);
    const [otpSentAgain, setOtpSentAgain] = useState(false);

    const [isVerified, setIsVerified] = useState(false);
    const [isButtonDisabled, setIsButtonDisabled] = useState(false);
    const [showPassword, setShowPassword] = useState(false);

    const [otpError, setOtpError] = useState(false)

    // *************Timer Set**********
    const [remTime, setRemTime] = useState(60);
    const [isResendButtonDisable, setIsResendButtonDisable] = useState(false);
    const [otpTimer, setOtpTimer] = useState(0);
    const [otpInterval, setOtpInterval] = useState(null);

    const [otpSentSuccessfully, setOtpSentSuccessfully] = useState(false);


    const handleRequestOtpOpen = () => {
        setRequestOtpOpen(true);

    };
    // ****************validation**************************
    const validate = (values) => {
        const errors = {}
        const regexemail = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/
        const regexNumber = /^\+\d{2}[0-6]{10}$/;


        const regexpass = /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[!@#$%^&*]).{8,}$/;

        // console.log(!values.phoneNumber)

        if (!values.name.trim()) {
            errors.name = 'Business name is required!';
        }
        // if (!values.phoneNumber) {
        //     errors.phoneNumber = 'Number is required!'
        // } else if (!regexNumber.test(values.phoneNumber)) {
        //     console.log(values.phoneNumber)
        //     console.log(regexNumber.test(values.phoneNumber))
        //     errors.phoneNumber = 'This is not a valid number format!'
        // }
        if (!values.businessEmail) {
            errors.email = 'Email is required!'
        } else if (!regexemail.test(values.businessEmail)) {
            errors.email = 'This is not a valid email format!'
        }
        if (!values.website) {
            errors.website = 'Website is required!'
        }
        if (!values.password) {
            errors.password = 'Password is required!'
        } else if (!regexpass.test(values.password)) {
            errors.password =
                'Please include: 6-12 characters, upper/lowercase, numbers, special character.'
        }
        return errors
    }
    // ***********************validation End****************

    const handleRequestClose = () => {
        clearInterval(otpInterval); // Clear the timer 
        setRequestOtpOpen(false);
        setIsButtonDisabled(false);
        setOtpTimer(0);
    };

    useEffect(() => {
        setOtpSentSuccessfully(false);
    }, [])

    useEffect(() => {
        if (isResendButtonDisable) {
            const tt = setInterval(() => {
                setRemTime((prev) => prev - 1)
            }, 1000)

            return () => clearInterval(tt);
        }

    }, [isResendButtonDisable])

    useEffect(() => {
        // console.log(remTime<=0)
        if (remTime <= 0) {
            setIsResendButtonDisable(false)
        }
    }, [remTime])

    // *************Timer Set END **********

    const handleChange = (event, property) => {
        setData({ ...data, [property]: event.target.value });
    };

    const [data, setData] = useState({
        id: '',
        name: '',
        businessEmail: '',
        website: '',
        password: '',
    });


    const [showVendorEmailVerification, setShowVendorEmailVerification] = useState({
        otpVerified: ''
    });


    const getData = async () => {

        try {
            const res = await axios.get(`${process.env.REACT_APP_STEELADMIN}/form/GetAllFormCheck`).then((res) => {

                setCheckData(res.data.map(((data) => {

                    let per = JSON.parse(data.permission);

                    return {
                        ...data,
                        permission: per
                    }
                })));


            })
        } catch (error) {
            // console.log(error)
        }

    }

    useEffect(() => {
        getData();

        // console.log(checkData)
    }, [])



    const [mobError, setMobError] = useState('');
    const handlePhoneVerify = async (e) => {

        e.preventDefault();
        openBackdrop()
        // setUserNumberExists(false)
        // console.log("clicked")

        const regexNumber = /^\+\d{2}[0-9]{10}$/;

        if (phone && phone.trim() !== '') {
            try {
                // openBackdrop(); 
                if (otpSentSuccessfully === false) {
                    const apiUrl = `${process.env.REACT_APP_STEELADMIN}/vendor/sendOtpOnRegisteredPhone/+${phone}`;
                    const response = await axios.post(apiUrl).then((res) => {
                        // console.log(res)
                        setData({ ...data, id: res.data.data.id });
                        setBusinessData({ ...businessData, phone: phone });
                        handleRequestOtpOpen();
                        setIsResendButtonDisable(true);
                        setRemTime(60);
                        setOtpSentSuccessfully(true);
                        closeBackdrop()

                    }).catch((err) => {
                        if (err.response.status === 409) {
                            closeBackdrop()
                            setUserNumberExists(true)
                        }
                        else {
                            closeBackdrop()
                            alert("Please enter a valid number")

                        }

                        closeBackdrop()
                    })
                }
                else {
                    // closeBackdrop()
                    handleRequestOtpOpen();
                    // console.log(isResendButtonDisable)
                    setIsResendButtonDisable(true);
                }

            } catch (error) {
                closeBackdrop()
                console.error('Error sending OTP:', error);
            }
        } else {
            closeBackdrop()
            console.error('Phone number is empty.');
            setMobError("Mobile Number is required");
            console.log(mobError)
        }
        closeBackdrop()
    };

    const handleVerifyOtp = async () => {
        openBackdrop();
        setOtpError(false)
        try {
            const res = await axios.post(
                `${process.env.REACT_APP_STEELADMIN}/vendor/verifyMobileOtpOnRegister/${data.id}`,
                { verifyMobileOtp: otp },
                {
                    headers: {
                        'Content-Type': 'application/json',
                    },
                }
            ).then((res) => {
                // window.location.reload();

                if (res.data.data.otpVerified === true) {
                    setOtp("");
                    handleRequestClose();
                    setPhoneVerified(true)
                    setIsVerified(true);
                    setIsButtonDisabled(true);
                    closeBackdrop();


                }
                closeBackdrop()
            }).catch((err) => {
                if (err.response.status === 400) {
                    setOtpError(true);
                    closeBackdrop();

                }
                else {
                    closeBackdrop()


                    alert("soemthing went wrong with processs")
                }
            })





        } catch (err) {
            // console.log(err);

            closeBackdrop()
        }
    };

    const handleRequestOtpEmail = async () => {
        openBackdrop()
        console.log(data)
        const validationErrors = validate(data);
        setErrors(validationErrors);
        console.log(errors)
        if (Object.keys(validationErrors).length === 0) {
            try {
                const response = await axios.post(
                    `${process.env.REACT_APP_STEELADMIN}/vendor/requestEmailOtp/${data.businessEmail}/${data.id}`,
                    {
                        headers: {
                            "Content-Type": "application/json",
                        },
                    }
                );

                if (response.status === 200) {
                    closeBackdrop();
                    console.log(data)

                    navigate(`/vendoremailverification/${data.businessEmail}/${data.id}`, { state: { businessData: data } });
                }

                else {
                    closeBackdrop()

                    console.log("API call failed");

                }
                console.log(response);
                closeBackdrop();

            } catch (err) {
                closeBackdrop();
                // console.log(err);
                // console.log(err.response.data.message);
                if (err.response.status === 409) {
                    console.log("hey", err.response);
                    // alert(err.response.data.message);
                    setEmailExists(err.response.data.message);



                }
            }
        }
        closeBackdrop();
    };


    const handleInputChange = (e) => {
        setPhone(e.target.value);
    };

    const validatePassword = (value) => {
        const regex = /^(?=.*[A-Za-z])(?=.*\d)[A-Za-z\d]{8,}$/;
        return regex.test(value);
    };
    const handlePasswordChange = (e) => {
        const value = e.target.value;
        setPassword(value);
    };


    const handleSubmit = async (event) => {
        event.preventDefault();

        const formValues = {
            name: event.target.name.value,
            username: event.target.username.value,
            phoneNumber: phone,
            email: event.target.email.value,
            password,
        };
        const validationErrors = validate(formValues);
        setErrors(validationErrors);

        // await checkUserNumberExists();

        if (Object.keys(errors).length === 0) {
            // console.log('Form is valid, submitting...');
        }
    };

    const [businessData, setBusinessData] = useState({
        phone: "",
    });




    const handleResendOtp = async () => {
        // openBackdrop();
        setIsResendButtonDisable(true);
        setRemTime(60)
        setOtp('')
        setOtpError(false); // Reset otpError to false before making the API call
        // const tt=setInterval(()=>{
        //     setRemTime((prev)=>prev-1)
        // },1000)

        try {
            const response = await axios.post(
                `${process.env.REACT_APP_STEELADMIN}/vendor/vendorResendOtpOnPhone/${data.id}`,
                {},
                {
                    headers: {
                        "Content-Type": "application/json",
                    },
                }
            ).then((res) => {
                // closeBackdrop();
                // startTimer(); // Start the timer when OTP is resent
                // setOtpSentAgain(true);
            }).catch((err) => {

                // closeBackdrop();
                console.log("error")

            })
        }
        catch (error) {
            // closeBackdrop();
            alert('error occured in api calling')
        }

        // return ()=>clearInterval(tt);

    }
    //Function to Allow only numbers 0-9, backspace, and delete key  
    const handleKeyPress = (e) => {
        const keyCode = e.keyCode || e.which;
        const keyValue = String.fromCharCode(keyCode);
        // Allow number keys (both top row and numeric keypad) and backspace/delete
        const validInput = /^[0-9\b]+$/.test(keyValue) || (
            (keyCode >= 96 && keyCode <= 105) && // Numeric keypad
            (keyCode !== 110 && keyCode !== 190) // Exclude decimal point
        );
        if (!validInput) {
            e.preventDefault();
        }
    };
    return (
        <div className='UloginMainFisrst'>
            <div className='UloginvendoStart'>

                {checkData != null &&
                    <form action='' autocomplete="off" className='UloginBoxformform' onSubmit={handleSubmit}>
                        <Box className='UloginFormstartst'>
                            <div className='box1'>
                                <h1>Welcome, let's create a free Seller account</h1>
                            </div>

                            <Box className='vendorttrtrtr'>

                                {/* {console.log(checkData[0]?.permission[0] )} */}
                                {
                                    checkData[0]?.permission[0] &&
                                    <div style={{ marginBottom: '3%' }}>

                                        <label>Business Name</label>
                                        <input
                                            type='text'
                                            onChange={(e) => handleChange(e, 'name')}
                                            value={data.name}
                                            // required
                                            placeholder='Your business name'
                                            // {...(checkData[0].permission[1] ? { required: 'required' } : {})}
                                            className={errors.name ? 'error' : ''}
                                            autocomplete="off"
                                        />
                                        {errors?.name ? <p style={{ fontSize: '12px', color: 'red' }}>{errors.name}</p> : ''}
                                    </div>
                                }

                                {
                                    checkData[1]?.permission[0] &&
                                    <div style={{ marginBottom: '3%' }}>

                                        <label>Phone</label>
                                        <div className='phoneinputtt' style={{ width: '100%', display: 'grid', gridTemplateColumns: '75% auto', alignItems: 'center' }}>
                                            <PhoneInput className='Phoneienei'
                                                country={'in'}
                                                value={phone}
                                                style={{ width: "100%" }}
                                                onChange={setPhone}
                                                // {...(checkData[1].permission[1] ? { required: 'required' } : {})}

                                                inputStyle={{ width: '100%', height: '30px', borderRadius: "9px 0px 0px 9px" }} // Add padding to accommodate the button
                                            />
                                            {userNumberExists && <p style={{ color: "red", fontSize: "12px" }}>User number already exists</p>}
                                            <Button
                                                variant="text"
                                                style={{
                                                    width: '100%',
                                                    minWidth: '0',
                                                    height: isVerified ? '40px' : 'auto',
                                                    fontSize: isVerified ? '10px' : 'auto',
                                                    padding: '7px',
                                                }}
                                                sx={{ textTransform: 'capitalize', color: '#fff' }}
                                                onClick={handlePhoneVerify}
                                                disabled={isButtonDisabled}
                                            >
                                                {isVerified ? 'Verified ✓' : 'Verify'}

                                            </Button>
                                        </div>
                                        {/* { errors?.phoneNumber ? <p style={{fontSize:'12px', color:'red'}}>{errors.phoneNumber}</p> : ''} */}
                                        {mobError ? <p style={{ fontSize: '12px', color: 'red' }}>{mobError}</p> : ''}
                                    </div>
                                }
                                {
                                    checkData[2]?.permission[0] &&
                                    <div style={{ marginBottom: '3%' }}>
                                        <label style={{ marginTop: '3%' }}>Work Email</label>
                                        <input
                                            onChange={(e) => handleChange(e, 'businessEmail')}
                                            value={data.businessEmail}
                                            autoComplete="off"
                                            type="email"
                                            name='email'
                                            placeholder='Your work email'
                                            // {...(checkData[2].permission[1] ? { required: 'required' } : {})}
                                            className={errors.email ? 'error' : ''}
                                        />
                                        {errors?.email ? <p style={{ fontSize: '12px', color: 'red' }}>{errors.email}</p> : ''}

                                        <div style={{ color: 'red', fontSize: '12px' }}>{emailExists}</div>
                                    </div>

                                }



                                {
                                    checkData[3]?.permission[0] &&
                                    <div style={{ marginBottom: '3%' }}>
                                        <label>Website</label>
                                        <input
                                            type='text'
                                            // required
                                            onChange={(e) => handleChange(e, 'website')}
                                            value={data.website}
                                            placeholder='Your website'
                                        // {...(checkData[3].permission[1] ? { required: 'required' } : {})}

                                        />
                                        {errors?.website ? <p style={{ fontSize: '12px', color: 'red' }}>{errors.website}</p> : ''}
                                    </div>
                                }
                                {
                                    checkData[4]?.permission[0] &&
                                    <div style={{ marginBottom: '3%' }}>
                                        <label>Password</label>
                                        <div style={{ position: "relative", width: "100%" }}>
                                            <input
                                                style={{ display: 'block', width: "100%" }}
                                                onChange={(e) => handleChange(e, 'password')}
                                                value={data.password}
                                                type={showPassword ? 'text' : 'password'}
                                                placeholder='Enter password'
                                                name='password'
                                            />
                                            <span style={{ position: "absolute", height: "100%", top: "0%", right: "0%", display: "flex", alignItems: "center" }}>
                                                <IconButton 
                                                    aria-label="toggle password visibility"
                                                    onClick={() => setShowPassword(!showPassword)}

                                                >
                                                    {showPassword ? <VisibilityOff /> : <Visibility />}
                                                </IconButton>
                                            </span>
                                        </div>
                                        {errors?.password ? <p style={{ fontSize: '12px', color: 'red' }}>{errors.password}</p> : ''}
                                    </div>
                                }
                            </Box>



                            <div className='box2'>
                                <h1 style={{ display: 'block !important' }}>By continuing you agree to the KJSS <Link to='/terms' target='_Blank'><span>terms of service </span></Link> and <Link to='/privacy' target='_Blank'><span>privacy policy. </span></Link></h1>

                            </div>
                            <Box className='lastButton'>
                                <Button className='btnContinue' variant="contained" type='submit' onClick={handleRequestOtpEmail}>Continue</Button>
                            </Box>


                        </Box>
                    </form>
                }
                {/* #16469D */}

                <Box className='UloginBox2Vendor'>
                    <Box className='VendorBoxStart2' >
                        <Slider {...settings}>
                            {venderSlide.map((item) =>
                                <div className='VendorMediaBox2' >
                                    <img src={item.linkImg} alt='Computer' />
                                </div>
                            )}
                        </Slider>
                    </Box>
                    <div className='BoxInfovendor'>
                        <Typography variant='h4' style={{ marginBottom: "1.5%" }}>Join the Steel Revolution!</Typography>
                        <Typography variant='h6'>Shop from more than 2000 trusted suppliers <br />for over 3000+ products</Typography>
                    </div>
                </Box>
                {/* {apiCallSuccess && <VendorEmailVerification />} */}
                <Modal
                    aria-labelledby="transition-modal-title"
                    aria-describedby="transition-modal-description"
                    sx={{ bgcolor: 'transparent', }}
                    open={requestOtpOpen}
                    onClose={handleRequestClose}
                    closeAfterTransition
                    slots={{ backdrop: Backdrop }}
                    slotProps={{
                        backdrop: {
                            timeout: 500,

                        },
                    }}
                >
                    <Fade in={requestOtpOpen}>
                        <Box sx={style} className='thirdModalsecound'>
                            <Typography variant='h5'>Verify with OTP</Typography>
                            <Typography variant='h6'>SMS Sent to {phone}</Typography>

                            <MuiOtpInput
                                length={5}
                                className='forgotVendorOTPInput'
                                style={{ width: "100%" }}
                                value={otp} name='otp'
                                onChange={(e) => setOtp(e)}
                                type='otp'
                                onKeyDown={handleKeyPress}
                            />
                            {otpError &&
                                <p style={{
                                    width: '100%',
                                    border: '2px solid 5F5E5E',
                                    padding: '1%',
                                    marginTop: '2%',
                                    justifyContent: 'left',
                                    display: 'flex',
                                    borderRadius: '4px',
                                    fontSize: '14px',
                                    color: 'red',
                                }}> <ErrorIcon sx={{ color: '#ff5d5d', marginRight: '8px' }} /> Incorrect OTP</p>}

                            <Button
                                sx={{

                                    height: '40px',
                                    textTransform: 'capitalize',
                                    bgcolor: '#16469d',

                                }} variant="contained" onClick={handleVerifyOtp}>

                                Verify
                            </Button>


                            <Typography
                                sx={{
                                    // mt: '2%',
                                    fontSize: '14px',
                                    display: 'flex',
                                    flexDirection: 'row',
                                    alignItems: 'center',
                                    justifyContent: 'center',
                                    textAlign: 'center',
                                }}
                                variant='p'

                            >
                                Didn't receive OTP?
                                <Button
                                    variant="text"
                                    sx={{
                                        width: '40px !important',
                                        margin: "0px !important",
                                        p: '2px',
                                        textTransform: 'capitalize',
                                        display: 'flex',
                                        alignItems: 'center',
                                        justifyContent: 'center',
                                        textAlign: 'center',
                                        color: '#16469D !important',


                                    }}
                                    onClick={handleResendOtp}
                                    disabled={isResendButtonDisable}
                                >
                                    {isResendButtonDisable ? `00:${remTime}` : "Resend"}
                                </Button>
                            </Typography>

                        </Box>
                    </Fade>
                </Modal>

            </div>

            <BackdropComponent />
        </div>
    );
};

export default VendorRegisterFirst;


