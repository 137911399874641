import React, { useState, useEffect } from 'react';
import "./scss/CorporateRegistration.css"
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import { Typography } from '@mui/material';
import { Link, useParams } from 'react-router-dom';
import 'react-phone-input-2/lib/style.css';
import PhoneInput from 'react-phone-input-2';
import Modal from '@mui/material/Modal';
import Fade from '@mui/material/Fade';
import { useNavigate } from 'react-router-dom';
import Backdrop from '@mui/material/Backdrop';
import axios from 'axios';
import VendorEmailVerification from 'components/VendorEmailVerification/VendorEmailVerification';
import Slider from 'react-slick';
import { MuiOtpInput } from 'mui-one-time-password-input'
import CheckIcon from '@mui/icons-material/Check';
import useBackdrop from 'hooks/useBackdrop';
import { Visibility, VisibilityOff } from '@mui/icons-material';
import IconButton from '@mui/material/IconButton';
import ErrorIcon from '@mui/icons-material/Error';

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 400,
    height: 304,
    backgroundColor: 'white',
    borderRadius: '4px',
    boxShadow: 24,
    p: 4,
};

const imageslidedata = [
    {
        id: 1,
        linkImg: 'https://res.cloudinary.com/dtffniutw/image/upload/v1689518380/Mockup_1_1_vilx0a.svg',
    },
    {
        id: 2,
        linkImg: 'https://res.cloudinary.com/dtffniutw/image/upload/v1689518297/Mockup_PSD_FREE___iPad_Pro___Pencil_2018-removebg-preview_1_bnh73w.svg',
    },
    {
        id: 3,
        linkImg: 'https://res.cloudinary.com/dtffniutw/image/upload/v1689518380/Mockup_1_1_vilx0a.svg',
    },
    {
        id: 4,
        linkImg: 'https://res.cloudinary.com/dtffniutw/image/upload/v1689518297/Mockup_PSD_FREE___iPad_Pro___Pencil_2018-removebg-preview_1_bnh73w.svg',
    },

]


const CorporateRegistration = () => {
    var settings = {
        fade: true,
        dots: true,
        arrows: false,
        infinite: true,
        slidesToShow: 1,
        slidesToScroll: 1,
        autoplay: true,
        autoplaySpeed: 2000,
        cssEase: 'cubic-bezier(0.7, 0, 0.3, 1)',


    };
    const { openBackdrop, closeBackdrop, BackdropComponent } = useBackdrop();


    const { id, corporateEmail } = useParams();
    const [errorMessage, setErrorMessage] = useState('');

    const navigate = useNavigate()
    const [requestOtpOpen, setRequestOtpOpen] = useState(false);
    const handleRequestOtpOpen = () => setRequestOtpOpen(true);
    const handleRequestClose = () => setRequestOtpOpen(false);
    const [checkData, setCheckData] = useState(null);
    const [otpError, setOtpError] = useState(false)
    const [phoneVerified, setPhoneVerified] = useState(false);
    const [phone, setPhone] = useState('');
    const [password, setPassword] = useState('');
    const [errors, setErrors] = useState({});
    const [otp, setOtp] = useState('');
    const [showPassword, setShowPassword] = useState(false);

    const [isVerified, setIsVerified] = useState(false);
    const [isButtonDisabled, setIsButtonDisabled] = useState(false);

    const { corporatePhoneNumber } = useParams();

    // ************************Timer Set Start 
    const [otpSentSuccessfully, setOtpSentSuccessfully] = useState(false);
    const [isResendButtonDisabled, setIsResendButtonDisabled] = useState(false);

    const [remTime, setRemTime] = useState(60);

    useEffect(() => {
        setOtpSentSuccessfully(false);
    }, [])



    // 
    useEffect(() => {
        if (isResendButtonDisabled) {
            const tt = setInterval(() => {
                setRemTime((prev) => prev - 1)
            }, 1000)

            return () => clearInterval(tt);
        }

    }, [isResendButtonDisabled])

    useEffect(() => {
        // console.log(remTime<=0)
        if (remTime <= 0) {
            setIsResendButtonDisabled(false)
        }
    }, [remTime])


    const handleChange = (event, property) => {
        setData({ ...data, [property]: event.target.value });
    };

    const handleKeyPress = (e) => {
        const keyCode = e.keyCode || e.which;
        const keyValue = String.fromCharCode(keyCode);
        // Allow number keys (both top row and numeric keypad) and backspace/delete
        const validInput = /^[0-9\b]+$/.test(keyValue) || (
            (keyCode >= 96 && keyCode <= 105) && // Numeric keypad
            (keyCode !== 110 && keyCode !== 190) // Exclude decimal point
        );
        if (!validInput) {
            e.preventDefault();
        }
    };

    const [data, setData] = useState({
        id: '',
        name: '',
        corporateEmail: '',
        website: '',
        corporatePassword: '',
    });
    const [showVendorEmailVerification, setShowVendorEmailVerification] = useState({
        otpVerified: ''
    });
    const getData = async () => {

        try {
            const res = await axios.get(`${process.env.REACT_APP_STEELADMIN}/form/GetAllCorporateFormCheck`).then((res) => {

                setCheckData(res.data.map(((data) => {

                    let per = JSON.parse(data.permission);

                    return {
                        ...data,
                        permission: per
                    }
                })));


            })
        } catch (error) {
            // console.log(error)
        }

    }

    // *******************************Resend OTP Start*************************
    const handleResendOtp = async () => {

        setIsResendButtonDisabled(true);
        setRemTime(60)
        setOtp('')

        openBackdrop();
        setOtpError('')
        setOtp('')

        try {
            const response = await axios.post(
                `${process.env.REACT_APP_STEELADMIN}/corporate/coporateResendOtpOnPhone/+${data.id}`,
                {
                    verifyMobileOtp: otp,

                },
                {
                    headers: {
                        'Content-Type': 'application/json',
                    },
                }
            )
            // .then( (res)=>{
            //   console.log("otp verified")
            //   navigate("/verify")
            // }).catch((err)=>{
            //   console.log("error occured "+err.response.status)
            // })
            closeBackdrop();


        } catch (err) {
            // console.log(err);
            // Handle error here if the API call fails
            // You can also set an error state and display a user-friendly error message on the UI.
            closeBackdrop();
        }

    };

    // *******************************Resend OTP End*************************

    useEffect(() => {
        getData();
    }, [])

    const [mobError, setMobError] = useState('');
    const handlePhoneVerify = async (e) => {
        e.preventDefault();
        // console.log("clicked")
        setMobError('');
        setOtpError('');
        setOtp('');
        openBackdrop();
        if (phone && phone.trim() !== '') {
            try {

                if (otpSentSuccessfully === false) {


                    // console.log("this " + phone)
                    const apiUrl = `${process.env.REACT_APP_STEELADMIN}/corporate/sendOtpOnRegisteredPhone/+${phone}`;
                    const response = await fetch(apiUrl, {
                        method: 'POST',
                        headers: {
                            'Content-Type': 'application/json',
                        },
                    });

                    if (!response.ok) {
                        throw new Error('Network response was not ok');
                    }

                    const responseData = await response.json();

                    // console.log(responseData)
                    setData({ ...data, id: responseData.data.userId });
                    setBusinessData({ ...businessData, phone: phone });
                    setPhoneVerified(true);
                    handleRequestOtpOpen();
                    setIsResendButtonDisabled(true);
                    setOtpSentSuccessfully(true);
                    closeBackdrop();
                }
                else {
                    handleRequestOtpOpen();
                    // console.log(isResendButtonDisable)
                    setIsResendButtonDisabled(true);
                    closeBackdrop()
                }
            } catch (error) {
                console.error('Error sending OTP:', error);
            }
        } else {
            closeBackdrop();
            console.error('Phone number is empty.');
            setMobError("Mobile Number is required");
            console.log(mobError)
        }
    };

    const handleVerifyOtp = async (e) => {
        // openBackdrop()
        e.preventDefault()
        setOtpError(''); // Reset otpError to false before making the API call



        openBackdrop();

        if (otp === '') {
            closeBackdrop();
            setOtpError("Please enter the OTP!");
        } else {

            try {
                const res = await axios.post(
                    `${process.env.REACT_APP_STEELADMIN}/corporate/verifyMobileOtpOnRegister/${data.id}`,
                    { verifyMobileOtp: otp },
                    {
                        headers: {
                            'Content-Type': 'application/json',
                        },
                    }
                ).then((res) => {

                    if (res.data.data.otpVerified === true) {
                        closeBackdrop();
                        setIsVerified(true);
                        setIsButtonDisabled(true);
                        handleRequestClose();


                    }
                })
                // setIsVerified(true); 


                if (res.data.data.otpVerified === true) {

                    setOtp("");
                    handleRequestClose();
                    closeBackdrop();

                } else {


                    console.log("otp is wrong try again");
                    setOtpError("Incorrect OTP!")
                    closeBackdrop();

                }
                // closeBackdrop()
            } catch (err) {
                // closeBackdrop() 
                setOtpError("Incorrect OTP!")
                console.log(err);
                closeBackdrop();

            }
        }
    };

    const handleRequestOtpEmail = async () => {
        openBackdrop()
        console.log(data)
        const validationErrors = validate(data);
        setErrors(validationErrors);
        console.log(validationErrors)
        console.log(Object.keys(validationErrors).length)
        if (Object.keys(validationErrors).length === 0) {
            try {
                const response = await axios.post(
                    `${process.env.REACT_APP_STEELADMIN}/corporate/requestEmailOtp/${data.corporateEmail}/${data.id}`,
                    {
                        headers: {
                            "Content-Type": "application/json",
                        },
                    }
                );

                if (response.status === 200) {

                    navigate(`/corporateemail/${data.corporateEmail}/${data.id}`, { state: { corporateData: data } });
                    closeBackdrop();
                }
                else {


                    console.log("API call failed");
                    closeBackdrop();


                }
                // closeBackdrop()
                // console.log(response);
            } catch (err) {
                //  if (err.response.status === 409) {
                //     closeBackdrop()
                //     // Email already exists

                //     console.log('hellooo', err.response.status)
                //     setErrorMessage('Email is already registered.');
                // }
                if (err.response.status === 409) {
                    console.log("hey", err.response);
                    // alert();
                    closeBackdrop();

                    setErrorMessage(err.response.data.message);

                }
                // closeBackdrop()
                console.log(err);

                closeBackdrop();
            }
        }
        closeBackdrop();
    };






    const handleInputChange = (e) => {
        setPhone(e.target.value);
    };

    const validatePassword = (value) => {
        const regex = /^(?=.*[A-Za-z])(?=.*\d)[A-Za-z\d]{8,}$/;
        return regex.test(value);
    };

    const handlePasswordChange = (e) => {
        const value = e.target.value;
        setPassword(value);
    };

    const handleSubmit = (event) => {
        event.preventDefault();
        // const formValues = {
        //     name: event.target.name.value,
        //     username: event.target.username.value,
        //     phoneNumber: phone,
        //     email: event.target.email.value,
        //     password,
        // };

        // const errors = validate(formValues);
        // setErrors(errors);

        if (Object.keys(errors).length === 0) {
            // console.log('Form is valid, submitting...');
        }
    };

    const [businessData, setBusinessData] = useState({
        phone: "",
    });

    const validate = (values) => {
        const errors = {}
        const regexemail = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/
        const regexNumber = /^\+\d{2}[0-6]{10}$/;


        const regexpass = /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[!@#$%^&*]).{8,}$/;
        console.log(!values.corporateEmail)

        if (!values.name.trim()) {
            errors.name = 'Name is required!';
        }
        // if (!!values.phoneNumber) {
        //     errors.phoneNumber = 'Number is required!'
        // } else if (!regexNumber.test(values.phoneNumber)) {
        //     console.log(values.phoneNumber)
        //     console.log(regexNumber.test(values.phoneNumber))
        //     errors.phoneNumber = 'This is not a valid number format!'
        // }
        if (!values.corporateEmail) {
            errors.email = 'Email is required!'
        } else if (!regexemail.test(values.corporateEmail)) {
            errors.email = 'This is not a valid email format!'
        }
        if (!values.website) {
            errors.website = 'Website is required!'
        }
        if (!values.corporatePassword) {
            errors.password = 'Password is required!'
        } else if (!regexpass.test(values.corporatePassword)) {
            errors.password =
                'Please include: 6-12 characters, upper/lowercase, numbers, special character.'
        }
        return errors
    }

    return (
        <div className='UloginMain'>
            <div className='UloginStart'>
                {checkData != null &&
                    <form action='' onSubmit={handleSubmit} className='UloginBox'>
                        <Box className='UloginForm'>
                            <div className='box1'>
                                <h1>Welcome, let's create a free Corporate account</h1>
                            </div>




                            <div className='UloginBoxForm' >
                                {
                                    checkData[0]?.permission[0] &&
                                    <div>
                                        <label> Name</label>
                                        <input
                                            type='text'
                                            onChange={(e) => handleChange(e, 'name')}
                                            value={data.name}
                                            placeholder='Enter Your name'
                                            className={errors.name ? 'error' : ''}
                                        />
                                        {errors?.name ? <p style={{ fontSize: '12px', color: 'red' }}>{errors.name}</p> : ''}
                                    </div>
                                }
                                {
                                    checkData[1]?.permission[0] &&
                                    <div>
                                        <label>Phone</label>
                                        <div className='phoneinputtt' style={{ width: '85%', display: 'grid', gridTemplateColumns: '75% auto', alignItems: 'center' }}>
                                            <PhoneInput
                                                style={{ width: '100%', alignItems: 'left' }}
                                                country={'in'}
                                                value={phone}
                                                onChange={setPhone}

                                                inputStyle={{ height: '30px', width: "100%" }} // Add padding to accommodate the button
                                            />
                                            <Button
                                                variant="text"
                                                // sx={{ textTransform: 'capitalize' }}
                                                style={{ width: '10px !important' }}
                                                sx={{
                                                    // padding:'10px',
                                                    width: '100% !important',
                                                    minWidth: "0px !important",
                                                    textTransform: 'capitalize',
                                                    margin: "0 !important",
                                                    padding: '7px',
                                                    ...(isVerified
                                                        ? { bgcolor: '#133F8D', color: '#ffff !important', pointerEvents: 'none' }
                                                        : { bgcolor: '#16469D', color: '#ffff', })
                                                }}
                                                onClick={handlePhoneVerify}
                                                disabled={isButtonDisabled}
                                                endIcon={isVerified ? <CheckIcon style={{ width: '80%' }} /> : null} // Conditionally render the CheckIcon
                                            >

                                                {isVerified ? "Verified" : "Verify"}
                                            </Button>
                                        </div>

                                        {/* {errors?.phoneNumber ? <p style={{fontSize:'12px', color:'red'}}>{errors.phoneNumber}</p> : ''} */}
                                        {mobError ? <p style={{ fontSize: '12px', color: 'red' }}>{mobError}</p> : ''}



                                    </div>

                                }


                                {
                                    checkData[2]?.permission[0] &&
                                    <div>
                                        <label>Email</label>
                                        <input
                                            onChange={(e) => handleChange(e, 'corporateEmail')}
                                            value={data.corporateEmail}
                                            type="email"
                                            name='email'
                                            placeholder='Enter your email'

                                            className={errors.email ? 'error' : ''}
                                        />
                                        {errors?.email ? <p style={{ fontSize: '12px', color: 'red' }}>{errors.email}</p> : ''}
                                        <Box sx={{ color: 'red' }}>{errorMessage}</Box>
                                    </div>
                                }
                                {
                                    checkData[3]?.permission[0] &&
                                    <div>
                                        <label>Company Website</label>
                                        <input
                                            type='text'
                                            onChange={(e) => handleChange(e, 'website')}
                                            value={data.website}
                                            placeholder='Company website'

                                        />
                                        {errors?.website ? <p style={{ fontSize: '12px', color: 'red' }}>{errors.website}</p> : ''}
                                    </div>
                                }
                                {
                                    checkData[4]?.permission[0] &&
                                    <div style={{marginBottom:'5%'}}>
                                        <label>Password</label>
                                        <div style={{ position: "relative", width: "85%" }}>
                                            <input
                                                onChange={(e) => handleChange(e, 'corporatePassword')}
                                                value={data.corporatePassword}
                                                type={showPassword ? 'text' : 'password'}
                                                placeholder='Must be at least 8 characters'
                                                name='password'
                                            />
                                            <span style={{ position: "absolute", height: "100%", top: "0%", right: "0%", display: "flex", alignItems: "center" }}>
                                                <IconButton style={{ backgroundColor: 'transparent', color: '#585858' }}
                                                    aria-label="toggle password visibility"
                                                    onClick={() => setShowPassword(!showPassword)}

                                                >
                                                    {showPassword ? <VisibilityOff /> : <Visibility />}
                                                </IconButton>
                                            </span>
                                        </div>
                                        {errors?.password ? <p style={{ fontSize: '12px', color: 'red' }}>{errors.password}</p> : ''}
                                    </div>
                                }
                            </div>

                            <div className='box2'>
                                <h1>By continuing you agree to the KJSS <Link to='/terms' target='_Blank'><span>terms of service </span></Link> and <Link to='/privacy' target='_Blank'><span>privacy policy. </span></Link></h1>

                            </div>
                            <Button variant="contained" type="submit" onClick={handleRequestOtpEmail}
                                sx={{ p: '2%' }}>Register</Button>

                        </Box>

                    </form>
                }
                <Box className='UloginBox2'>
                    <Box className='UloginBoxStart2' >
                        <Slider {...settings}>
                            {imageslidedata.map((item) =>
                                <div className='UloginMediaBox2' >
                                    <img src={item.linkImg} alt='Computers' />
                                </div>
                            )}
                        </Slider>
                    </Box>
                    <div className='BoxInfo'>
                        <Typography variant='h4' style={{ marginBottom: "1.5%" }}>Join the Steel Revolution!</Typography>
                        <Typography variant='h6'>Shop from more than 2000 trusted suppliers <br />for over 3000+ products</Typography>
                    </div>
                </Box>
                <Modal
                    aria-labelledby="transition-modal-title"
                    aria-describedby="transition-modal-description"
                    sx={{ bgcolor: 'transparent', }}
                    open={requestOtpOpen}
                    onClose={handleRequestClose}
                    closeAfterTransition
                    slots={{ backdrop: Backdrop }}
                    slotProps={{
                        backdrop: {
                            timeout: 500,

                        },
                    }}
                >
                    <Fade in={requestOtpOpen}>
                        <Box sx={style} className='thirdModalsecound'>
                            <Typography variant='h5'>Verify with OTP</Typography>
                            <Typography variant='h6'> SMS Sent to  {phone}</Typography>

                            <MuiOtpInput
                                length={5}
                                className='forgotVendorOTPInput'
                                style={{ width: '100%' }}
                                value={otp} name='otp'
                                onChange={(e) => setOtp(e)}
                                type='otp'
                                onKeyDown={handleKeyPress}
                            />
                            {otpError ? <p style={{ color: "red", fontSize: "12px", marginBottom: "0" }}>{otpError}</p> : ''}
                            {/* <Box sx={{ mt: '4%' }} className='otpInput'>
                                <input maxLength={5} type='otp' name='otp' onChange={(e) => setOtp(e.target.value)} value={otp} />

                            {otpError && <p style={{color:"red",fontSize:"12px"}}>Otp is Not Correct </p>}
                            </Box> */}
                            <Button
                                sx={{

                                    height: '40px',
                                    textTransform: 'capitalize',
                                    bgcolor: '#16469d',

                                }} variant="contained" onClick={handleVerifyOtp}>

                                Verify
                            </Button>
                            {/* <Typography
                                sx={{
                                    mt: '2%',
                                    fontSize: '14px',

                                }}
                                variant='p' className='forOTPResend resendMid'>
                                Didn't receive OTP? <span style={{ marginLeft: '2%' }} onClick={handleResendOtp}> Resend</span>
                            </Typography> */}

                            <Typography
                                sx={{
                                    mt: '2%',
                                    fontSize: '14px',
                                    display: 'flex',
                                    flexDirection: 'row',
                                    alignItems: 'center',
                                    justifyContent: 'center',
                                    textAlign: 'center',
                                    width: '100%',
                                    color: "9F9F9F",

                                }}
                                variant='p' >
                                Didn't receive OTP?
                                <Button
                                    sx={{
                                        ml: '1%',
                                        width: '40px !important',
                                        margin: "0px !important",
                                        textTransform: 'capitalize',
                                        display: 'flex',
                                        alignItems: 'center',
                                        justifyContent: 'center',
                                        textAlign: 'center',
                                        color: '#16469D !important',

                                    }} onClick={handleResendOtp}
                                    disabled={isResendButtonDisabled}>{isResendButtonDisabled ? `00:${remTime}` : "Resend"}</Button>
                            </Typography>
                        </Box>
                    </Fade>
                </Modal>


            </div>
            <BackdropComponent />
        </div>
    );
};

export default CorporateRegistration;


