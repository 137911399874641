import * as React from 'react'
import CssBaseline from '@mui/material/CssBaseline'
import Container from '@mui/material/Container'
import Button from '@mui/material/Button'
import '../ErrorPage/scss/Error.css'
import Box from '@mui/material/Box'
import ComingSoonPg from '../../assets/images/comingsoonimg.gif'
import { Link } from 'react-router-dom'

const ComingSoon = () => {
    return (
        <Container maxWidth="sm">
            <br></br>
            <Box sx={{ height: '70vh', marginTop: '10%' }}>
                <center>
                    <img src={ComingSoonPg} width={'50%'} alt="Error" />

                    <p className={'CSMsg'}>
                        We're building something great! Our website is currently
                        in the works,<br></br> Thank you for your patience. Check
                        back soon!
                    </p>
                    <Link to="/home" style={{ textDecoration: 'none' }}>
                        <Button
                            variant="contained"
                            sx={{ mt: 2, backgroundColor: '#1976d2' }}
                        >
                            Go Back
                        </Button>
                    </Link>
                </center>
            </Box>
        </Container>
    )
}

export default ComingSoon
