import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    name: null,
    userName: null,
    email: null,
    password: null,
    phoneNumber:null,
    loading: false,
    error: null,
    success: false,
}

export const registrationSlice = createSlice({
    name: 'registration',
    initialState,

    reducers: {
        setName: (state, action) => {
            state.name = action.payload
        },
        setUserName: (state, action) => {
            state.userName = action.payload
        },
        setEmail: (state, action) => {
            state.email = action.payload.email
        },
        setPassword: (state, action) => {
            state.password = action.payload
        },
        setPhoneNumber: (state, action) => {
            state.phoneNumber = action.payload
        },
        setLoading: (state, action) => {
            state.loading = action.payload;
        },
        setError: (state, action) => {
            state.error = action.payload;
        },
        setSuccess: (state, action) => {
            state.success = action.payload;
        },
    }
})

export const {
    success,
    setName,
    setUserName,
    setEmail,
    setPassword,
    setPhoneNumber,
    setLoading,
    setError,
    setSuccess,
} = registrationSlice.actions

export default registrationSlice.reducer