import { Typography } from '@mui/material'
import Container from '@mui/material/Container'
import Box from '@mui/material/Box'
import TextField from '@mui/material/TextField'
import FormControlLabel from '@mui/material/FormControlLabel'
import Checkbox from '@mui/material/Checkbox'
import Button from '@mui/material/Button'
import './sass/Register.css'
import axios from "axios";
import { useEffect, useState } from 'react'
import { signUp } from '../../services/UserService'
import { useDispatch, useSelector } from 'react-redux'
import { setName, setUserName, setEmail, setPassword } from './store/dataSlice'
import { setPhoneNumber } from './store/dataSlice'
import { register } from './store/Register.actions'
import { Link, useNavigate } from 'react-router-dom'
import RegSuccess from '../../assets/images/RegisterSuccess.gif'
import VerifyEmail from './VerifyEmail'
import { Image } from 'cloudinary-react'
import InputLabel from '@mui/material/InputLabel'
import { FormControl, MenuItem, Select } from '@mui/material';
import 'react-phone-input-2/lib/style.css';
import PhoneInput from 'react-phone-input-2';
import 'react-phone-input-2/lib/bootstrap.css';
import Slider from 'react-slick';
import { Visibility, VisibilityOff } from '@mui/icons-material';
import IconButton from '@mui/material/IconButton';
import ErrorIcon from '@mui/icons-material/Error';

import {
    setLoading,
    setError,
    setSuccess,
} from './store/dataSlice'
// *************************new Added***************
import Backdrop from '@mui/material/Backdrop';
import Modal from '@mui/material/Modal';
import Fade from '@mui/material/Fade';
import useBackdrop from 'hooks/useBackdrop';
import { Helmet } from "react-helmet";
const imageslidedata = [
    {
        id: 1,
        linkImg: 'https://res.cloudinary.com/dtffniutw/image/upload/v1689518380/Mockup_1_1_vilx0a.svg',
    },
    {
        id: 2,
        linkImg: 'https://res.cloudinary.com/dtffniutw/image/upload/v1689518297/Mockup_PSD_FREE___iPad_Pro___Pencil_2018-removebg-preview_1_bnh73w.svg',
    },
    {
        id: 3,
        linkImg: 'https://res.cloudinary.com/dtffniutw/image/upload/v1689518380/Mockup_1_1_vilx0a.svg',
    },
    {
        id: 4,
        linkImg: 'https://res.cloudinary.com/dtffniutw/image/upload/v1689518297/Mockup_PSD_FREE___iPad_Pro___Pencil_2018-removebg-preview_1_bnh73w.svg',
    },

]

const Register = () => {
    const { openBackdrop, closeBackdrop, BackdropComponent } = useBackdrop();

    var settings = {
        fade: true,
        dots: true,
        arrows: false,
        infinite: true,
        slidesToShow: 1,
        slidesToScroll: 1,
        autoplay: true,
        autoplaySpeed: 2000,
        cssEase: 'cubic-bezier(0.7, 0, 0.3, 1)',


    };
    const dispatch = useDispatch()
    // *****************New Added*********************
    const [requestOtpOpen, setRequestOtpOpen] = useState(false);
    const handleRequestOtpOpen = () => setRequestOtpOpen(true);
    const handleRequestClose = () => setRequestOtpOpen(false);
    const [phoneVerified, setPhoneVerified] = useState(false);
    const [phone, setPhone] = useState('');
    const [errorMessage, setErrorMessage] = useState('');
    const [otp, setOtp] = useState('');

    const [showPassword, setShowPassword] = useState(false);

    const [businessData, setBusinessData] = useState({
        phone: "",
    });




    const handlePhoneVerify = async (e) => {
        e.preventDefault();
        // console.log("clicked")

        if (phone && phone.trim() !== '') {
            try {
                // console.log("this " + phone)
                const apiUrl = `${process.env.REACT_APP_STEELADMIN}/corporate/sendOtpOnRegisteredPhone/+${phone}`;
                const response = await fetch(apiUrl, {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                    },
                });

                if (!response.ok) {
                    throw new Error('Network response was not ok');
                }

                const responseData = await response.json();

                // console.log(responseData)
                setData({ ...data, id: responseData.data.userId });
                setBusinessData({ ...businessData, phone: phone });
                setPhoneVerified(true);
                handleRequestOtpOpen();
            } catch (error) {
                console.error('Error sending OTP:', error);
            }
        } else {
            console.error('Phone number is empty.');
        }
    };
    // **********************New Added*****************
    const navigate = useNavigate()
    const [checkData, setCheckData] = useState([]);

    const registrationState = useSelector((state) => state.registration)


    const [data, setData] = useState({
        name: '',
        phoneNumber: '',
        password: '',
        email: '',
    })
    const [emailError, setEmailError] = useState('')
    const [phoneError, setPhoneError] = useState(false)
    const [errors, setErrors] = useState({})
    const [isSubmit, setIsSubmit] = useState(false)
    const [isSuccess, setIsSuccess] = useState(false)

    const handleChange = (event, property) => {
        setData({ ...data, [property]: event.target.value })
    }

    const getData = async () => {

        try {
            const res = await axios.get(`${process.env.REACT_APP_STEELADMIN}/form/GetAllUserFormCheck`).then((res) => {

                setCheckData(res.data.map(((data) => {

                    let per = JSON.parse(data.permission);

                    return {
                        ...data,
                        permission: per
                    }
                })));

            })
        } catch (error) {
            // console.log(error)
        }
    }


    useEffect(() => {
        getData();
    }, [])

    // *******************************Verify Mobile && Verify Email  API integration Start*******************************
    const handleVerifyOtp = async () => {
        openBackdrop()
        try {
            const res = await axios.post(
                `${process.env.REACT_APP_STEELADMIN}/corporate/verifyMobileOtpOnRegister/${data.id}`,
                { verifyMobileOtp: otp },
                {
                    headers: {
                        'Content-Type': 'application/json',
                    },
                }
            );

            if (res.data.data.otpVerified === true) {
                setOtp("");
                handleRequestClose();
                closeBackdrop()

            }
            // ******************Error Handling*******************
            else if (res.data.data.statusCode === 403) {
                closeBackdrop()
            }
            // ******************Error Handling*******************
            else {
                // console.log("otp is wrong try again");
                closeBackdrop()
            }
        } catch (err) {
            // console.log(err);
            closeBackdrop()
        }
    };



    const handleChanges = (country, phoneNumber) => {

        const { dialCode, format } = country;

        const updatedPhoneNumber = `+${phoneNumber}`;


        setData((prevData) => ({
            ...prevData,
            phoneNumber: updatedPhoneNumber,
        }));

    };

    const validate = (values) => {
        const errors = {}
        const regexemail = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/
        const regexNumber = /^\+\d{2}[0-9]{10}$/;

        const regexpass =
            /^(?=.*[0-9])(?=.*[!@#$%^&*])[a-zA-Z0-9!@#$%^&*]{6,12}$/


        if (!values.name.trim()) {
            errors.name = 'Name is required!';
        } else if (/\d/.test(values.name)) {
            errors.name = 'Name should not contain numbers';
        }
        if (!values.phoneNumber) {
            errors.phoneNumber = 'Number is required!'
        } else if (!regexNumber.test(values.phoneNumber)) {
            // console.log(values.phoneNumber)
            // console.log(regexNumber.test(values.phoneNumber))
            errors.phoneNumber = 'This is not a valid number format!'
        }
        if (!values.email) {
            errors.email = 'Email is required!'
        } else if (!regexemail.test(values.email)) {
            errors.email = 'This is not a valid email format!'
        }
        if (!values.password) {
            errors.password = 'Password is required!'
        } else if (!regexpass.test(values.password)) {
            errors.password =
                'Please include: 6-12 characters, upper/lowercase, numbers, special character.'
        }
        return errors
    }

    const handleSubmit = (event) => {
        event.preventDefault()
        // console.log("this is the handle Submit");
        setErrors(validate(data))
        setIsSubmit(true)
    }


    const signup = async (userData) => {
        // openBackdrop()
        setPhoneError(false)

        dispatch(setLoading(true));
        try {
            const response = await axios.post(`${process.env.REACT_APP_STEELADMIN}/api/auth/register`, userData).then((res) => {

                // closeBackdrop()
                console.log(res.data);

                dispatch(setSuccess(true));
                navigate(`/userOtpVerify/${userData.phoneNumber}/${res.data.data.id}`,)
            }).catch((err) => {


                // if(err.response.status===409)
                // {
                //     // console.log("huu",err.response.message)
                //     setEmailError(err.response.data.message)
                // }
                if (err.response.status === 409) {
                    console.log("hey", err.response);
                    // alert();

                    setEmailError(err.response.data.message);

                }
                else if (err.response.status === 403) {
                    setPhoneError(true)
                }
                else {
                    // closeBackdrop()
                    alert("something went wrong in the process")
                }
                // closeBackdrop()
            })
        } catch (error) {

            // closeBackdrop()
            console.log(error);

            dispatch(setError(error.message));
        } finally {
            // closeBackdrop()
            dispatch(setLoading(false));
        }
    };

    useEffect(() => {
        // console.log("this is the ueeffect");
        // console.log(Object.keys(errors));
        // console.log(errors.phoneNumber);

        if (Object.keys(errors).length === 0 && isSubmit) {

            dispatch(setName(data.name))
            dispatch(setPhoneNumber(data.phoneNumber))
            dispatch(setEmail(data.email))
            dispatch(setPassword(data.password))
            signup(data)
            // navigate('/verifyOtp', { state: { user: data } })

        }
    }, [errors, isSubmit])

    const style = {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: 400,
        bgcolor: 'background.paper',
        border: '2px solid #000',
        boxShadow: 24,
        p: 4,
    }

    return (
        <>
            <Helmet>
                <html lang="en" />
                <title>Register KJS Steel</title>
                <meta name="description" content="KJS Steel is one of the leading steel plant engineering consultants in India offering EPC services as well as turnkey solutions." />
                <meta name="keywords" content="Register KJS Steel" />

            </Helmet>
            <div className="RegisterMAin">
                <div className="RegisterStart">
                    <Box className='registerBoxes1'>
                        <form className="register-form" action="" onSubmit={handleSubmit}>
                            <div className="register-heading">
                                <div className='resiterbox1'>
                                    <Typography variant='h1'>Let's create a free account</Typography>
                                    <Typography variant="h2">
                                        Already have an account?{' '}
                                        <Link to="/Signin" style={{ color: '#16469d' }}>
                                            Sign in
                                        </Link>
                                    </Typography>
                                </div>
                            </div>
                            <div className="register-details">
                                <div className='RegisterStart'
                                    style={{ display: 'flex', justifyContent: 'space-between' }}>
                                    {' '}
                                    {
                                        checkData[0]?.permission[0] &&
                                        <>

                                            <div className='FullName'
                                                style={{
                                                    width: '100%',
                                                    display: 'flex',
                                                    justifyContent: 'space-between',
                                                    flexDirection: 'column',
                                                }}
                                            >
                                                <label>Name</label>
                                                <input className='text123'
                                                    id="outlined-basic"
                                                    label="Full Name"
                                                    variant="outlined"
                                                    autoComplete='off'
                                                    onChange={(e) => handleChange(e, 'name')}
                                                    value={data.name}
                                                    // {...(checkData[0].permission[1] ? { required: 'required' } : {})}
                                                    type="text"
                                                    style={{ width: "85%", height: '36px !important' }}
                                                    InputProps={{
                                                        sx: {

                                                            fieldset: {
                                                                borderColor: errors.name
                                                                    ? 'red'
                                                                    : 'rgba(195, 195, 195, 1)',
                                                            },
                                                            display: 'flex',
                                                            height: 50,
                                                            borderRadius: '10px',
                                                            '& hover': { borderColor: '#C3C3C3' },
                                                        },
                                                    }}
                                                    sx={{
                                                        backgroundColor: 'white',
                                                    }}
                                                />
                                                {errors.name ? (
                                                    <Typography
                                                        variant="caption"
                                                        sx={{
                                                            fontWeight: '300',
                                                            textAlign: 'left',
                                                            color: 'red',

                                                            marginTop: { xs: '-13px', sm: '-13px', md: '-26px', lg: '-20px' }
                                                            // Adjust the margin top as desired


                                                        }}
                                                    >
                                                        {errors.name}
                                                    </Typography>

                                                ) : null}{' '}
                                            </div>
                                        </>
                                    }
                                </div>
                                {/* {
                                checkData[1]?.permission[0] &&
                                <> */}

                                {
                                    checkData[1]?.permission[0] &&
                                    <>

                                        <div className='fieldText'>
                                            <label style={{ display: 'flex', textAlign: 'left !important' }}>Phone</label>

                                            <PhoneInput
                                                className='myInput1'
                                                // {...(checkData[1].permission[1] ? { required: 'required' } : {})}
                                                country={'in'}
                                                value={data.phoneNumber}
                                                onChange={(phoneNumber, country) => handleChanges(country, phoneNumber)}


                                                inputProps={{
                                                    name: 'phoneNumber',
                                                    required: true,
                                                    height: 50,
                                                    '& hover': { borderColor: ' 1.5px solid #000' },
                                                    style: {
                                                        width: '100%',
                                                        height: '40px',
                                                        borderRadius: '6px',

                                                    },
                                                    autoComplete: 'off',
                                                }}
                                                inputClass="MuiInputBase-input"
                                                containerStyle={{ borderRadius: '10px !imporant' }}

                                                InputProps={{
                                                    sx: {
                                                        fieldset: {
                                                            borderColor: errors.password
                                                                ? 'red'
                                                                : 'rgba(195, 195, 195, 1)',
                                                        },
                                                        height: 50,
                                                        borderRadius: '10px',
                                                        '& hover': { borderColor: '#C3C3C3' },
                                                    },
                                                }}
                                                sx={{
                                                    backgroundColor: 'white',
                                                    width: '85% !important'
                                                }}

                                            />
                                        </div>
                                        {phoneError && <p style={{ color: "red", fontSize: "12px", display: 'flex', justifyContent: 'left', }}>Phone Already exists</p>}
                                        {errors.phoneNumber ? (
                                            <Typography
                                                variant="caption"
                                                sx={{
                                                    fontWeight: '300',
                                                    textAlign: 'left',
                                                    color: 'red !important',
                                                    marginTop: { xs: '-13px', sm: '-13px', md: '-26px', lg: '-20px' }
                                                }}
                                            >
                                                {errors.phoneNumber}
                                            </Typography>
                                        ) : null}
                                    </>
                                }
                                {
                                    checkData[2]?.permission[0] &&
                                    <div style={{display:'flex',flexDirection:'column'}}>

                                        <label>Email</label>

                                        <input
                                            className='myEmailinput'
                                            // {...(checkData[2].permission[1] ? { required: 'required' } : {})}
                                            id="outlined-basic"
                                            label="Enter Email"
                                            variant="outlined"
                                            onChange={(e) => handleChange(e, 'email')}
                                            value={data.email}
                                            type="email"
                                            style={{ width: "85%" }}
                                            autoComplete='off'
                                            InputProps={{
                                                sx: {
                                                    'fieldset': {
                                                        borderColor: errors.email
                                                            ? 'red'
                                                            : 'rgba(195, 195, 195, 1)',


                                                        '&:hover fieldset': {
                                                            borderColor: errors.email
                                                                ? 'red'
                                                                : 'rgba(195, 195, 195, 1)',
                                                        },
                                                        '&:active fieldset': {
                                                            borderColor: errors.email
                                                                ? 'red'
                                                                : 'rgba(195, 195, 195, 1)',
                                                        },
                                                        '&:Mui-focused fieldset': {
                                                            borderColor: errors.email
                                                                ? 'red'
                                                                : 'rgba(195, 195, 195, 1)',
                                                        },

                                                    },

                                                    height: 45,
                                                    borderRadius: '10px',
                                                    '& hover': { borderColor: '#C3C3C3' },
                                                },
                                            }}
                                            sx={{
                                                backgroundColor: 'white',
                                            }}
                                        />
                                        <p style={{ color: "red", fontSize: "12px", display: 'flex', justifyContent: 'left', }}>{emailError}</p>
                                        {/* <Box sx={{ color: 'red',display:'flex', }}>{emailError}</Box> */}
                                        {errors.email ? (
                                            <Typography
                                                variant="caption"
                                                sx={{
                                                    fontWeight: '300',
                                                    textAlign: 'left',
                                                    color: 'red',
                                                    marginTop: { xs: '-13px', sm: '-13px', md: '-26px', lg: '-20px' }
                                                }}
                                            >
                                                {errors.email}
                                            </Typography>
                                        ) : null}
                                    </div>
                                }

                                {
                                    checkData[3]?.permission[0] &&
                                    <div>
                                        <label>Password</label>
                                        <div style={{ position: "relative", width: '85%' }}>
                                            <input
                                                style={{ display: 'block', width: '100%' }}
                                                type={showPassword ? 'text' : 'password'}
                                                // className='myPassinput'
                                                id="outlined-adornment-password"
                                                variant="outlined"
                                                value={data.password}
                                                onChange={(e) => handleChange(e, 'password')}

                                                InputProps={{
                                                    sx: {
                                                        fieldset: {
                                                            borderColor: errors.password
                                                                ? 'red'
                                                                : 'rgba(195, 195, 195, 1)',
                                                        },
                                                        height: 50,
                                                        borderRadius: '10px',
                                                        '& hover': { borderColor: '#C3C3C3' },
                                                    },
                                                }}
                                                sx={{
                                                    backgroundColor: 'white',

                                                }}
                                            />
                                            <span style={{ position: "absolute", height: "100%", top: "0%", right: "2%", display: "flex", alignItems: "center" }}>
                                                <IconButton style={{ backgroundColor: 'transparent', color: '#585858' }}
                                                    aria-label="toggle password visibility"
                                                    onClick={() => setShowPassword(!showPassword)}

                                                >
                                                    {showPassword ? <VisibilityOff /> : <Visibility />}
                                                </IconButton>
                                            </span>
                                        </div>
                                        {errors.password ? (
                                            <Typography
                                                variant="caption"
                                                sx={{
                                                    fontWeight: '300',
                                                    textAlign: 'left',
                                                    color: 'red !important',
                                                    marginTop: { xs: '-13px', sm: '-13px', md: '-26px', lg: '-20px' }
                                                }}
                                            >
                                                {errors.password}
                                            </Typography>
                                        ) : null}
                                    </div>

                                }


                                <Typography variant='h6'>By continuing you agree to the KJSS <Link to='/terms' ><span className='termC'>    terms of service </span></Link></Typography>
                                <Button
                                    variant="contained"
                                    type="submit"
                                >
                                    Register
                                </Button>
                                <Typography variant='h4'>Buying for work? create a free  <Link to='/corporate' ><span className='termC'>Business Account</span></Link></Typography>
                            </div>
                        </form>
                    </Box>
                    {/* {***********************************Modal Added*******************************} */}
                    <Modal className='corporateModal'
                        aria-labelledby="transition-modal-title"
                        aria-describedby="transition-modal-description"
                        sx={{ bgcolor: 'transparent', }}
                        open={requestOtpOpen}
                        onClose={handleRequestClose}
                        closeAfterTransition
                        slots={{ backdrop: Backdrop }}
                        slotProps={{
                            backdrop: {
                                timeout: 500,
                            },
                        }}
                    >
                        <Fade in={requestOtpOpen}>
                            <Box sx={style} className='thirdModal'>
                                <Typography variant='h5'>Verify with OTP</Typography>
                                <Typography variant='h6'>Sent to SMS to value</Typography>
                                <Box sx={{ mt: '4%' }} className='otpInput'>
                                    {/* <input maxLength={5} type='otp' name='otp' onChange={(e) => setOtp(e.target.value)} value={otp} /> */}
                                    <input
                                        style={{ cursor: 'none' }}
                                        className="otp-input"
                                        type="number"
                                        maxLength="5"
                                        value={otp}
                                        onChange={(e) => setOtp(e.target.value)}
                                    />
                                </Box>
                                <Button fullWidth sx={{ mt: '4%', textTransform: 'capitalize', display: 'flex', justifyContent: 'center', textAlign: 'center', bgcolorcolor: '#16469D', borderRadius: '2px', fontWeight: '600', fontSize: '14px', lineHeight: '16px' }} variant="contained" onClick={handleVerifyOtp}>
                                    Verify
                                </Button>
                            </Box>
                        </Fade>
                    </Modal>
                    {/* {***********************************Modal Added*******************************} */}
                    <Box className='registerBoxes2'>
                        <Box className='RegiaterBoxStart2' >
                            <Slider {...settings}>
                                {imageslidedata.map((item) =>
                                    <div className='RegiaterMediaBox2' >
                                        <img src={item.linkImg} alt='Computers' />
                                    </div>
                                )}
                            </Slider>
                        </Box>
                        <div className='RegisterBoxInfo' >
                            <Typography variant='h4' style={{ marginBottom: "1.5%" }}>Join the Steel Revolution!</Typography>
                            <Typography variant='h6'>Shop from more than 2000 trusted suppliers <br /> for over 3000+ products</Typography>
                        </div>
                    </Box>
                </div>
                <BackdropComponent />
            </div>
        </>
    )
}

export default Register