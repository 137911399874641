import React from 'react'
import './App.css';
import store from './store/store'
import { Provider } from 'react-redux'
import MainPage from 'components/MainLandingPage/MainPage'
import { Route, Routes } from 'react-router-dom'
import ProductPage from 'components/ProductPage/ProductPage'
import Register from 'components/Register/Register'
import ComingSoon from 'components/ComingSoon/ComingSoon'
import FAQPage from 'components/FAQPage/FAQPage'
import ContactUsPage from 'components/ContactUsPage/ContactUsPage'
import ErrorLanding from 'components/ErrorLandingPage/ErrorLanding'
import PartnerPage from 'components/PartnerPage/PartnerPage'
import OurProjectsPage from 'components/OurProjectsPage/OurProjectsPage'
import RateContractPage from 'components/RateContract/RateContractPage'
import AboutUsPage from 'components/AboutUsPage/AboutUsPage'
import ProjectDescriptionPage from 'components/ProjectDescriptionPage/ProjectDescriptionPage'
import FeedbackPage from 'components/FeedbackPage/FeedbackPage'
import VerifyEmail from 'components/Register/VerifyEmail'
import OTPVerification from 'components/OTPVerification/OTPVerification'
import EmailVerifing from 'components/EmailVerification/EmailVerifing'
// import RelatedProjectsDescPage from 'components/RelatedProjectsDescPage/RelatedProjectsDescPage'
import ScrollToTop from 'components/ScrollToTop'
import ComingSoonLandingPg from 'components/ComingSoonLandingPg/ComingSoonLandingPg'
import ForgotPassword from 'components/ForgotPassword/ForgotPassword'
import ForgotPassOTP from 'components/ForgotPassOTP/ForgotPassOTP'
import NewPassword from 'components/NewPassword/NewPassword'
import DisclaimerPage from 'components/DisclaimerPage/DisclaimerPage'
import PrivacyPage from 'components/PrivacyPage/PrivacyPage'
import TermsPage from 'components/TermsPage/TermsPage'
import Brochure from 'components/Brochure/Brochure'
import { useRef, useEffect } from 'react';
import AllVerification from 'components/AllVerification/AllVerification'
import VendorRegister from 'components/VendorRegister/VendorRegister'
import VendorRegisterFirst from 'components/VendorRegisterFirst/VendorRegisterFirst'
import VendorEmailVerification from 'components/VendorEmailVerification/VendorEmailVerification'
import UserLogin from "components/UserLogin/UserLogin";
import NewLogin from 'components/NewLogin/NewLogin'
import CorporateRegistration from 'components/CorporateRegistration/CorporateRegistration'
import CorporateEmailVerification from 'components/CorporateEmailVerification/CorporateEmailVerification'
import UserRegisterEmail from 'components/NormalRegisterEmail/UserRegisterEmail'

import AllRegistration from 'components/AllRegistration/AllRegistration'

// import GetNews from 'components/GetNews/GetNews'




const App = () => {

    const captureRef = useRef(null);

  const handleContextMenu = (event) => {
    event.preventDefault();
  };

  const handleKeyDown = (event) => {
    if (event.code === 'KeyI' && event.ctrlKey && event.shiftKey) {
      event.preventDefault();
    }
  };

  useEffect(() => {
    window.addEventListener('contextmenu', handleContextMenu);
    window.addEventListener('keydown', handleKeyDown);
    return () => {
      window.removeEventListener('contextmenu', handleContextMenu);
      window.removeEventListener('keydown', handleKeyDown);
    };
  }, []);



    return (
        <Provider store={store}>
            {/* <SettingsProvider> */}

            <div
             onContextMenu={handleContextMenu} onKeyDown={handleKeyDown}
            >
                <ScrollToTop />
                <Routes
                 ref={captureRef}
                >
                    <Route path="/become-a-buyer" element={<Register />} />
                    <Route path="/" element={<MainPage />} />
                    <Route path="/home" element={<MainPage />} />
                    <Route path="/products" element={<ProductPage />} />
                    <Route path="/coming-soon" element={<ComingSoonLandingPg />} />
                    <Route path="/faq" element={<FAQPage />} />
                    <Route path="/contact-us" element={<ContactUsPage />} />
                    <Route path="/portaldetail/:id" element={<RateContractPage />} />
                    <Route path="/partner-with-us" element={<PartnerPage />} />
                    <Route path="/about-us" element={<AboutUsPage />} />
                    <Route path="/projects" element={<OurProjectsPage />} />
                    {/* <Route path="/partner-page" element={<PartnerPage />} /> */}
                    <Route path='/product-catalogue' element={<Brochure />} />
                    <Route
                        path="/projects/:projectName/:projectId"
                        element={<ProjectDescriptionPage />} />
                    {/* <Route path="/relatedprojects/:id" element={<RelatedProjectsDescPage />} /> */}
                    <Route path="/verifyEmail" element={<VerifyEmail />} />
                    <Route path="/verify" element={<EmailVerifing />} />
                    <Route path="/verifyOtp" element={<OTPVerification />} />
                    <Route path="/forgotpassword" element={<ForgotPassword />} />
                    <Route path="/forgotpassOTP/:email" element={<ForgotPassOTP />} />
                    <Route path="/newpassword" element={<NewPassword />} />
                    <Route path='/disclaimer' element={<DisclaimerPage />} />
                    <Route path='/privacy' element={<PrivacyPage />} />
                    <Route path='/terms' element={<TermsPage />} />
                    <Route path="/feedback" element={<FeedbackPage />} />
                    <Route path="/ulogin" element={<UserLogin />} />
                    <Route path="/Signin" element={<NewLogin />} />
                    <Route path="/userOtpVerify/:userPhoneNumber/:id" element={<UserRegisterEmail />} />
                    <Route path="/become-a-seller" element={<VendorRegisterFirst />} />
                    <Route path="/vendorregister/:businessEmail" element={<VendorRegister />} />
                    <Route path="/vendoremailverification/:businessEmail/:id" element={<VendorEmailVerification />} />
                    <Route path="/corporate" element={<CorporateRegistration />} />
                    <Route path="/corporateemail/:corporateEmail/:id" element={<CorporateEmailVerification />} />
                    <Route path="/allverification" element={<AllVerification />} />
                    <Route path="/allRegistration" element={<AllRegistration />} />
        
                </Routes>

            </div>

        </Provider>
    )
}

export default App
