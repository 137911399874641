import React, { useState } from 'react';
import "./scss/AllRegistration.css";
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import { Typography, Select, MenuItem } from '@mui/material';
import { Link } from 'react-router-dom';
import Slider from 'react-slick';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import { Visibility, VisibilityOff } from '@mui/icons-material';
import IconButton from '@mui/material/IconButton';
import InputAdornment from '@mui/material/InputAdornment';
import ErrorIcon from '@mui/icons-material/Error';

const data = [
    {
        id: 1,
        linkImg: 'https://res.cloudinary.com/dtffniutw/image/upload/v1689518380/Mockup_1_1_vilx0a.svg',
    },
    {
        id: 2,
        linkImg: 'https://res.cloudinary.com/dtffniutw/image/upload/v1689518297/Mockup_PSD_FREE___iPad_Pro___Pencil_2018-removebg-preview_1_bnh73w.svg',
    },
    {
        id: 3,
        linkImg: 'https://res.cloudinary.com/dtffniutw/image/upload/v1689518380/Mockup_1_1_vilx0a.svg',
    },
    {
        id: 4,
        linkImg: 'https://res.cloudinary.com/dtffniutw/image/upload/v1689518297/Mockup_PSD_FREE___iPad_Pro___Pencil_2018-removebg-preview_1_bnh73w.svg',
    }
];

const AllRegistration = () => {
    var settings = {
        fade: true,
        dots: true,
        arrows: false,
        infinite: true,
        slidesToShow: 1,
        slidesToScroll: 1,
        autoplay: true,
        autoplaySpeed: 2000,
        cssEase: 'cubic-bezier(0.7, 0, 0.3, 1)',
    };
    const navigate = useNavigate();
    const options = ['User', 'Vendor', 'Corporate'];
    const [selectedOption, setSelectedOption] = useState(options[0]);

    const handleSelectChange = (event) => {
        setSelectedOption(event.target.value);
    };

    const handleContinue = () => {
        if (selectedOption === 'User') {
            navigate('/sig-nup');
        } else if (selectedOption === 'Vendor') {
            navigate('/become-a-seller');
        } else if (selectedOption === 'Corporate') {
            navigate('/corporate');
        }
    };

    return (
        <div className='USerLOginMAin'>
            <div className='USerLOginStart'>
                <Box className='USerLOginBox'>
                    <form className='USerLOginFOrm'>
                        <Typography variant='h3'>Register As! 👋</Typography>
                        <div className='inputRows'>
                            <div className='selectContainer'>
                            <Select
                                    id="dropdown"
                                    value={selectedOption}
                                    onChange={handleSelectChange}
                                    className='customSelect'
                                >
                                    {options.map((option, index) => (
                                        <MenuItem key={index} value={option}>
                                            {option}
                                        </MenuItem>
                                    ))}
                                </Select>
                            </div>
                            <Button className='continueButton' onClick={handleContinue}>
                                Continue
                            </Button>
                        </div>
                    </form>
                </Box>
                <Box className='USerLOginBox2'>
                    <Box className='USerBoxSTart2'>
                        <Slider {...settings}>
                            {data.map((item) => (
                                <div className='USerMEdiaBox2' key={item.id}>
                                    <img src={item.linkImg} alt='Computer' />
                                </div>
                            ))}
                        </Slider>
                    </Box>
                    <div className='USerBOxInfo'>
                        <Typography variant='h4' style={{ marginBottom: "1.5%" }}>Join the Steel Revolution!</Typography>
                        <Typography variant='h6'>Shop from more than 2000 trusted suppliers <br />for over 3000+ products</Typography>
                    </div>
                </Box>
            </div>
        </div>
    );
};

export default AllRegistration;
