import { Box, Button, Typography } from '@mui/material';
import React, { useEffect, useState } from 'react';
import './scss/VendorEmailVerification.css';
import myImage from '../../assets/images/new/Mail.svg';
import axios from 'axios';
import { useParams } from 'react-router-dom';
import { useNavigate, useLocation } from 'react-router-dom';
import { MuiOtpInput } from 'mui-one-time-password-input'
import ErrorIcon from '@mui/icons-material/Error';
import '../OTPFieldCSS/scss/OTPFieldcss.css';

import useBackdrop from 'hooks/useBackdrop';

import Slider from 'react-slick';

const EmailVerOTPSlider = [
  {
    id: 1,
    linkImg: 'https://res.cloudinary.com/dtffniutw/image/upload/v1689518380/Mockup_1_1_vilx0a.svg',
  },
  {
    id: 2,
    linkImg: 'https://res.cloudinary.com/dtffniutw/image/upload/v1689518297/Mockup_PSD_FREE___iPad_Pro___Pencil_2018-removebg-preview_1_bnh73w.svg',
  },
  {
    id: 3,
    linkImg: 'https://res.cloudinary.com/dtffniutw/image/upload/v1689518380/Mockup_1_1_vilx0a.svg',
  },
  {
    id: 4,
    linkImg: 'https://res.cloudinary.com/dtffniutw/image/upload/v1689518297/Mockup_PSD_FREE___iPad_Pro___Pencil_2018-removebg-preview_1_bnh73w.svg',
  }
]



function VendorEmailVerification() {

  var settings = {
    fade: true,
    dots: true,
    arrows: false,
    infinite: true,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 2000,
    cssEase: 'cubic-bezier(0.7, 0, 0.3, 1)',
  };

  const { openBackdrop, closeBackdrop, BackdropComponent } = useBackdrop();

  const [otpValue, setOtpValue] = useState('');

  const navigate = useNavigate();
  const location = useLocation();
  const [otpError, setOtpError] = useState(false);
  const { businessData } = location.state;


  const [isResendButtonDisabled, setIsResendButtonDisabled] = useState(true);
  const [remainingTime, setRemainingTime] = useState(60);

  useEffect(() => {
    if (isResendButtonDisabled) {
      const timer = setInterval(() => {
        setRemainingTime((prev) => prev - 1);
      }, 1000);

      return () => clearInterval(timer);
    }

  }, [isResendButtonDisabled]);

  useEffect(() => {

    if (remainingTime <= 0) {
      setIsResendButtonDisabled(false);
    }
  }, [remainingTime]);


  const handleKeyPress = (e) => {
    const keyCode = e.keyCode || e.which;
    const keyValue = String.fromCharCode(keyCode);
    // Allow number keys (both top row and numeric keypad) and backspace/delete
    const validInput = /^[0-9\b]+$/.test(keyValue) || (
      (keyCode >= 96 && keyCode <= 105) && // Numeric keypad
      (keyCode !== 110 && keyCode !== 190) // Exclude decimal point
    );
    if (!validInput) {
      e.preventDefault();
    }
  };


  // const [data, setData] = useState({
  //   email: ''
  // });
  // const email = [businessEmail, setBusinessEmail]= ({})
  const { businessEmail, id } = useParams(); // Get businessEmail and id from URL params

  // const handleChange = (event) => {
  //   setData({ ...data, email: event.target.value });
  // };

  const handleEmailVerification = async () => {
    openBackdrop();
    setOtpError(false); // Reset otpError to false before making the API call

    try {
      const response = await axios.post(
        `${process.env.REACT_APP_STEELADMIN}/vendor/verifyEmailOtp/${businessEmail}`,
        { verifyEmailOtp: otpValue },
        {
          headers: {
            'Content-Type': 'application/json',
          },
        }
      );
      // console.log("hello", response.statusCode);
      if (response.data.statusCode === 200) {
        closeBackdrop()
        navigate(`/vendorregister/${businessEmail}`, { state: { businessData: businessData } });
        // navigate(`/vendoremailverification/${data.businessEmail}`);
      } else {
        console.log("API called failed");
        closeBackdrop()

      }
      setOtpValue('');
      closeBackdrop();
      // console.log(response.data);

    } catch (err) {
      closeBackdrop();
      // console.log(err.response.status)
      if (err.response.status === 400) {
        setOtpError(true);


      }
      else {
        alert("soemthing went wrong with processs")
      }
    }
  };

  // ********************************************Email Resend OTP START ********************************
  const handleResendOtp = async () => {

    setIsResendButtonDisabled(true);
    setRemainingTime(60);
    setOtpError(false);
    const timer = setInterval(() => {
      setRemainingTime((prev) => prev - 1);
    }, 1000);

    try {
      const response = await axios.post(
        `${process.env.REACT_APP_STEELADMIN}/vendor/vendorResendOtpOnEmail/${businessEmail}/${id}`,

        {
          headers: {
            'Content-Type': 'application/json',
          },
        }
      ).then((res) => {
        setOtpValue('')

      }).catch((err) => {
        setOtpValue('')

        console.log("err occured in the api ");

        alert("otp resend failed ")
      })
      // .then( (res)=>{
      //   console.log("otp verified")
      //   navigate("/verify")
      // }).catch((err)=>{
      //   console.log("error occured "+err.response.status)
      // })



    } catch (err) {


      console.log(err);

    }
    return () => clearInterval(timer);
    // return ()=>clearTimeout(ttt);

  };
  // *************************************Email Resend OTP ENd***************************************







  return (
    <div className='vendorEmailVerOTPMain'>
      <div className='vendorEmailVerStart'>

        <Box className='OTPBox'>
          <form action='' className='OTPForm'>
            <div className='OTPItem'>
              <Typography variant='h3'>Lets go!</Typography>
            </div>

            <div className='OTPItem'>
              <img className='mailImg' src={myImage} alt="Mail Icon" />
            </div>

            <div className='OTPMailPart'>
              <div className='OTPItem'>
                <Typography variant='h5'>We’ve sent a code to <strong>{businessEmail}</strong></Typography>
              </div>

              <div className='OTPItem'>
                <MuiOtpInput
                  className='muiOTPPField'
                  length={5}
                  value={otpValue}
                  onChange={(tt) => { setOtpValue(tt) }}
                  onKeyDown={handleKeyPress}
                />
                {otpError &&
                  <p style={{
                    width: '80%',
                    border: '2px solid 5F5E5E',
                    padding: '1%',
                    marginTop: '2%',
                    justifyContent: 'center',
                    display: 'flex',
                    borderRadius: '4px',
                    fontSize: '14px',
                    color: 'red',
                  }}> <ErrorIcon sx={{ color: '#ff5d5d', marginRight: '8px' }} /> Incorrect OTP</p>}
              </div>

              <div className='OTPItem'>
                <Button className='verifyButtonn' variant='contained'
                  onClick={handleEmailVerification}>
                  Verify
                </Button>
              </div>

              <div className='OTPItem'>
                <Typography
                  variant='p' className='didntOTPText'>
                  Didn't receive OTP?
                  <Button onClick={handleResendOtp} className='didntOTPButton'
                    disabled={isResendButtonDisabled}>{isResendButtonDisabled ? `00:${remainingTime}` : "Resend"}</Button>
                </Typography>
              </div>

            </div>
          </form>
        </Box>





        <Box className='EmailVerOTPLOginBox2'>
          <Box className='EmailVerOTPBoxSTart2'>
            <Slider {...settings}>
              {EmailVerOTPSlider.map((item) =>
                <div className='EmailVerOTPMEdiaBox2' >
                  <img src={item.linkImg} alt='Computer' />
                </div>
              )}
            </Slider>
          </Box>
          <div className='EmailVerrrOTPBOxInfo' >
            <Typography variant='h4' style={{ marginBottom: "1.5%" }}>Join the Steel Revolution!</Typography>
            <Typography variant='h6'>Shop from more than 2000 trusted suppliers <br />for over 3000+ products</Typography>
          </div>
        </Box>

        <BackdropComponent />

      </div>
    </div>





  );
}


export default VendorEmailVerification;
