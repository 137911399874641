import React from 'react'
import './scss/AboutUsPageContent.css'
import Box from '@mui/material/Box'
import { Typography } from '@mui/material'
import DoneIcon from '@mui/icons-material/Done'
import Button from '@mui/material/Button'
import { Link } from 'react-router-dom'
const AboutUsPageContent = () => {
    return (
        <div className="AboutUsPageContent">
            <div className="AboutUsPageContentMain">
                <div className="AboutUsPageContentInfo">
                    <Box
                        sx={{
                            width: '100%',
                            display: 'flex',
                            alignItems: 'flex-start',
                            marginBottom: '2%',
                        }}
                    >
                        <Box
                            className="AboutUsPageKjss"
                            sx={{
                                width: '45%',
                                display: 'flex',
                                fontSize: '18px',
                                fontWeight: '600',
                                padding: '10px 0 10px 20px',
                                alignItems: 'center',
                                color: '#585858',
                                backgroundColor: '#F4F4F4',
                            }}
                        >
                            Welcome To KJSS STEEL
                        </Box>
                    </Box>
                    <Box
                        className="AboutUsContentPara"
                        sx={{
                            width: '92%',
                            display: 'flex',
                            marginBottom: '4%',
                        }}
                    >
                        <Typography variant='h6'
                            sx={{
                                color: 'rgba(126, 126, 126, 1)',
                                fontSize: '15px',
                                fontWeight: '400',
                                lineHeight: '23px',
                                textAlign:'justify',
                            }}
                        >
                            We are a steel start-up company committed to
                            delivering high-quality steel products to our
                            customers. Our team consists of experienced
                            professionals with a wealth of knowledge in the
                            steel industry. We offer a wide range of steel
                            products and services for various applications, from
                            Construction, Engineering, Consulting, Plant
                            set-ups, and infrastructure to transportation and
                            energy supported with latest technologies. At our
                            company, we are committed to sustainability and
                            environmental responsibility while ensuring the
                            safety and well-being of our employees and
                            communities.
                        </Typography>
                    </Box>
                    <div className="AboutUsPageContentBGHide">
                        <Box className="AboutUsPageContentBackground"></Box>
                    </div>
                    <Box
                        className="AboutUsPageContentIcons"
                        sx={{
                            width: '100%',
                            display: 'flex',
                            alignItems: 'flex-start',
                            justifyContent: 'space-between',
                        }}
                    >
                        <Box
                            className="AboutUSPageBar"
                            sx={{
                                width: '50%',
                                display: 'flex',
                                flexDirection: 'column',
                                justifyItems: 'center',
                                fontSize: '22px',
                                marginBottom: '4%',
                            }}
                        >
                            <Box
                                sx={{
                                    width: '100%',
                                    display: 'flex',
                                    alignItems: 'center',
                                    paddingBottom: '5%',
                                }}
                            >
                                <Box className="TickBox">
                                    <DoneIcon className='Tickmark' sx={{ fontSize: '25px', color: '#fff' }} />
                                </Box>
                                <Box
                                    className="AboutUSheader"
                                    sx={{
                                        fontSize: '15px',
                                        fontWeight: '400',
                                        color: '#585858',
                                    }}
                                >
                                    24/7 support
                                </Box>
                            </Box>
                            <Box
                                sx={{
                                    width: '100%',
                                    display: 'flex',
                                    alignItems: 'center',
                                    paddingBottom: '5%',
                                }}
                            >
                                <Box className="TickBox">
                                    <DoneIcon className='Tickmark' sx={{ fontSize: '25px', color: '#fff' }} />
                                </Box>
                                <Box
                                    className="AboutUSheader"
                                    sx={{
                                        fontSize: '15px',
                                        fontWeight: '400',
                                        color: '#585858',
                                    }}
                                >
                                    Experienced professionals
                                </Box>
                            </Box>
                        </Box>
                        <Box
                            className="AboutUSPageBar"
                            sx={{
                                width: '50%',
                                display: 'flex',
                                flexDirection: 'column',
                                justifyItems: 'center',
                                fontSize: '22px',
                            }}
                        >
                            <Box
                                sx={{
                                    width: '100%',
                                    display: 'flex',
                                    alignItems: 'center',
                                    paddingBottom: '5%',
                                }}
                            >
                                <Box className="TickBox">
                                    <DoneIcon className='Tickmark' sx={{ fontSize: '25px', color: '#fff' }} />
                                </Box>
                                <Box
                                    className="AboutUSheader"
                                    sx={{
                                        fontSize: '15px',
                                        fontWeight: '400',
                                        color: '#585858',
                                    }}
                                >
                                    Cutting edge technology
                                </Box>
                            </Box>
                            <Box
                                sx={{
                                    width: '100%',
                                    display: 'flex',
                                    alignItems: 'center',
                                    paddingBottom: '5%',
                                }}
                            >
                                <Box className="TickBox">
                                    <DoneIcon className='Tickmark' sx={{ fontSize: '25px', color: '#fff' }} />
                                </Box>
                                <Box
                                    className="AboutUSheader"
                                    sx={{
                                        fontSize: '15px',
                                        fontWeight: '400',
                                        color: '#585858',
                                    }}
                                >
                                    Verified vendors
                                </Box>
                            </Box>
                        </Box>
                    </Box>
                    <Box className="ContentbuttonBox">
                        <a href="https://www.kjsscosmos.com/about-us" className='ContentBtnStart' target='_blank' style={{ textDecoration: 'none' }}>
                            <Button
                                className="MyContentBtn"
                                variant="contained"
                                sx={{
                                    width: '100%',
                                    padding: '4% 2%',
                                    fontSize: '18px',
                                    textTransform: 'capitalize',
                                    backgroundColor: '#16469D',
                                    color: '#',
                                    marginRight: '20%',
                                    '&:hover': { backgroundColor: '#16469D' },
                                }}
                            >
                                More About Us
                            </Button>
                        </a>
                        <Box className="MyContentBtnInfo" sx={{ ml: '10%' }}>
                            <Typography
                                sx={{
                                    width: '100%',
                                    textDecoration: 'underline',
                                    color: '#585858',
                                    fontSize: '15px',
                                    fontWeight: '400',
                                }}
                            >
                                For more details or appointment
                            </Typography>
                            <Typography
                                sx={{
                                    width: '100%',

                                    fontSize: '25px',
                                    color: '#16469D',
                                    fontWeight: '500',
                                    fontSize: '20px',
                                }}
                            >
                                93217 14108/ 104
                            </Typography>
                        </Box>
                    </Box>
                </div>
                <div className="AboutUsPageContentBG">
                    <Box className="AboutUsPageContentBackground"></Box>
                </div>
            </div>
        </div>
    )
}

export default AboutUsPageContent
