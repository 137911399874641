import React from 'react'
import { Typography, Box, Container } from '@mui/material'
import './scss/PartnerProgram.css'
import { Image } from 'cloudinary-react'


const PartnerWorkLine = ({ info, image }) => {


    return (
        <div className='singleBoxCon'>
            <div className='circleShape'>
                <img src={image} alt='Image'></img>
            </div>
            <div className='BoxCnt'>
                <p>{info}</p>
            </div>
        </div>
    )
}

const workData = [
    {
        info: "Join the programme by clicking on the ‘Become a Partner’ button",
        image: "https://res.cloudinary.com/dxluokypg/image/upload/v1682421331/span_xcyq82.svg",

    },
    {
        info: "Hit the ground running with the help of our structured onboarding and orientation process.",
        image: "https://res.cloudinary.com/dxluokypg/image/upload/v1682421492/span_2_pjas2y.svg",
    },
    {
        info: "Receive extensive resources - from marketing & product collaterals to training and certification.",
        image: "https://res.cloudinary.com/dxluokypg/image/upload/v1682421331/span_1_vwwhkh.svg",
    },
    {
        info: "Co-create value leveraging our ongoing engagement and support framework.",
        image: "https://res.cloudinary.com/dxluokypg/image/upload/v1682421659/span_3_zzh55h.svg",
    },
    {
        info: "Enjoy incremental rewards as you advance through different partner levels.",
        image: "https://res.cloudinary.com/dxluokypg/image/upload/v1682421715/span_4_qlx5te.svg",
    },
]

const PartnerProgram = () => {
    return (
        <div className='workConMain'>
            <div className='worksCon' >
                <Typography variant="p" className="PartnerprogramHead"
                    sx={{

                        fontWeight: { sm: '700', md: '700', lg: '700' },
                        fontSize: { sm: '16px', md: '16px', lg: '30px' },
                        lineHeight: { sm: '27px', md: '27px', lg: '39px' },
                        // color: '#585858',
                        mb: '8%',
                        p: '0%',
                        display: 'flex',
                        justifyContent: 'center',
                        textAlign: 'center',
                    }}
                >
                    How Our Global Partner Program Works
                </Typography>

                <div className="lineCon">

                    <div className="BoxCon">

                        {
                            workData.map((work) => {
                                return <PartnerWorkLine info={work.info} image={work.image} />
                            })
                        }


                    </div>
                </div>
            </div>
            <Box className='NewBoxCon'>
                <Typography variant='h6'>How Our Global Partner Program Works</Typography>
                {
                    workData.map((work) => {
                        return <PartnerWorkLine info={work.info} image={work.image} />
                    })
                }

            </Box>
        </div>
    )
}

export default PartnerProgram
