import { Box, Button, Typography } from '@mui/material';
import React, { useState, useEffect } from 'react';
import './scsss/CorporateEmailVerification.css';
import myImage from '../../assets/images/new/Mail.svg';
import axios from 'axios';
import { useParams } from 'react-router-dom';
import { useNavigate, useLocation } from 'react-router-dom';
import Slider from 'react-slick';
import { MuiOtpInput } from 'mui-one-time-password-input'
import useBackdrop from 'hooks/useBackdrop';
import '../OTPFieldCSS/scss/OTPFieldcss.css';
import ErrorIcon from '@mui/icons-material/Error';

const imageslidedata = [
  {
    id: 1,
    linkImg: 'https://res.cloudinary.com/dtffniutw/image/upload/v1689518380/Mockup_1_1_vilx0a.svg',
  },
  {
    id: 2,
    linkImg: 'https://res.cloudinary.com/dtffniutw/image/upload/v1689518297/Mockup_PSD_FREE___iPad_Pro___Pencil_2018-removebg-preview_1_bnh73w.svg',
  },
  {
    id: 3,
    linkImg: 'https://res.cloudinary.com/dtffniutw/image/upload/v1689518380/Mockup_1_1_vilx0a.svg',
  },
  {
    id: 4,
    linkImg: 'https://res.cloudinary.com/dtffniutw/image/upload/v1689518297/Mockup_PSD_FREE___iPad_Pro___Pencil_2018-removebg-preview_1_bnh73w.svg',
  },

]

function CorporateEmailVerification() {
  var settings = {
    fade: true,
    dots: true,
    arrows: false,
    infinite: true,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 2000,
    cssEase: 'cubic-bezier(0.7, 0, 0.3, 1)',


  };
  const { openBackdrop, closeBackdrop, BackdropComponent } = useBackdrop();

  const [otpValue, setOtpValue] = useState('');
  const [otpError, setOtpError] = useState('');

  const navigate = useNavigate();
  const location = useLocation();

  const { corporateData } = location.state;

  const handleKeyPress = (e) => {
    const keyCode = e.keyCode || e.which;
    const keyValue = String.fromCharCode(keyCode);
    // Allow number keys (both top row and numeric keypad) and backspace/delete
    const validInput = /^[0-9\b]+$/.test(keyValue) || (
      (keyCode >= 96 && keyCode <= 105) && // Numeric keypad
      (keyCode !== 110 && keyCode !== 190) // Exclude decimal point
    );
    if (!validInput) {
      e.preventDefault();
    }
  };

  // const [data, setData] = useState({
  //   email: ''
  // });
  // const email = [businessEmail, setBusinessEmail]= ({})
  const { corporateEmail, id } = useParams(); // Get businessEmail and id from URL params

  // const handleChange = (event) => {
  //   setData({ ...data, email: event.target.value });
  // };

  // //   ******************8newAPI********************
  // corporate/corporateRegister/{email}



  // ************Timer Start************
  const [isResendButtonDisabled, setIsResendButtonDisabled] = useState(true);
  const [remainingTime, setRemainingTime] = useState(60);

  useEffect(() => {
    if (isResendButtonDisabled) {
      const timer = setInterval(() => {
        setRemainingTime((prev) => prev - 1);
      }, 1000*60);

      return () => clearInterval(timer);
    }

  }, [isResendButtonDisabled]);

  useEffect(() => {

    if (remainingTime <= 0) {
      setIsResendButtonDisabled(false);
    }
  }, [remainingTime]);


  const handleEmailVerification = async () => {
    openBackdrop()
    setIsResendButtonDisabled(true);
    setRemainingTime(60);
    setOtpError("")
    const timer = setInterval(() => {
      setRemainingTime((prev) => prev - 1);
    }, 1000);
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_STEELADMIN}/corporate/verifyEmailOtp/${corporateEmail}`,
        {
          verifyEmailOtp: otpValue,

        },
        {
          headers: {
            'Content-Type': 'application/json',
          },
        }
      );

      const response2 = await axios.post(
        `${process.env.REACT_APP_STEELADMIN}/corporate/corporateRegister/${corporateEmail}`,
        corporateData, // Send the form data in the request body
        {
          headers: {
            'Content-Type': 'application/json',
          },
        }
      );
      // console.log("hello", response2);
      if (response2.status === 201) {
        closeBackdrop()
        navigate(`/allverification`,);
        // navigate(`/vendoremailverification/${data.businessEmail}`);
      } else {
closeBackdrop();
        setOtpError('Incorrect OTP');
        console.log("API call failed");

        // navigate('/vendorregister');

      }

      // Check the response data to determine if the email OTP was verified successfully
      // For example, you can use response.data.otpVerified to check if it's true or false.
      // console.log(response.data);
      closeBackdrop()
    } catch (err) {

      // closeBackdrop()
      closeBackdrop()
      console.log(err);
      setOtpError('Error occurred during verification')
      // Handle error here if the API call fails
      // You can also set an error state and display a user-friendly error message on the UI.
    }
    closeBackdrop()
    return () => clearInterval(timer);
  };

  // ********************************************Email Resend OTP START ********************************
  const handleResendOtp = async () => {
    setIsResendButtonDisabled(true);
    setRemainingTime(60);
    setOtpError('')
    const timer = setInterval(() => {
      setRemainingTime((prev) => prev - 1);
    }, 1000*60);
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_STEELADMIN}/corporate/corporateResendOtpOnEmail/${corporateEmail}/${id}`,

        {
          headers: {
            'Content-Type': 'application/json',
          },
        }
      ).then((err) => {

      }).catch((err) => {
        // console.log("err occured in the api ");
        alert("otp resend failed ")
      })
      // .then( (res)=>{
      //   console.log("otp verified")
      //   navigate("/verify")
      // }).catch((err)=>{
      //   console.log("error occured "+err.response.status)
      // })



    } catch (err) {
      // console.log(err);
      // Handle error here if the API call fails
      // You can also set an error state and display a user-friendly error message on the UI.
    }
  };
  // *************************************Email Resend OTP ENd***************************************

  return (
    <div className='optVerification' style={{ width: '100%', display: 'flex' }}>
      <Box className='OTPBox'>
        <form action='' className='OTPForm'>
          <div className='OTPItem'>
            <Typography variant='h3'>Lets go!</Typography>
          </div>

          <div className='OTPItem'>
            <img className='mailImg' src={myImage} alt="Mail Icon" />
          </div>


          <div className='OTPMailPart'>
            <div className='OTPItem'>
              <h5>We’ve sent a code to <strong>{corporateEmail}</strong></h5>
            </div>

            <div className='OTPItem'>
              <MuiOtpInput
              className='muiOTPPField'
                length={5}
                value={otpValue}
                onChange={(e) => setOtpValue(e)}
                onKeyDown={handleKeyPress} 
              />
              {otpError &&
                  <p style={{
                    width: '80%',
                    border: '2px solid 5F5E5E',
                    padding: '1%',
                    marginTop: '2%',
                    justifyContent: 'center',
                    display: 'flex',
                    borderRadius: '4px',
                    fontSize: '14px',
                    color: 'red',
                  }}> <ErrorIcon sx={{ color: '#ff5d5d', marginRight: '8px' }} /> Incorrect OTP</p>}
            </div>


            <div className='OTPItem'>
              <Button className='verifyButtonn' variant='contained' onClick={handleEmailVerification}>
                Verify
              </Button>
            </div>

            <div className='OTPItem'>
              <Typography
                variant='p' className='didntOTPText'>
                Didn't receive OTP?
                <Button className='didntOTPButton'
                  onClick={handleResendOtp} 
                  disabled={isResendButtonDisabled}>{isResendButtonDisabled ? `00:${remainingTime}` : "Resend"}</Button>
              </Typography>



              {/* <a style={{marginTop:"2%", width:'100%',fontSize:'14px',display:'flex',justifyContent:'center', color:'#929292',fontWeight:'400'}}>
          Didn’t receive the code? 
          <span
          style={{
            fontSize:'14px',
            color:'#3578DE',
            marginLeft:'1%',
            cursor:'pointer',
            fontWeight:'590'}}
            onClick={handleResendOtp}>Resend OTP</span>
          </a> */}

            </div>
          </div>
        </form>
      </Box>

      <Box className='Coporateverification'>
        <Box className='RegiaterBoxStart2' >
          <Slider {...settings}>
            {imageslidedata.map((item) =>
              <div className='RegiaterMediaBox2' >
                <img src={item.linkImg} alt='Computers' />
              </div>
            )}
          </Slider>
        </Box>

        <div className='BoxInfo' >
          <Typography variant='h4' style={{ marginBottom: "1.5%" }}>Join the Steel Revolution!</Typography>
          <Typography variant='h6'>Shop from more than 2000 trusted suppliers <br />for over 3000+ products</Typography>

        </div>
      </Box>
      <BackdropComponent/>
    </div>
  );
}

export default CorporateEmailVerification;
