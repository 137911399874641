import React from 'react';
import "./scss/ProjectDescriptionPageMain.css";
import Typography from '@mui/material/Typography';
import { Box } from '@mui/system';

const RelatedProjectDescriptionPageMain = (props) => {
  const { data } = props;

 
  return (
    <>
      <div className='ProjectDescPageMainInfo'>
        <Box className='ProjectDescPageMainContent'>
          <h5 style={{ color: '#585858', fontSize: '30px', fontWeight: '500', marginBottom: '2%' }}>Project Description </h5>
          <div dangerouslySetInnerHTML={{ __html: data.projDesc }} />
        </Box>
      </div>
      {data.projLink && ( // Check if data.projLink is present
        <div style={{ width: "100%", display: "flex", justifyContent: "center" }}>
        <div className='ProjectDescPage'
         style={{ width: "85%", display: "flex", flexDirection: "column",alignItems:"flex-start" }}>
          <label htmlFor="projLink" 
          
          style={{
            // width:'80%',
            display:'flex',
            justifyContent:'center',
            alignItems:'center',
            color: '#585858', fontSize: '30px', fontWeight: '500', marginBottom: '2%' }}>Link</label>
          <a
          style={{
            display:'flex',
            justifyContent:'center',
            color:'grey',
            // width:'100%',
            // marginRight:'30%',
            marginBottom:'5%',
            color:'#2458B7',
          }} target='_blank' href={data.projLink}>{data.projLink}</a>
        </div>
        </div>
      )}
    </>
  );
}

export default RelatedProjectDescriptionPageMain;
