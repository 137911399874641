import { Box, Button, Typography } from '@mui/material'
import React from 'react'
import { PopupWidget } from 'react-calendly';
import { PopupButton } from "react-calendly";

import './scss/ContactUsSchedule.css'

const ContactUsSchedule = () => {
    return (
        <div className="Schedule" style={{ backgroundColor: '#F6FAFF' }}>
            <Box className="ScheduleContent">
                <Box className="ScheduleBox1" >
                    <Typography
                        variant="h3"
                        sx={{
                            fontSize: '30px',
                            fontWeight: '700',
                            lineHeight: '24px',
                        }}
                    >
                        Book your appointment with us!
                    </Typography>
                    <Typography
                        variant="h5"
                        sx={{
                            fontSize: '15px',
                            fontWeight: '400',
                            lineHeight: '19px',
                            color: '#585858',
                            mt: '5%',
                            width: '85%',
                            textAlign:'justify',
                        }}
                    >
                        Thank you for showing interest in us! We would be happy to schedule a meeting to discuss your steel needs, answer any questions you may have, and provide you with more information about our products and services.
                    </Typography>
                    <Box className="ScheduleBoxHide">
                        <img src="https://res.cloudinary.com/dxluokypg/image/upload/v1683269548/Calendly_s_scoppttt_x6jjdj.webp" alt='calendly'/>
                    </Box>
                    <PopupButton
                        url="https://calendly.com/info-kjssteel/leads?primary_color=f5ac38%27"
                        styles={{
                            backgroundColor: '#16469D',
                            color: '#ffffff',
                            padding: '15px',
                            marginTop: '3%',
             
                            border: 'none',
                            borderRadius: '4px',
                            cursor: 'pointer',
                            "&:hover": { backgroundColor: "#F5AC38" }
                        }}
                        rootElement={document.getElementById("root")}
                        text="Schedule Now"
                    />
                </Box>
                <Box className="ScheduleBox2">
                    <img src="https://res.cloudinary.com/dxluokypg/image/upload/v1683269548/Calendly_s_scoppttt_x6jjdj.webp" alt='calendly'/>
                </Box>
            </Box>
        </div>
    )
}

export default ContactUsSchedule
