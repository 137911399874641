import React, { useState } from 'react'
import './scss/AboutUsPageNewletter.css'
import { Button, Typography, TextField } from '@mui/material'
import Box from '@mui/material/Box'
// import InputUnstyled from '@mui/base/InputUnstyled'
import { styled } from '@mui/system'
// import Mailchimp from 'mailchimp-api-v3';
import jsonp from 'jsonp'
import { useEffect } from 'react'

const blue = {
    100: '#DAECFF',
    200: '#b6daff',
    400: '#3399FF',
    500: '#007FFF',
    600: '#0072E5',
}

const grey = {
    50: '#f6f8fa',
    100: '#eaeef2',
    200: '#d0d7de',
    300: '#afb8c1',
    400: '#8c959f',
}
const StyledInputElement = styled('input')(
    ({ theme }) => `
    width: 200%;
    font-size: 0.875rem;
    font-weight: 400;
    line-height: 1.5;
    padding: 12px;

   `
)

const AboutUsPageNewletter = () => {
    const [email, setEmail] = useState('')
    const [isSuccess, setIsSuccess] = useState(false)

    const handleSubmit = (e) => {
        e.preventDefault()
        if(email){
            const url =
            'https://kjssteel.us21.list-manage.com/subscribe/post-json?u=da43e2fce51437c45437134b4&amp;id=16e4e3887e&amp;f_id=00e752e1f0'
        jsonp(`${url}&EMAIL=${email}`, { param: 'c' }, (_, { msg }) => {
            // alert(msg)
            setIsSuccess(true)
            setTimeout(() => {
                setIsSuccess(false)
            }, 5000)
        })
        setEmail('')
        }
        else{
            alert('Please enter email.')
        }

    }

    return (
        <div className="AboutUsPageNewletterMain">
            <div className="AboutUsPageNewletter">
                <div className="AboutUsPageNewletterBox1">
                    <Box sx={{ width: '86%' }}>
                        <Typography variant='h5'
                            sx={{
                                color: '#585858',
                                marginBottom: '2%',
                                fontSize: '30px',
                                fontWeight: '500',
                            }}
                        >
                            Subscribe Our Newsletter
                        </Typography>
                        <Typography variant='h6'
                            sx={{
                                color: '#585858',
                                lineHeight: '23px',
                                fontSize: '20px',
                                fontWeight: '400',
                            }}
                        >
                            Stay in touch with us to get latest news.
                        </Typography>
                    </Box>
                </div>
                <div className="AboutUsPageNewletterBox2">
                    <form
                        style={{
                            width: '86%',
                            display: 'flex',
                            alignItems: 'center',
                        }}
                        onSubmit={handleSubmit}
                    >
                        <input
                            value={email}
                            onChange={(e) => setEmail(e.target.value)}
                            type="email"
                            className="AboutUSSubscribe"
                            placeholder="Your mail address here"
                            sx={{
                                color: '#656363',
                                lineHeight: '23px',
                                fontSize: '16px',
                                fontWeight: '400',
                            }}
                        ></input>
                        {/* <TextField value={email} onChange={e => setEmail(e.target.value)} type='email' className='AboutUSSubscribe' placeholder="Your mail address here" /> */}
                        <Button
                            type="submit"
                            className="AboutUsPageNewletterBTN"
                            sx={{
                                color: '#ffffff',
                                width: '30%',
                                backgroundColor: '#16469D',
                                fontSize: '20px',
                                height: '50px',
                                textAlign: 'center',
                                fontWeight: '600',
                                textTransform: 'capitalize',
                                borderRadius: '0px 4px 4px 0px',
                            }}
                        >
                            Subscribe
                        </Button>
                    </form>
                    {isSuccess ? (
                        <p style={{ color: 'green' }}>
                            Thank you for subscribing!
                        </p>
                    ) : null}
                </div>
            </div>
        </div>
    )
}

export default AboutUsPageNewletter
