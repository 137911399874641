import React from 'react'
import "./scss/UserLogin.css"
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import Card from '@mui/material/Button';
import { Typography } from '@mui/material';

// import { myImgUrl } from "./imageData.js";
import { Link } from 'react-router-dom'
import { Image } from 'cloudinary-react'
import Slider from 'react-slick';


const data = [
    {
        id: 1,
        linkImg: 'https://res.cloudinary.com/dxluokypg/image/upload/v1681289702/Dastur_fnqtea.svg',
    },
    {
        id: 2,
        linkImg: 'https://res.cloudinary.com/dxluokypg/image/upload/v1681289702/Shanghai_Baosteel_Group_Corporation_logo_1_tlbbwg.svg',
    }
]

const UserLogin = () => {
    var settings = {
        dots: true,
        infinite: true,
        slidesToShow: 3,
        slidesToScroll: 1,
        autoplay: true,
        autoplaySpeed: 2000,

        responsive: [
            {
                breakpoint: 1024,
                settings: {
                    slidesToShow: 3,
                    slidesToScroll: 3,
                    infinite: true,
                    dots: true
                }
            },
            {
                breakpoint: 600,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 2,
                    initialSlide: 2
                }
            },
            {
                breakpoint: 480,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1
                }
            }
        ]
    };
    return (
        <div className='UloginMain'>
            <div className='UloginStart'>
                <Box className='UloginBox'>
                    <form className='UloginForm'>
                        <h3>Welcome back! 👋</h3>
                        <div style={{display:'flex',flexDirection:'column',gap:'25px'}}>
                        <input type='text' placeholder='Email' />
                        <input type='password' placeholder='Your Password' />
                        </div>
                        <a>Forgot Password?</a>
                        <Button variant="contained">Continue</Button>
                        <h5>Don't have an account?<Link to='/become-a-buyer' ><span>Sign up</span></Link></h5>

                    </form>
                </Box>
                <Box className='UloginBox2'>
                    <Box className='BoxStart2' >
                        {/* <Slider {...settings}>
                            {data.map((item) =>
                                <div className={Lstyle.MediaBox2} >
                                    <img src={item.linkImg} alt='' />
                                </div>
                            )}
                        </Slider> */}
                        <img alt='computer' src='https://res.cloudinary.com/dtffniutw/image/upload/v1689518380/Mockup_1_1_vilx0a.svg'></img>
                    </Box>
                    <div className='BoxInfo' >
                    <Typography variant='h4'style={{marginBottom:"1.5%"}}>Join the Steel Revolution!</Typography>
                    <Typography variant='h6'>Shop from more than 2000 trusted suppliers <br /> for over 3000+ products</Typography>
                    </div>
                </Box>


            </div>
        </div >
    )
}
export default UserLogin


