import ProjectDescriptionImageInfo from 'components/ProjectDescriptionImageInfo/ProjectDescriptionImageInfo'
import ProjectDescriptionPageBG from 'components/ProjectDescriptionPageBG/ProjectDescriptionPageBG'
import ProjectDescriptionPageCards from 'components/ProjectDescriptionPageCards/ProjectDescriptionPageCards'
import ProjectDescriptionPageMain from 'components/ProjectDescriptionPageMain/ProjectDescriptionPageMain'
import NavBar from 'components/NavBar/NavBar'
import Footer from 'components/Footer/Footer'
import React, { useEffect, useState } from 'react'
import { useLocation, useParams } from 'react-router-dom'
import { Image } from 'cloudinary-react'
import axios from 'axios'

const ProjectDescriptionPage = (props) => {

  const { projectName, projectId } = useParams();

  // console.log(card)
  // const location = useLocation()
  // console.log(location.state)
  const [card, setCard] = useState({});
  const getProjectData = async () => {
    try {
      const res = await axios.get(
        `${process.env.REACT_APP_STEELADMIN}/project/getProjectById/${projectId}`
      )
      setCard(res.data)
    } catch (error) {
      // console.log(error)
    }
  }

  useEffect(() => {
    getProjectData();
  }, [projectId])
  return (
    <div>
      <NavBar />
      {card &&
      <>
     
      <ProjectDescriptionPageBG data={card} />
      <ProjectDescriptionImageInfo data={card} />
      <ProjectDescriptionPageMain data={card} />
      <ProjectDescriptionPageCards data={card} />
      </>
      }
      <Footer />
      <div style={{ position: 'fixed', bottom: '2rem', right: '2rem', zIndex: '999', backgroundImage: 'none', background: 'none', boxSizing: 'none', borderStyle: 'none', cursor: 'pointer' }}>
        <a href="https://api.whatsapp.com/send?phone=9321714104&text=I%20am%20interested!" target="_blank">
          <Image style={{ height: '7vh', width: '7vh', cursor: 'pointer', }} publicId="https://res.cloudinary.com/dxluokypg/image/upload/v1680763106/whatsapp_gqgneb.png" cloudName="dxluokypg" />
        </a>
      </div>
    </div>
  )
}

export default ProjectDescriptionPage
