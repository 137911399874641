import React, { useEffect, useState } from 'react'
import '../OurProject/scss/OurProject.css'
import Typography from '@mui/material/Typography'
import { Button, Box } from '@mui/material'
import { Link, useNavigate } from 'react-router-dom'
import axios from 'axios'

const OurProject = () => {
    const navigate=useNavigate()
    const [data, setData] = useState([])

    const getProjectData = async () => {
        try {
            const res = await axios.get(
                `${process.env.REACT_APP_STEELADMIN}/project/getAllProjectDetails`
            )
            setData(res.data)
           
        } catch (error) {
            // console.log(error)
        }
    }

    useEffect(() => {
        getProjectData()
    }, [])

    return (
        <div className="projectComp">
            {/* <Typography className="project_headM" >Our Projects</Typography> */}
            <div className="projectCompContent">
                <div className="projectCompText">
                    <Typography className="project_head"
                        sx={{

                            fontSize: { xs: '10px', sm: '20px', md: '20px', lg: '30px' },
                            fontWeight: { xs: '400', sm: '400', md: '500', lg: '700' },
                            lineHeight: { xs: '20px', sm: '23px', md: '23px', lg: '23px' },
                        }}
                    >
                        Our Projects
                    </Typography>
                    <Typography className="project_para"
                        sx={{
                            textAlign:'justify',
                            fontSize: { sm: '11px', md: '13px', lg: '15px' },
                            fontWeight: { sm: '400', md: '400', lg: '400' },
                            lineHeight: { sm: '20px', md: '21px', lg: '23px' },
                        }}
                    >
                        We take pride in delivering exceptional quality and innovative solutions to our clients across a wide range of industries. From structural steel for commercial buildings to custom-designed steel components for industrial machinery, we have the experience and expertise to handle even the most complex projects. Browse through our portfolio to see examples of our work and discover how we can help bring your next steel project to life.
                    </Typography>
                    <Link to="/projects" className="project_btnMain" style={{ textDecoration: 'none' }}>
                        <Button
                            variant="contained"
                            className="project_btn"
                            sx={{

                                fontSize: { sm: '', md: '', lg: '15px' },
                                fontWeight: { sm: '', md: '', lg: '400' },
                                lineHeight: { sm: '', md: '', lg: '23px' },

                            }}
                        >

                            Know More
                        </Button>
                    </Link>
                </div>
                <div className='ProjectImgStart'>
                    <div className="projectImgfirst">
                       {data && <Link 
                        // to={`/projects/${data[0]?.projectId}`} state={data[0]}
                        onClick={(e)=>{e.preventDefault();navigate(`/projects/${data[0]?.projHeading.replace(/\s+/g, '-').toLowerCase()}`,{state:{card:data[0]}})}}
                         className="projectImg1" style={{ textDecoration: 'none', cursor: 'pointer',
                          backgroundImage:`none,url(${process.env.REACT_APP_STEELADMIN}/project/file/${data[0]?.projCover.newCoverImageName})`
                         }}>
                            
                           


                            <Typography variant='h4'>
                                {data[0]?.projHeading ? data[0]?.projHeading : 'Powerful Strategy'}
                            </Typography>
                        </Link>
                         }
                    </div>
                    <div className="projectImgsecond">
                        {data && data?.slice(1, 3).map((data) => {
                            console.log(data)
                            return(
                                <Link 
                                // to={`/projects/${data?.projectId}`} state={data[1]}
                                onClick={(e)=>{e.preventDefault();navigate(`/projects/${data?.projHeading.replace(/\s+/g, '-').toLowerCase()}`,{state:{card:data}})}}
                                 className="projectImg2" style={{ textDecoration: 'none',transition:"all 0.2s ease-in-out", cursor: 'pointer',
                                 backgroundImage:`none,url(${process.env.REACT_APP_STEELADMIN}/project/file/${data?.projCover.newCoverImageName})`
                                 }}>
                                    <Typography variant='h4' > 
                                        {data?.projHeading ? data?.projHeading : 'Powerful Strategy'}
                                    </Typography>
    
                                </Link>
                            )
                         
                        })}

                        {/* <Link to={`/projects/${data[2]?.projectId}`} state={data[2]} className="projectImg3" style={{ textDecoration: 'none', cursor: 'pointer' }}>
                                <Typography variant='h4' >
                                    {data[2]?.projHeading ? data[2].projHeading : 'Powerful Strategy'}
                                </Typography>
                        </Link> */}
                    </div>
                </div>
            </div>
        </div>
    )
}

export default OurProject
