import React from 'react'
import './scss/PartnerLogo.css'
import { Image } from 'cloudinary-react'
import { Box } from '@mui/material'
import Slider from 'react-slick';


const PartnerLogo = () => {
  var settings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 4,
    slidesToScroll: 1,
    initialSlide: 0,
    arrows: false,
    margin: 4,
    autoplay: true,
    autoplaySpeed: 1000,
    pauseOnHover: true,

    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3,
          infinite: true,
          dots: true
        }
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          initialSlide: 2
        }
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1
        }
      }
    ]
  };

  return (
    <div className='partner-logo'>
      <Box className='logoStart'>
        <Image className='partner-logo-image' publicId="https://res.cloudinary.com/dxluokypg/image/upload/v1681289565/posco-chemtech-1-removebg-preview_1_zxnuhh.svg" cloudName="dxluokypg" />
        <Image className='partner-logo-image' publicId="https://res.cloudinary.com/dxluokypg/image/upload/v1681289702/Shanghai_Baosteel_Group_Corporation_logo_1_tlbbwg.svg" cloudName="dxluokypg" />
        <Image className='partner-logo-image' publicId="https://res.cloudinary.com/dxluokypg/image/upload/v1681289701/5cce57041ec30-removebg-preview_1_k51dwl.svg" cloudName="dxluokypg" />
        <Image className='partner-logo-image' publicId="https://res.cloudinary.com/dxluokypg/image/upload/v1681289702/Dastur_fnqtea.svg" cloudName="dxluokypg" />
        <Image className='partner-logo-image' publicId="https://res.cloudinary.com/dxluokypg/image/upload/v1681289702/rsa_680063b8_dj9gua.svg" cloudName="dxluokypg" />
        <Image className='partner-logo-image' publicId="https://res.cloudinary.com/dxluokypg/image/upload/v1681289702/080622090347-logo_1_cqqqf0.svg" cloudName="dxluokypg" />
        <Image className='partner-logo-image2' publicId="https://res.cloudinary.com/dxluokypg/image/upload/v1681290357/595eec789c671_vkieig.svg" cloudName="dxluokypg" />
      </Box>
      <Box className='logoStartHide'>
        <Slider {...settings}>
          <Image className='partner-logo-image0' publicId="https://res.cloudinary.com/dxluokypg/image/upload/v1681289565/posco-chemtech-1-removebg-preview_1_zxnuhh.svg" cloudName="dxluokypg" />
          <Image className='partner-logo-image0' publicId="https://res.cloudinary.com/dxluokypg/image/upload/v1681289702/Shanghai_Baosteel_Group_Corporation_logo_1_tlbbwg.svg" cloudName="dxluokypg" />
          <Image className='partner-logo-image0' publicId="https://res.cloudinary.com/dxluokypg/image/upload/v1681289701/5cce57041ec30-removebg-preview_1_k51dwl.svg" cloudName="dxluokypg" />
          <Image className='partner-logo-image0' publicId="https://res.cloudinary.com/dxluokypg/image/upload/v1681289702/Dastur_fnqtea.svg" cloudName="dxluokypg" />
          <Image className='partner-logo-image0' publicId="https://res.cloudinary.com/dxluokypg/image/upload/v1681289702/rsa_680063b8_dj9gua.svg" cloudName="dxluokypg" />
          <Image className='partner-logo-image0' publicId="https://res.cloudinary.com/dxluokypg/image/upload/v1681289702/080622090347-logo_1_cqqqf0.svg" cloudName="dxluokypg" />
          <Image className='partner-logo-image0' publicId="https://res.cloudinary.com/dxluokypg/image/upload/v1681290357/595eec789c671_vkieig.svg" cloudName="dxluokypg" />
        </Slider>
      </Box>
    </div>
  )
}

export default PartnerLogo