import { createTheme } from '@mui/material/styles'


const theme = createTheme({
  typography: {
      fontFamily: ['Inter','sans-serif'].join(','),
          
  },
  overrides: {
      MuiCssBaseline: {
        root: {
              '@font-face': [theme],
              fontFamily: [theme]
          },
      },
  },
})





export default theme






