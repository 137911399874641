import React from 'react'
import "./scss/ProjectDescriptionPageBG.css"
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';

const ProjectDescriptionPageBG = (props) => {

  const { data } = props


  return (
        <div className='ProjectDescriptionPageMain1'>
            <Box className='ProjectDescriptionPageBG1'>
                <Typography variant='h3' sx={{ color: '#fff', fontSize: '45px', fontWeight: '700',marginBottom:'3%' }}>
                  {data.projHeading}</Typography>
             
                <div style={{ color: '#fff', fontSize: '19px',maxHeight:"80px",overflow:"hidden" }}
                dangerouslySetInnerHTML={{ __html: data.projShortDesc }}>
                  {/* {data.projShortDesc} */}
                  </div>
            </Box>
        </div>
  )
}

export default ProjectDescriptionPageBG
