import React from 'react'
import { Box, Container } from '@mui/system'
import './scss/AboutUs.css'
import { Typography } from '@mui/material'
import { Image } from 'cloudinary-react'
import { Link } from 'react-router-dom'

const AboutUs = () => {
    return (
        <>
        <div className="backimg" >
            <Box className="aboutUs">
                <Box className="aboutBox1">
                    <Box sx={{ width: '100%' }}>
                        <center>
                            <Image className="imgContainer" publicId="https://res.cloudinary.com/dxluokypg/image/upload/v1677834287/AboutUsBanner_kcim97.svg" cloudName="dxluokypg"/>
                        </center>
                    </Box>
                </Box>
                <Box className="aboutBox2">
                    <Typography className="aboutUs_head">About Us</Typography>
                    <Typography className="aboutUs_para" >
                    <p style={{textAlign:'justify'}}>Welcome to our steel start-up company! We are a team of passionate individuals who are dedicated to delivering high-quality steel products to our clients. Our mission is to provide our customers with the best steel products on the market while also maintaining the highest level of customer service.</p><br></br>
                    <p style={{textAlign:'justify'}}>At our company, we understand that every project is unique, which is why we offer a wide range of steel products to suit any application. From construction and infrastructure to transportation and energy, we have the expertise to deliver the right steel products for your project 
                    <Link to='/about-us' style={{textDecoration: 'none'}}> <span style={{color: '#16469d'}}>Read more</span></Link></p>
                   </Typography>
                </Box>
            </Box>
        </div>
        </>
    )
}

export default AboutUs
