import React from 'react'
import "./scss/FeedbackPageBG.css"
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import PersonOutlineOutlinedIcon from '@mui/icons-material/PersonOutlineOutlined';
import ContactMailOutlinedIcon from '@mui/icons-material/ContactMailOutlined';
import EmailOutlinedIcon from '@mui/icons-material/EmailOutlined';
import { useDispatch, useSelector } from 'react-redux';
import { useState } from 'react';
import { useEffect } from 'react';
import { setFeedbackName, setFeedbackDesignation, setFeedbackSuggestions, setFeedbackEmail } from './store/dataSlice';
import { feedback } from './store/Feedback.actions';
import { useNavigate } from 'react-router-dom';
import Button from '@mui/material/Button';


const FeedbackPageBG = () => {

  const dispatch = useDispatch()

  const navigate = useNavigate()

  const feedbackState = useSelector((state) => state.partner)

  const [data, setData] = useState({
    feedbackName: '',
    feedbackDesignation: '',
    feedbackEmail: '',
    feedbackSuggestions: '',
  })

  const [errors, setErrors] = useState({})
  const [isSubmit, setIsSubmit] = useState(false)

  const handleChange = (event, property) => {
    setData({ ...data, [property]: event.target.value })
  }

  const validate = (values) => {
    const errors = {}
    const regexemail = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/
    if (!values.feedbackName) {
      errors.feedbackName = "Name is required!"
    }
    if (!values.feedbackDesignation) {
      errors.feedbackDesignation = "Designation is required!"
    }
    if (!values.feedbackEmail) {
      errors.feedbackEmail = 'Email is required!'
    } else if (!regexemail.test(values.feedbackEmail)) {
      errors.feedbackEmail = 'Please enter a valid email.'
    }
    if (!values.feedbackSuggestions) {
      errors.feedbackSuggestions = "Suggestions is required!"
    }
    return errors
  }

  const handleSubmit = (event) => {
    event.preventDefault()
    // console.log(data)
    setErrors(validate(data))
    setIsSubmit(true)
  }

  useEffect(() => {
    if (Object.keys(errors).length === 0 && isSubmit) {
      dispatch(setFeedbackName(data.feedbackName))
      dispatch(setFeedbackDesignation(data.feedbackDesignation))
      dispatch(setFeedbackEmail(data.feedbackEmail))
      dispatch(setFeedbackSuggestions(data.feedbackSuggestions))
      dispatch(feedback(data))
      setData({
        feedbackName: '',
        feedbackDesignation: '',
        feedbackEmail: '',
        feedbackSuggestions: '',
      })
      navigate('/feedback')  
      alert('Successfully submitted');
    }
  }, [errors, isSubmit])



  return (
    <div className='FeedbackPageMain'>
      <div className='FeedbackPageBackground'>
        <Box className='FeedbackPageBG'>
          <Typography variant='h3' sx={{ color: '#fff', fontSize: '45px', fontWeight: '700', marginBottom: '1%' }}>Send us your feedback</Typography>
          <Typography variant='h6' sx={{ color: '#fff', fontSize: '22px', fontWeight: '300' }}>share your experience and help us improve it</Typography>
        </Box>
      </div>
      <div className='FeedbackPageInfo'>
        <Box className='FeedbackPagePics'>
          <img src='https://res.cloudinary.com/dxluokypg/image/upload/v1679152620/FeedbackpagePics_mauxii.svg' alt='FeedbackImg'></img>
        </Box>

        <Box className='FeedbackPageForm'>
          <form action="#" method="post" className='Feedform' onSubmit={handleSubmit}>
            <div className="Feedformcontainer">
              <div className="input-field">
                <label style={{
                  fontSize: errors.feedbackName ? '12px' : '15px',
                  color: errors.feedbackName ? 'red' : '#585858',
                }}>{errors.feedbackName ? errors.feedbackName : 'Full name'}</label>
                <input type="text"  value={data.feedbackName} className='MYinput1' onChange={(e) => handleChange(e, 'feedbackName')} />
                <Box className='formIcon' sx={{ fontSize: '30px', }}>
                  <PersonOutlineOutlinedIcon fontSize='50px' />
                </Box>
              </div>

              <div className='input-field'>
                <label style={{
                  fontSize: errors.feedbackDesignation ? '12px' : '15px',
                  color: errors.feedbackDesignation ? 'red' : '#585858',
                }}>{errors.feedbackDesignation ? errors.feedbackDesignation : 'Occupation'}</label>
                <input type="text" value={data.feedbackDesignation} className='MYinput1' onChange={(e) => handleChange(e, 'feedbackDesignation')} />
                <Box className='formIcon2' sx={{ fontSize: '25px' }}>
                  <ContactMailOutlinedIcon fontSize='50px' />
                </Box>
              </div>
              <div className="input-field">
                <label style={{
                  fontSize: errors.feedbackEmail ? '12px' : '15px',
                  color: errors.feedbackEmail ? 'red' : '#585858',
                }}>{errors.feedbackEmail ? errors.feedbackEmail : 'Email'}</label>
                <input type="text"  value={data.feedbackEmail} className='MYinput1' onChange={(e) => handleChange(e, 'feedbackEmail')} />
                <Box className='formIcon' sx={{ fontSize: '31px' }}>
                  <EmailOutlinedIcon fontSize='50x' />
                </Box>
              </div>

              <div className='input-field' >
                <label style={{
                  fontSize: errors.feedbackSuggestions ? '12px' : '15px',
                  color: errors.feedbackSuggestions ? 'red' : '#585858',
                }}>{errors.feedbackSuggestions ? errors.feedbackSuggestions : 'Brief Suggestions'}</label>
                <textarea className='TextInput' value={data.feedbackSuggestions} rows="10" column="10" onChange={(e) => handleChange(e, 'feedbackSuggestions')} />
              </div>
              <div className="input-field">
              <Button variant="contained" type='submit'  style={{fontWeight:'400',fontSize:'15px',color:'#fff',boxShadow:'none'}}  className='submit' value="Send Feedback">Send Feedback</Button>
              </div>
            </div>
          </form>
        </Box>
      </div>
    </div>


  )

}

export default FeedbackPageBG
