import axios from 'axios'

import{
    setLoading,
    setError,
    setSuccess,
} from './dataSlice'

export const feedback = (userData) => async (dispatch) => {
    dispatch(setLoading(true))
    try{

        const token = JSON.parse(localStorage.data)
        const response = await axios.post(`${process.env.REACT_APP_STEELADMIN}/feedback/addFeedBack`, userData, {
            headers: {
                'Authorization': `Bearer ${token.accessToken}` 
            }
        })
        dispatch(setSuccess(true))
        alert(response.data)
    } catch (error) {
        dispatch(setError(error.message))
        // alert('Please login')
    } finally {
        dispatch(setLoading(false))
    }
}