
import React from 'react'
import "./scss/NewLogin.css"
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import { Typography } from '@mui/material';
import { Link } from 'react-router-dom'
import Slider from 'react-slick';
import FormControlLabel from '@mui/material/FormControlLabel'
import Checkbox from '@mui/material/Checkbox'
import { useNavigate } from 'react-router-dom'
import { useState } from 'react'
import { loginUser } from 'services/UserService'
import { doLogin } from 'auth'
import axios from 'axios';
import { Visibility, VisibilityOff } from '@mui/icons-material';
import IconButton from '@mui/material/IconButton';
import InputAdornment from '@mui/material/InputAdornment';
import ErrorIcon from '@mui/icons-material/Error';
import { Helmet } from "react-helmet";
import useBackdrop from 'hooks/useBackdrop';
const data = [
    {
        id: 1,
        linkImg: 'https://res.cloudinary.com/dtffniutw/image/upload/v1689518380/Mockup_1_1_vilx0a.svg',
    },
    {
        id: 2,
        linkImg: 'https://res.cloudinary.com/dtffniutw/image/upload/v1689518297/Mockup_PSD_FREE___iPad_Pro___Pencil_2018-removebg-preview_1_bnh73w.svg',
    },
    {
        id: 3,
        linkImg: 'https://res.cloudinary.com/dtffniutw/image/upload/v1689518380/Mockup_1_1_vilx0a.svg',
    },
    {
        id: 4,
        linkImg: 'https://res.cloudinary.com/dtffniutw/image/upload/v1689518297/Mockup_PSD_FREE___iPad_Pro___Pencil_2018-removebg-preview_1_bnh73w.svg',
    }

]

const NewLogin = () => {
    const { openBackdrop, closeBackdrop, BackdropComponent } = useBackdrop();
    const navigate = useNavigate()
    const [errorShow, setErrorShow] = useState('')
    // new logic
    const [loginDetail, setLoginDetail] = useState({

        usernameOrEmail: '',
        password: '',
    })

    const [error, setError] = useState({
        email: false,
        password: false,
        loginError: false
    })



    const [showPassword, setShowPassword] = useState(false);

    const handleChange = (event, field) => {
        let actualValue = event.target.value
        setLoginDetail({
            ...loginDetail,
            [field]: actualValue,
        })
    }


    const handleSubmit = (event) => {
        event.preventDefault()
        // console.log(loginDetail)
        openBackdrop()
        setError({
            email: false,
            password: false,
            loginError: false
        })

        if (loginDetail.password !== '' && loginDetail.usernameOrEmail !== '') {
            // console.log("went in  withoutl errro")
            try {
                const res = axios.post(`${process.env.REACT_APP_STEELADMIN}/api/auth/login`, loginDetail).then((response) => {
                    // console.log(response)
                    if (response.status === 200) {
                        // closeBackdrop()
                        doLogin(response.data, () => {
                            // console.log('login detail is saved to localstorage')
                        })
                        setTimeout(() => {
                            navigate('/',)
                        }, 500)
                        // setError({
                        //         email:true,
                        //         password:true
                        //     }
                        // )
                    }
                    else {
                        // console.log("not loged in")
                        closeBackdrop()
                        setError((prev) => {
                            return {
                                ...prev,
                                loginError: true,
                            }
                        })
                    }



                }).catch((err) => {
                    closeBackdrop()
                    setError((prev) => {
                        return {
                            ...prev,
                            loginError: true,
                        }
                    })

                    setTimeout(() => {

                    }, 500)
                }



                )

            } catch (error) {
                // console.log(error)
                alert('Something went wrong')
                closeBackdrop()
            }
        }
        else {
            // console.log("went in errro")

            closeBackdrop()
            if (loginDetail.usernameOrEmail === '') {
                setError((prev) => {
                    return {
                        ...prev,
                        email: true
                    }
                })
            }
            if (loginDetail.password === '') {
                setError((prev) => {
                    return {
                        ...prev,
                        password: true
                    }
                })
            }
            else {

            }

        }



    }

    // const testBackdrop=()=>{

    // }


    var settings = {
        fade: true,
        dots: true,
        arrows: false,
        infinite: true,
        slidesToShow: 1,
        slidesToScroll: 1,
        autoplay: true,
        autoplaySpeed: 2000,
        cssEase: 'cubic-bezier(0.7, 0, 0.3, 1)',


    };
    return (
        <>
            <BackdropComponent />
            <Helmet>
                <html lang="en" />
                <title>Login KJS Steel</title>
                <meta name="description" content="KJS Steel is one of the leading steel plant engineering consultants in India offering EPC services as well as turnkey solutions." />
                <meta name="keywords" content="Login KJS Steel" />

            </Helmet>
            <div className='USerLOginMAin'>
                <div className='USerLOginStart'>
                    <Box className='USerLOginBox'>
                        <form className='USerLOginFOrm' onSubmit={handleSubmit}>

                            {error.loginError &&
                                <p style={{
                                    width: '85%',
                                    border: '2px solid 5F5E5E',
                                    padding: '3%',
                                    marginBottom: '2%',
                                    justifyContent: 'left',
                                    display: 'flex',
                                    backgroundColor: '#ffdfe4',
                                }}> <ErrorIcon sx={{ color: '#ff5d5d', marginRight: '8px' }} /> User Doesn't Exist</p>}


                            <Typography variant='h3'>Welcome Back! 👋</Typography>
                            <label>Email</label>
                            <div className='inputRows'>
                                <input style={{ display: 'block' }} type='email' placeholder='Email'
                                    onChange={(e) => handleChange(e, 'usernameOrEmail')}
                                />
                                {error.email && <span style={{ color: "red", fontSize: "12px", paddingBottom: '2px' }}>Email is Required</span>}
                            </div>

                            <label>Password</label>
                            <div className='inputRows'>
                                <div style={{ position: "relative",width:"85%" }}>
                                    <input
                                        style={{ display: 'block',width:"100%" }}
                                        type={showPassword ? 'text' : 'password'}
                                        placeholder='Your Password'
                                        id="outlined-adornment-password"
                                        value={loginDetail.password}
                                        onChange={(e) => handleChange(e, 'password')}

                                    />
                                    <span style={{position:"absolute",height:"100%",top:"0%",right:"2%",display:"flex",alignItems:"center"}}>
                                        <IconButton style={{backgroundColor:'transparent',color:'#585858'}}
                                            aria-label="toggle password visibility"
                                            onClick={() => setShowPassword(!showPassword)}
                                            
                                        >
                                            {showPassword ? <VisibilityOff /> : <Visibility />}
                                        </IconButton>
                                    </span>
                                </div>

                                {error.password && <span style={{ color: "red", fontSize: "12px", }}>Password is Required</span>}
                            </div>

                            <Link to='/forgotpassword'>
                                <a>Forgot Password?</a>
                            </Link>
                            <Button variant="contained" type="submit">Log In</Button>
                            <Typography variant='h5'>Don't have an account? <Link to='/become-a-buyer' ><span> Sign up </span></Link></Typography>



                        </form>

                    </Box>
                    <Box className='USerLOginBox2'>
                        <Box className='USerBoxSTart2'>
                            <Slider {...settings}>
                                {data.map((item) =>
                                    <div className='USerMEdiaBox2' >
                                        <img src={item.linkImg} alt='Computers' />
                                    </div>
                                )}
                            </Slider>
                        </Box>
                        <div className='USerBOxInfo' >
                            <Typography variant='h4' style={{ marginBottom: "1.5%" }}>Join the Steel Revolution!</Typography>
                            <Typography variant='h6'>Shop from more than 2000 trusted suppliers <br />for over 3000+ products</Typography>
                        </div>
                    </Box>


                </div>

            </div>



        </>
    )
}
export default NewLogin;