import React, { useEffect, useState } from 'react'
import "./scss/ProjectDescriptionPageCards.css"
import Box from '@mui/material/Box';
import { Typography } from '@mui/material';
import { useNavigate, Link, useParams } from 'react-router-dom';
import axios from 'axios';

const ProjectDescriptionPageCards = (props) => {

  const { data } = props
  const {projectId}=useParams();
console.log(projectId)
  const navigate=useNavigate();
  const [relProj, setRealProj] = useState([])

  const getRelativeData = async () => {
    try {
      const res = await axios.get(
        `${process.env.REACT_APP_STEELADMIN}/project/getAllRelatedProjects/${data.projCategory}`
      )
      setRealProj(res.data)
      // console.log(res.data)
    }
    catch (error) {
      // console.log(error)
    }
  }
  useEffect(() => {
    getRelativeData()
  }, [data])



  return (
    <div className='ProjectDescPageCardsMain'>
      {
        relProj.length>1&&
        <>
         <Box className='ProjectDescPageCardsHeadiing'>
        <Typography variant='h4'>Related Projects</Typography>
      </Box>
         <div className='OurProjectCardStart'>
          {console.log(relProj)}
        {relProj.filter((card)=>{
         
          return card.projectId!==parseInt(projectId)
        
        }).map((card) => (

          <Link
          to={{pathname:`/projects/${card?.projHeading.replace(/\s+/g, '-').toLowerCase()}/${card?.projectId}`}}
            // onClick={(e) => { e.preventDefault(); navigate(`/projects/${card?.projHeading.replace(/\s+/g, '-').toLowerCase()}`, { state: { card } }) }}
            className='projectCard' >
            <div className='cardImg'>
              <img src={`${process.env.REACT_APP_STEELADMIN}/project/file/${card.projCover.newCoverImageName}`}
                alt={`${card.projHeading} picture`} />
            </div>




            <div className='cardContent'>
              <h2>
                {card.projHeading}
              </h2>
              <p>
                {card.projCategory}
              </p>
            </div>

          </Link>

        ))}
      </div>
        </>
      }
     

      {/* <div className='ProjectDescPageCardsImgs'>
        {relProj.map((card) => (
          <Link to={`/relatedprojects/${card?.projectId}`}
            className="ProjectPageimg" state={card} style={{ textDecoration: 'none' }}>
            <img
              style={{
                maxWidth: '400px',
                maxHeight: '400px'
              }} src={`${process.env.REACT_APP_STEELADMIN}/project/file/${card.projCover.newCoverImageName}`} alt="React Image" />
            <Typography variant='h5'>{card.projHeading}</Typography>
            <Typography variant='h6'>{card.projCategory}</Typography>

          </Link>


        ))}
      </div> */}

    </div>
  )
}

export default ProjectDescriptionPageCards
