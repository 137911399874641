import React from 'react'
import { Typography } from '@mui/material'
import Container from '@mui/material/Container'
import Box from '@mui/material/Box'
import TextField from '@mui/material/TextField'
import FormControlLabel from '@mui/material/FormControlLabel'
import Checkbox from '@mui/material/Checkbox'
import Button from '@mui/material/Button'
import './scss/NewPassword.css'
import { Link, useLocation, useNavigate } from 'react-router-dom'
import { useState, useRef, useEffect, useContext } from 'react'
import axios from 'axios'
import { Image } from 'cloudinary-react'
import RegSuccess from '../../assets/images/RegisterSuccess.gif'
import Slider from 'react-slick';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { Height } from '@mui/icons-material'
import Modal from '@mui/material/Modal';


const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 400,
    bgcolor: 'background.paper',
    border: 'none',
    boxShadow: 24,
    p: 4,
};

const newOTPSlider = [
    {
        id: 1,
        linkImg: 'https://res.cloudinary.com/dtffniutw/image/upload/v1689518380/Mockup_1_1_vilx0a.svg',
    },
    {
        id: 2,
        linkImg: 'https://res.cloudinary.com/dtffniutw/image/upload/v1689518297/Mockup_PSD_FREE___iPad_Pro___Pencil_2018-removebg-preview_1_bnh73w.svg',
    },
    {
        id: 3,
        linkImg: 'https://res.cloudinary.com/dtffniutw/image/upload/v1689518380/Mockup_1_1_vilx0a.svg',
    },
    {
        id: 4,
        linkImg: 'https://res.cloudinary.com/dtffniutw/image/upload/v1689518297/Mockup_PSD_FREE___iPad_Pro___Pencil_2018-removebg-preview_1_bnh73w.svg',
    }
]

const NewPassword = () => {

    const [newPassword, setNewPassword] = useState('')
    const [confPassword, setConfPassword] = useState('')
    const [isSubmit, setIsSubmit] = useState(false)
    const [isSuccess, setIsSuccess] = useState(false)
    const [error, setError] = useState(false);
    const [passSuccess, setPassSuccess] = useState(false);



    const navigate = useNavigate()
    const { state } = useLocation()
    const { verifyOtp } = state

    const handlePassword = (e) => {
        setNewPassword(e.target.value)
    }


    useEffect(() => {
        if(passSuccess)
        { const timer = setTimeout(() => {
         
            navigate("/Signin")
          }, 2000); 
      
          
          return () => clearTimeout(timer);

        }
       
      }, [passSuccess]);

    const handleConfPassword = (e) => {
        setConfPassword(e.target.value)
    }

    const validate = (newPassword, confPassword) => {
        // console.log(newPassword, confPassword)
        let errorPass = '';
        const regexpass = /^(?=.*[0-9])(?=.*[!@#$%^&*])[a-zA-Z0-9!@#$%^&*]{6,11}$/
        if (!newPassword) {
            errorPass = 'Please enter password.'
        } else if (!regexpass.test(newPassword)) {
            errorPass = 'Please include: 8+ characters, upper/lowercase, numbers, special character.'
        }
        if (!confPassword) {
            errorPass = 'Please enter confirmation password.';
        }
        if (newPassword !== confPassword) {
            errorPass = 'Passwords do not match.'
        }
        // console.log(errorPass)
        return errorPass
    }

    const handleSubmit = (event) => {
        event.preventDefault()
        setError(validate(newPassword, confPassword))
        setIsSubmit(true)
        // setError(false);
    }

    useEffect(() => {
        const submitPassword = async () => {

            try {
                const response = await axios.post(`${process.env.REACT_APP_STEELADMIN}/api/auth/resetPassword/${verifyOtp}`, { newPassword }).then((res) => {

                    setIsSuccess(true);
                    setError('');
                    setPassSuccess(true);
                    // alert("Pass Change Su")
                 
                }).catch((err) => {
                    // console.log("PJ", err);
                    // setError("something went wrong with the api")
                    // console.log("error occured in the api calling")
                })


            } catch (error) {
                // console.log(error)
            }
        }
        if (error.length === 0 && isSubmit) {
            submitPassword()
        }
    }, [isSubmit])


    var settings = {
        fade: true,
        dots: true,
        arrows: false,
        infinite: true,
        slidesToShow: 1,
        slidesToScroll: 1,
        autoplay: true,
        autoplaySpeed: 2000,
        cssEase: 'cubic-bezier(0.7, 0, 0.3, 1)',
    };

    return (
        <div className="newpassword">
            <div className="newpasswordMain">
                <div className="newPasswordStart">
                    <Box className="newPasswordbOX">
                        <form className="newpassword-form" action="" onSubmit={handleSubmit}>
                            {passSuccess &&
                                <p style={{
                                    width: '100%',
                                    border: '2px solid 5F5E5E',
                                    padding: '2%',
                                    marginTop: '2%',
                                    justifyContent: 'center',
                                    display: 'flex',
                                    borderRadius: '4px',
                                    fontSize: '14px',
                                    color: '#71C837',
                                    backgroundColor:'#E4FFD2',
                                    borderRadius:'8px',
                                }}>  Password Change successfully ✓</p>}
                            <Typography
                                variant="h3"
                            >
                                Reset Password
                            </Typography>


                            <input type='password' placeholder='New Password' name='newPassword' onChange={(e) => handlePassword(e)} />

                            <input type='password' placeholder='Confirm Password' name='confirmPassword' onChange={(e) => handleConfPassword(e)} />


                            {error ? (
                                <Typography variant="caption" sx={{ textAlign: 'left', color: 'red' }} className='errorMessage'>
                                    {error}
                                </Typography>
                            ) : null}
                            <Button type="submit"

                                variant="contained">
                                Reset Password
                            </Button>
                        </form>
                    </Box>
                    <Box className='newOtpBox2'>
                        <Box className='newOtpSTart2'>
                            <Slider {...settings}>
                                {newOTPSlider.map((item) =>
                                    <div className='NewOTPMEdiaBox2' >
                                        <img src={item.linkImg} alt='Computers' />
                                    </div>
                                )}
                            </Slider>
                        </Box>
                        <div className='newOTPInfo' >
                            <Typography variant='h4' style={{ marginBottom: "1.5%" }}>Join the Steel Revolution!</Typography>
                            <Typography variant='h6'>Shop from more than 2000 trusted suppliers <br />for over 3000+ products</Typography>
                        </div>
                    </Box>
                </div>
            </div>


            <div>


            </div>
        </div >
    )
}

export default NewPassword