import React, { useEffect, useState } from 'react'
import { Typography, Box } from '@mui/material'
import './scss/PartnerOpp.css'
import axios from 'axios'
import KeyboardTabIcon from '@mui/icons-material/KeyboardTab';
const opp = [1, 2, 3]

const PartnerOpp = () => {
    const [data, setData] = useState([])
    const [selectedCompanyDetail, setSelectedCompanyDetail] = useState(null);
    const [pdfUrl, setPdfUrl] = useState('');

    const getOppData = async () => {
        try {
            const res = await axios.get(
                `${process.env.REACT_APP_STEELADMIN}/partnerOpp/getAllPartnerOpp`
            )
            setData(res.data)
            // console.log(data)
        } catch (error) {
            console.log(error)
        }
    }

    const handlePdfLink = async (opp) => {
        setSelectedCompanyDetail(opp);
        setPdfUrl('');
        if (opp && opp.pdfId) {
            const pdfId = opp.pdfId;
            const apiUrl = `${process.env.REACT_APP_STEELADMIN}/partnerOpp/file/${opp.newPdfName}`;

            axios.get(apiUrl, {
                responseType: 'blob' // set the response type to blob
            })
                .then(response => {
                    const blob = new Blob([response.data], { type: 'application/pdf' });
                    const url = URL.createObjectURL(blob);
                    const link = document.createElement('a');
                    link.href = url;
                    link.target = '_blank';
                    link.click();
                })
                .catch(error => {
                    console.error('Error downloading PDF file', error);
                });
        }

    }

    useEffect(() => {
        getOppData()
    }, [])
    return (
        <div className="partner-opp123">
            <Typography
                className="partnerHead1"
                sx={{
                    fontWeight: {xl:'600', sm: '600', md: '600', lg: '700' },
                    fontSize: {xl:"20px", sm: '20px', md: '20px', lg: '30px' },
                    lineHeight: { sm: '27px', md: '27px', lg: '27px' },
                    color: '#585858',
                    paddingLeft: '5%',

                }}
            >
                Scope of Opportunities
            </Typography>

            <div className="partner-opp-box123">
                {data.map((opp) => (
                    <Box className='PartnerSquare'
                        sx={{
                            display: 'flex',
                            flexDirection: 'column',
                            m: 1,
                            p: 5,
                            mt: '3%',
                            border : '1px solid #b5a7a7',
                            borderRadius: '4px',
                            transition:'0.6s ease-in-out'
                        }}
                    >
                        <Typography
                            className="partnerName"
                            sx={{
                                fontWeight: { sm: '500', md: '500', lg: '810' },
                                fontSize: {
                                    sm: '16px',
                                    md: '16px',
                                    lg: '20px',
                                },
                                lineHeight: {
                                    sm: '27px',
                                    md: '27px',
                                    lg: '27px',
                                },
                                color: 'rgba(88, 88, 88, 1)',
                            }}
                        >
                            {opp.partOpp.partnerOppName}
                        </Typography>
                        <Typography
                            className="partnerDes"
                            sx={{
                                mt: '10%',
                                textAlign:'justify',
                            }}
                        >
                            {opp.partOpp.partnerOppDescription}
                        </Typography>
                        <Typography variant='h6'
                            sx={{
                                mt: '25%',
                                color: '#16469d',
                            }}
                        >
                            <a
                                onClick={() => handlePdfLink(opp)}
                                className="dlbrochure"
                                style={{
                                    width: '100%',
                                    color: '#16469d',
                                    cursor: 'pointer'
                                }}
                                target="_blank"
                            >
                                Download Brochure
                                {/* <KeyboardTabIcon/> */}
                                <img alt='DownloadIcon' src='https://res.cloudinary.com/dxluokypg/image/upload/v1682421049/Group_430_hcoilu.svg'></img>
                                {/* <KeyboardTabIcon sx={{ fontSize: '15px', rotate: '90deg' }} /> */}
                            </a>
                        </Typography>
                    </Box>
                ))}
            </div>
        </div>
    )
}

export default PartnerOpp
