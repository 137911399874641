import React from 'react'
import { Typography } from '@mui/material'
import Container from '@mui/material/Container'
import Box from '@mui/material/Box'
import TextField from '@mui/material/TextField'
import FormControlLabel from '@mui/material/FormControlLabel'
import Checkbox from '@mui/material/Checkbox'
import Button from '@mui/material/Button'
import './scss/ForgotPassOTP.css'
import { Link, useNavigate, useParams } from 'react-router-dom'
import { useState, useRef, useEffect, useContext } from 'react'
import axios from 'axios'
import { Image } from 'cloudinary-react'
import { MuiOtpInput } from 'mui-one-time-password-input'
import Slider from 'react-slick';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { setError } from 'components/Register/store/dataSlice'


const forgetOTPSlider = [
    {
        id: 1,
        linkImg: 'https://res.cloudinary.com/dtffniutw/image/upload/v1689518380/Mockup_1_1_vilx0a.svg',
    },
    {
        id: 2,
        linkImg: 'https://res.cloudinary.com/dtffniutw/image/upload/v1689518297/Mockup_PSD_FREE___iPad_Pro___Pencil_2018-removebg-preview_1_bnh73w.svg',
    },
    {
        id: 3,
        linkImg: 'https://res.cloudinary.com/dtffniutw/image/upload/v1689518380/Mockup_1_1_vilx0a.svg',
    },
    {
        id: 4,
        linkImg: 'https://res.cloudinary.com/dtffniutw/image/upload/v1689518297/Mockup_PSD_FREE___iPad_Pro___Pencil_2018-removebg-preview_1_bnh73w.svg',
    }
]

const ForgotPassOTP = () => {

    const [verifyOtp, setVerifyOtp] = useState('')
    const [isOtpSubmit, setIsOtpSubmit] = useState(false)
    const [error, setError] = useState({
        otp: false,
        normal: false
    })
    const handleChange = (newValue) => {
        setVerifyOtp(newValue)
    }


    const [isResendButtonDisabled,setIsResendButtonDisabled]=useState(true);
    const [remainingTime, setRemainingTime] = useState(60);
  
    useEffect(() => {
      if(isResendButtonDisabled)
      {
        const timer = setInterval(() => {
          setRemainingTime((prev) => prev - 1);
        }, 1000);
    
        return () => clearInterval(timer);
      }
     
    }, [isResendButtonDisabled]);
  
    useEffect(() => {
     
      if (remainingTime <= 0) {
        setIsResendButtonDisabled(false);
      }
    }, [remainingTime]);
  
    // const handleChange = (e) => {
    //     setVerifyOtp(e.target.value)
    // }
    const { email } = useParams()
    const navigate = useNavigate()

    const submitOTP = async () => {
        setError((prev) => {
            return {
                otp: false,
                normal: false
            }
        })
        try {


            const response = await axios.post(`${process.env.REACT_APP_STEELADMIN}/api/auth/verifyOtp/${email}`, { verifyOtp }).then((res) => {
                if (res.data.statusCode === 200) {
                    navigate('/newpassword', { state: { verifyOtp } })
                }

            }).catch((err) => {
                // console.log(err)
                if (err.response.status === 400) {

                    setError((prev) => {
                        return {
                            otp: true,
                            normal: false
                        }
                    })
                }
                else {

                    setError((prev) => {
                        return {
                            otp: false,
                            normal: true
                        }
                    })
                }
            })

        } catch (error) {
            // console.log(error)
        }
    }

    const handleSubmit = (event) => {
        event.preventDefault()
        // console.log(verifyOtp)
        setIsOtpSubmit(true)
        submitOTP()
    }

    const handleResendOtp = async () => {
        alert("Opt has been sent")

        setIsResendButtonDisabled(true);
        setRemainingTime(60);
    
        const timer = setInterval(() => {
          setRemainingTime((prev) => prev - 1);
        }, 1000);

        
        try {
            const res = axios.post(`${process.env.REACT_APP_STEELADMIN}/api/auth/forgotPassword`, { email }).then((res) => {
                if (res.data.statusCode === 200) {
                    //    alert("otp sent again")

                }
                else {

                    alert("proccess failed ")

                }
            }).catch((err) => {
                // console.log("err");
                alert("error occured")
            })

        } catch (error) {
            // console.log("api calling failed")
        }
        return () => clearInterval(timer);

    }
    // , { state: { verifyOtp } }

    // useEffect(() => {
    //     const submitOTP = async () => {
    //         try {
    //             const response = await axios.post(`${process.env.REACT_APP_STEELADMIN}/api/auth/verifyOtp`, { verifyOtp }).then((res)=>{

    //                 navigate('/newpassword')
    //             })
    //         } catch (error) {
    //             console.log(error)
    //             alert("Error Processing Data");
    //         }
    //     }
    //     if (isOtpSubmit) {
    //         submitOTP()
    //     }
    // }, [isOtpSubmit])




    //Function to Allow only numbers 0-9, backspace, and delete key  
    const handleKeyPress = (e) => {
        const keyCode = e.keyCode || e.which;
        const keyValue = String.fromCharCode(keyCode);
        // Allow number keys (both top row and numeric keypad) and backspace/delete
        const validInput = /^[0-9\b]+$/.test(keyValue) || (
            (keyCode >= 96 && keyCode <= 105) && // Numeric keypad
            (keyCode !== 110 && keyCode !== 190) // Exclude decimal point
        );
        if (!validInput) {
            e.preventDefault();
        }
    };

    var settings = {
        fade: true,
        dots: true,
        arrows: false,
        infinite: true,
        slidesToShow: 1,
        slidesToScroll: 1,
        autoplay: true,
        autoplaySpeed: 2000,
        cssEase: 'cubic-bezier(0.7, 0, 0.3, 1)',
    };

    return (
        <div className="forgotpassOTPMain">
            <div className='forgotpassOTPStart'>
                <Box className="forgotpassOTPbOX">
                    <form className="forgotpassOTP-form" action="" onSubmit={handleSubmit}>
                        <div>
                            <Typography
                                variant="h3"
                            >
                                Check your email
                            </Typography>
                            <Typography
                                variant="h5"
                            >
                                We've sent an verification code to your mail.
                            </Typography>
                        </div>



                    <MuiOtpInput 
                    className='forgotOTPInput' 
                    style={{ width: "100%" }} 
                    length={4} value={verifyOtp} 
                    onChange={handleChange} 
                    onKeyDown={handleKeyPress} 
                    />
                        
                        {/* <TextField
                    id="outlined-basic"
                    variant="outlined"
                    value={verifyOtp}
                    onChange={(e) => handleChange(e)}
                    
                /> */}


                        <div style={{width:'100%'}}>
                            <Button
                                type="submit"
                                variant="contained"
                            >
                                Verify
                            </Button>

                            {/* <Typography variant='p' className='forOTPResend'>
                    Didn't receive the OTP? <span onClick={handleResendOtp}>Resend OTP</span>
                </Typography> */}

                            
                            <Typography
                            className='forOTPResend'
                                sx={{
                                    mt: '2%',
                                    fontSize: '14px',
                                    display: 'flex',
                                    flexDirection: 'row',
                                    justifyContent: 'center',
                                    alignItems: "center",
                                    width: '100%',
                                    color:'#9F9F9F',

                                }}
                               >
                                Didn't receive OTP?
                                 <Button 
                                 style={{ marginLeft: '1%',backgroundColor:'#fff',color:'#16469D',width:'fit-content' }}
                                  onClick={handleResendOtp} 
                                  sx={{ margin: "0px !important" }}
                                    disabled={isResendButtonDisabled}>{isResendButtonDisabled ? `00:${remainingTime}` : "Resend"}</Button>
                            </Typography>

                        </div>



                        <Link to='/Signin' className='forgetOTPBack'>
                            <ArrowBackIcon className='OTPbackItem' />
                            <Typography variant='h6' > Back to login</Typography>
                        </Link>

                        {error.otp && <p style={{ color: "red", fontSize: "12px" }}>OTP IS NOT CORRECT</p>}
                        {error.normal && <p style={{ color: "red", fontSize: "12px" }}>OTP VERIFICATION FAILED</p>}
                    </form>
                </Box>

                <Box className='ForgotOTPLOginBox2'>
                    <Box className='ForgotOTPBoxSTart2'>
                        <Slider {...settings}>
                            {forgetOTPSlider.map((item) =>
                                <div className='ForgiotOTPMEdiaBox2' >
                                    <img src={item.linkImg} alt='Computers' />
                                </div>
                            )}
                        </Slider>
                    </Box>
                    <div className='ForgotttOTPBOxInfo' >
                        <Typography variant='h4' style={{ marginBottom: "1.5%" }}>Join the Steel Revolution!</Typography>
                        <Typography variant='h6'>Shop from more than 2000 trusted suppliers <br />for over 3000+ products</Typography>
                    </div>
                </Box>
            </div>
        </div>
    )
}

export default ForgotPassOTP