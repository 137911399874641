import Footer from 'components/Footer/Footer'
import NavBar from 'components/NavBar/NavBar'
import PartnerBenefits from 'components/PartnerBenefits/PartnerBenefits'
import PartnerBg from 'components/PartnerBg/PartnerBg'
import PartnerForm from 'components/PartnerForm/PartnerForm'
import PartnerLogo from 'components/PartnerLogo/PartnerLogo'
import PartnerOpp from 'components/PartnerOpp/PartnerOpp'
import PartnerProgram from 'components/PartnerProgram/PartnerProgram'
import React from 'react'
import { Image } from 'cloudinary-react'
import { Helmet } from "react-helmet";
const PartnerPage = () => {
  return (
    <>
          <Helmet>
                <html lang="en" />
                <title>Partner With Us | EPC Services Company | Steel Industry Portal | kjs Steel | kjssteel</title>
                <meta name="description" content="KJS Steel is one of the leading steel plant engineering consultants in India offering EPC services as well as turnkey solutions." />
                <meta name="title" content="Partner With Us | EPC Services Company | Steel Industry Portal | kjs Steel | kjssteel" />
                <meta name="keywords" content="Partner With Us | EPC Services Company | Steel Industry Portal | kjs Steel | kjssteel" />

            </Helmet>
    <div>
        <NavBar/>
        <PartnerBg/>
        <PartnerLogo/>
        <PartnerBenefits/>
        <PartnerOpp/>
        <PartnerProgram/>
        <PartnerForm/>
        <Footer/>

        
<div style={{position: 'fixed', bottom: '2rem', right: '2rem', zIndex: '999', backgroundImage: 'none', background: 'none', boxSizing: 'none', borderStyle: 'none',cursor:'pointer'}}>
                <a href="https://api.whatsapp.com/send?phone=9321714104&text=I%20am%20interested!" target="_blank">
                    <Image style={{height: '7vh', width: '7vh',cursor:'pointer',}} publicId="https://res.cloudinary.com/dxluokypg/image/upload/v1680763106/whatsapp_gqgneb.png" cloudName="dxluokypg" />
                </a>
            </div>
    </div>
    </>
  )
}

export default PartnerPage