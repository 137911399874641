import React from 'react'
import { Typography } from '@mui/material'
import Container from '@mui/material/Container'
import Box from '@mui/material/Box'
import TextField from '@mui/material/TextField'
import FormControlLabel from '@mui/material/FormControlLabel'
import Checkbox from '@mui/material/Checkbox'
import Button from '@mui/material/Button'
import './scss/ForgotPassword.css'
import { Link, useNavigate } from 'react-router-dom'
import { useState, useRef, useEffect, useContext } from 'react'
import axios from 'axios'
import { Image } from 'cloudinary-react'
import Slider from 'react-slick';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import ErrorIcon from '@mui/icons-material/Error';



const forgetSlider = [
    {
        id: 1,
        linkImg: 'https://res.cloudinary.com/dtffniutw/image/upload/v1689518380/Mockup_1_1_vilx0a.svg',
    },
    {
        id: 2,
        linkImg: 'https://res.cloudinary.com/dtffniutw/image/upload/v1689518297/Mockup_PSD_FREE___iPad_Pro___Pencil_2018-removebg-preview_1_bnh73w.svg',
    },
    {
        id: 3,
        linkImg: 'https://res.cloudinary.com/dtffniutw/image/upload/v1689518380/Mockup_1_1_vilx0a.svg',
    },
    {
        id: 4,
        linkImg: 'https://res.cloudinary.com/dtffniutw/image/upload/v1689518297/Mockup_PSD_FREE___iPad_Pro___Pencil_2018-removebg-preview_1_bnh73w.svg',
    }
]

const ForgotPassword = () => {

    const navigate = useNavigate()
    
    const [email, setEmail] = useState('')
    const [isSubmit, setIsSubmit] = useState(false)
    const [error, setError] = useState(false)

    const handleChange = (e) => {
        setEmail(e.target.value)
    }

    const validate = (email) => {
        
        
        const regexemail = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/
        if (email==='') {
            return false;
        }
        else if (!regexemail.test(email)) {
            return false
        }
        return true
    }

    const handleSubmit = async(event) => {
        event.preventDefault()
        
        setError(false);

        if(validate(email))
        {
            try {
                const response = await axios.post(`${process.env.REACT_APP_STEELADMIN}/api/auth/forgotPassword`, {email}).then((res)=>{
                    // console.log(res)
                    if(res.data.statusCode===200)
                    {
                    navigate(`/forgotpassOTP/${email}`,)

                    }
                    else
                    {
                        setError(true)
                        // console.log("Email Id is not registered ")
                    }
                }).catch((err)=>{
                    setError(true)
                })
            } catch (error) {
                // console.log(error)
                setError(true)
            }
        }
        else{
            setError(true)
        }
       
    }


    useEffect(() => {
        const submitEmail = async() => {
           
        }
        if (error === '' && isSubmit) {
            submitEmail()
        }
        
    }, [isSubmit])


    var settings = {
        fade: true,
        dots: true,
        arrows: false,
        infinite: true,
        slidesToShow: 1,
        slidesToScroll: 1,
        autoplay: true,
        autoplaySpeed: 2000,
        cssEase: 'cubic-bezier(0.7, 0, 0.3, 1)',


    };

    return (
        <div className="forgotpasswordMain">
            <div className='forgotpasswordStart'>
                <Box className='forgotpasswordBox'>
            <form className="forgot-form" action="" onSubmit={handleSubmit}>
                        <Typography variant='h3'>Forgot Password?</Typography>
                        <Typography variant='h5'>Enter the email associated with your account</Typography>

                        <input type='text' placeholder='Email' name='email' value={email} onChange={handleChange} />
                        {error && 
                    <p style={{
                        width:'100%',
                         border: '2px solid 5F5E5E',
                          padding: '1%',
                          marginBottom:'2%',
                           justifyContent: 'left',
                            display: 'flex',
                            borderRadius:'4px',
                            fontSize:'14px',
                            color:'red',
                            //  backgroundColor: '#ffdfe4',
                             }}> <ErrorIcon sx={{ color: '#ff5d5d',marginRight: '8px'  }} />Invalid Email</p>}
                        <Button type="submit" variant="contained" > Send OTP </Button>


                        <Link to='/Signin' className='forgetBack'>
                                <ArrowBackIcon className='backItem' />
                                <Typography variant='h6' > Back to login</Typography>
                </Link>

                {/* {
                error &&
            <span style={{color:"red",fontSize:"12px"}}>
                Email is Not registered or Wrong Email Entered</span>
            } */}
            



            </form>

           
                </Box>


                <Box className='ForgotLOginBox2'>
                    <Box className='ForgotBoxSTart2'>
                        <Slider {...settings}>
                            {forgetSlider.map((item) =>
                                <div className='ForgiotMEdiaBox2' >
                                    <img src={item.linkImg} alt='Computers' />
                                </div>
                            )}
                        </Slider>
                    </Box>
                    <div className='ForgotttBOxInfo' >
                        <Typography variant='h4' style={{marginBottom:"1.5%"}}>Join the Steel Revolution!</Typography>
                        <Typography variant='h6'>Shop from more than 2000 trusted suppliers <br />for over 3000+ products</Typography>
                    </div>
                </Box>

            </div>
        </div>
    )
}

export default ForgotPassword