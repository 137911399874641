import {
    Button,
    Checkbox,
    FormControlLabel,
    FormGroup,
    TextField,
    Typography,
    FormLabel,
    FormControl,
    Box,
    Card,
} from '@mui/material'
import Radio from '@mui/material/Radio'
import RadioGroup from '@mui/material/RadioGroup'
import { orange } from '@mui/material/colors'
import React, { useRef, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import './scss/ContactUs.css'
import {
    setContactEmail,
    setContactMessage,
    setContactName,
    setContactNumber,
    setContactType,
} from './store/dataSlice'
import { contact } from './store/Contact.actions'
import { useEffect } from 'react'
import RegSuccess from '../../assets/images/RegisterSuccess.gif'
import { CardContent, Grid, } from '@mui/material'
import { color } from 'echarts'



const ContactUs = () => {
    const dispatch = useDispatch()

    const contactState = useSelector((state) => state.contact)
    const emailRef = useRef(null);
    const [data, setData] = useState({
        contactType: '',
        contactName: '',
        contactEmail: '',
        contactNumber: '',
        contactMessage: '',
    })

    const [errors, setErrors] = useState({
        contactType: '',
        contactName: '',
        contactEmail: '',
        contactNumber: '',
        contactMessage: '',
        radioErr: ''
    })
    const [isSubmit, setIsSubmit] = useState(false)
    const [isSuccess, setIsSuccess] = useState(false)
    const [radioError, setRadioError] = useState(false);

    const handleChange = (event, property) => {
        setData({ ...data, [property]: event.target.value })
        if (property === 'contactType') {
            setRadioError(false);
        }
    }

    useEffect(() => {
        // Focus the input field when needed
        if (emailRef.current) {
            emailRef.current.focus();
        }
    }, []);

    const validate = (values) => {
        const errors = {};
        const regexEmail = /^\w+([\.-]?\w+)@\w+([\.-]?\w+)(\.\w{2,3})+$/;
        const regexPhone = /^(\+|\d)[0-9]{7,16}$/;

        if (!values.contactName) {
            errors.contactName = 'Name is required!';
        } else if (!/^[A-Za-z\s]+$/.test(values.contactName)) {
            errors.contactName = 'Name should only contain letters and spaces.';
        }
        if (!values.contactEmail) {
            errors.contactEmail = 'Email is required!';
        } else if (!regexEmail.test(values.contactEmail)) {
            errors.contactEmail = 'Please enter a valid email.';
        }
        if (!values.contactNumber) {
            errors.contactNumber = 'Mobile Number is required!';
        } else if (!regexPhone.test(values.contactNumber)) {
            errors.contactNumber = 'Enter a valid phone number.';
        } 
        if (!values.contactMessage) {
            errors.contactMessage = 'Please write a message.';
        }

        return errors;
    };

    const handleSubmit = (event) => {
        event.preventDefault();
        setErrors({
            contactType: '',
            contactName: '',
            contactEmail: '',
            contactNumber: '',
            contactMessage: '',
        })
        if (!data.contactType) {
            setRadioError(true);
            return;
        }
        // console.log(data)
        setErrors(validate(data))
        setIsSubmit(true)
    }

    useEffect(() => {
        if (Object.keys(errors).length === 0 && isSubmit) {
            dispatch(setContactType(data.contactType))
            dispatch(setContactName(data.contactName))
            dispatch(setContactEmail(data.contactEmail))
            dispatch(setContactNumber(data.contactNumber))
            dispatch(setContactMessage(data.contactMessage))
            dispatch(contact(data))
            setIsSuccess(true)
            setData({
                contactType: '',
                contactName: '',
                contactEmail: '',
                contactNumber: '',
                contactMessage: '',
            })
            setTimeout(() => {
                setIsSuccess(false)
                alert('Form submitted successfully!');
            }, 2000)
        }
    }, [errors, isSubmit])




    return (
        <div className="contactUsBannerMain">
            <div className="contactUsBanner">
                <Box className='contactUsBanner1'
                    sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        color: 'white',
                        width: '100%',
                        // ml: '6%',
                        // alignItems: 'center',
                        // pr:'5%',
                        justifyContent: 'center',
                    }}>
                    <Typography variant="h3" sx={{
                        fontWeight: '700', fontSize: '40px',
                        lineHeight: '64px',
                        width: '90%',
                        ml: '6%',
                    }}>
                        Let’s Start a Conversation
                    </Typography>
                    <Typography
                        variant="p"
                        sx={{
                            fontWeight: '400',
                            lineHeight: '28px',
                            fontSize: '15px',
                            width: '40%',
                            ml: '6%',
                            display: 'flex',
                            marginRight: '8%',
                        }}
                    >
                    </Typography>

                </Box>
            </div>
            <div className="ContactFormStart">

                <div>
                    <Box className='ContactBox1' >

                        <div>


                            <Typography
                                sx={{

                                    color: '#16469D',
                                    opacity: '60%',
                                    fontWeight: '800',
                                    fontSize: { xs: '15px', sm: '15px', md: '16px', lg: '16px', xl: '18px' },
                                    lineHeight: '20px',

                                }}
                            >Address :</Typography>
                            <Typography
                                style={{ textAlign: "justify",maxWidth:"80%" }}
                                sx={{
                                    color: '#585858',
                                    fontWeight: '500',
                                    fontSize: { xs: '12px', sm: '15px', md: '15px', lg: '15px' },
                                    lineHeight: { xs: '16px', sm: '18px', md: '20px', lg: '25px' },




                                }}>Kamdhenu Commerz Commercial
                                Business Park, Sector 14, Kharghar,
                                Navi Mumbai, Maharashtra 410210</Typography>
                        </div>

                        <div>
                            <Typography
                                sx={{

                                    color: '#16469D',
                                    opacity: '60%',
                                    fontWeight: '800',
                                    fontSize: { xs: '15px', sm: '15px', md: '16px', lg: '16px', xl: '18px' },
                                    lineHeight: '20px',
                                }}>Working Hours</Typography>
                            <Typography
                                sx={{

                                    color: '#585858',
                                    fontWeight: '500',
                                    fontSize: { xs: '12px', sm: '15px', md: '15px', lg: '15px' },
                                    lineHeight: { xs: '18px', sm: '20px', md: '30px', lg: '30px' },
                                }}>Monday To Friday</Typography>

                            <Typography
                                sx={{
                                    color: '#585858',
                                    fontWeight: '500',
                                    fontSize: { xs: '12px', sm: '15px', md: '15px', lg: '15px' },
                                    lineHeight: { xs: '18px', sm: '20px', md: '30px', lg: '30px' },
                                }}>9:00 AM to 8:00 PM </Typography>
                            <Typography
                                sx={{
                                    color: '#585858',
                                    fontWeight: '500',
                                    fontSize: { xs: '12px', sm: '15px', md: '15px', lg: '15px' },
                                    lineHeight: { xs: '15px', sm: '22px', md: '25px', lg: '30px' },
                                }}>Our Support Team is available 24/7</Typography>
                        </div>

                        <div>


                            <Typography
                                sx={{

                                    color: '#16469D',
                                    opacity: '60%',
                                    fontWeight: '800',
                                    fontSize: { xs: '15px', sm: '15px', md: '16px', lg: '16px', xl: '18px' },
                                    lineHeight: '20px',
                                }}>Contact Us</Typography>
                            <Typography
                                sx={{
                                    color: '#585858',
                                    fontWeight: '500',
                                    fontSize: { xs: '12px', sm: '15px', md: '15px', lg: '15px' },
                                    lineHeight: { xs: '18px', sm: '20px', md: '30px', lg: '30px' },


                                }}>9321714104</Typography>
                            <Typography
                                sx={{
                                    color: '#585858',
                                    fontWeight: '500',
                                    fontSize: { xs: '12px', sm: '15px', md: '15px', lg: '15px' },
                                    lineHeight: { xs: '13px', sm: '22px', md: '25px', lg: '30px' },

                                }}>info@kjssteel.com</Typography>
                        </div>

                    </Box>
                    <form onSubmit={handleSubmit} className='FormInput'>
                        <div style={{position:"relative"}}>

                       
                        <RadioGroup className='FormContlLabel' 
                            row
                            aria-labelledby="demo-row-radio-buttons-group-label"
                            name="row-radio-buttons-group"
                            // onChange={(e) => handleChange(e, 'contactType')}
                            onChange={(e) => {
                                handleChange(e, 'contactType');
                                setRadioError(false);
                            }}
                            // required
                        >
                            <FormControlLabel
                                value="Vendor"
                                checked={data.contactType === 'Vendor'}
                                // required
                                control={
                                    <Radio
                                        sx={{
                                            color: '#16469D',
                                            '&.Mui-checked': {
                                                color: '#16469D',
                                            },
                                        }}
                                        size='small'
                                    />
                                }
                                label="Vendor"
                            />
                            <FormControlLabel
                                value="Customer"
                                checked={data.contactType === 'Customer'}
                                // required
                                control={
                                    <Radio
                                        sx={{
                                            color: '#16469D',
                                            '&.Mui-checked': {
                                                color: '#16469D',
                                            },
                                        }}
                                        size='small'

                                    />
                                }
                                label="Customer"
                            />
                            <FormControlLabel
                                value="Dealer"
                                checked={data.contactType === 'Dealer'}
                                // required
                                control={
                                    <Radio
                                        sx={{
                                            color: '#16469D',
                                            '&.Mui-checked': {
                                                color: '#16469D',
                                            },
                                        }}
                                        size='small'

                                    />
                                }
                                label="Dealer"
                            />
                        </RadioGroup>
                        {radioError && (
                            <Typography variant="body2" color="red" style={{position:"absolute",top:"85%", display:'flex'}}>
                                Please select a role.
                            </Typography>
                        )}
                         </div>
                         <div>
                        <TextField placeholder='Full Name*' variant='outlined' fullWidth
                        //  required
                            value={data.contactName}
                            onChange={(e) => {
                                const inputValue = e.target.value;
                                // Allow only alphabetic characters and spaces
                                const sanitizedValue = inputValue.replace(/[^A-Za-z\s]/g, '');
                                handleChange({ target: { value: sanitizedValue } }, 'contactName');
                            }}
                            inputRef={emailRef}
                            sx={{
                                bgcolor: '#FFFFFF',
                                color: '#232536',
                                fontWeight: '400',
                                fontSize: '15px',
                                lineHeight: '28px',
                            }} />
                        {errors.contactName ? <p style={{ fontSize: '12px', color: 'red', display: 'flex', justifyContent: 'flex-start', top:'0' }}>{errors.contactName}</p> : ''}
                        </div>
                        <div>
                        <TextField type='Email' placeholder='Your Email*' variant='outlined' fullWidth 
                        // required
                            value={data.contactEmail}
                            onChange={(e) => handleChange(e, 'contactEmail')}
                            sx={{
                                bgcolor: '#FFFFFF',
                                color: '#232536',
                                fontWeight: '400',
                                fontSize: '15px',
                                lineHeight: '28px',
                              

                            }} />
                        {errors.contactEmail ? <p style={{ fontSize: '12px', color: 'red', display: 'flex', justifyContent: 'flex-start' }}>{errors.contactEmail}</p> : ''}
                        </div>
                        <div>
                        <TextField className="NumberArea" type='text' placeholder='Phone Number*' variant='outlined' fullWidth
                        //  required
                            value={data.contactNumber}
                            onChange={(e) => handleChange(e, 'contactNumber')}
                            sx={{
                                bgcolor: '#FFFFFF',
                                color: '#232536',
                                fontWeight: '400',
                                fontSize: '15px',
                                lineHeight: '28px',
                              


                            }} />
                        {errors.contactNumber ? <p style={{ fontSize: '12px', color: 'red', display: 'flex', justifyContent: 'flex-start' }}>{errors.contactNumber}</p> : ''}
                        </div>
                        <div>
                        <textarea
                            className='TextInput12345'
                            rows="4"
                            column="10"
                            placeholder="Message*"
                            color='blue'
                            fullWidth
                            // required
                            
                            value={data.contactMessage}
                            onChange={(e) => handleChange(e, 'contactMessage')}
                            style={{ minHeight:"80px" }}
                        />
                        {errors.contactMessage ? <p style={{ fontSize: '12px', color: 'red', display: 'flex', justifyContent: 'flex-start' }}>{errors.contactMessage}</p> : ''}
                        </div>
                        <Button
                            className="SubmitBtn"
                            type="submit"
                            variant="contained"
                            style={{
                                width: '100%',
                               
                                fontWeight: '400',
                                textTransform: 'capitalize',
                                fontSize: '16px',
                                lineHeight: '32PX',
                                color: '#ffffff',
                                display: 'flex',
                                backgroundColor: '#16469D',
                                cursor: 'pointer',
                            }}
                        >
                            Send Message
                        </Button>


                    </form>

                </div>



            </div>
        </div>
    )
}

export default ContactUs