import { Box, Button, Typography } from '@mui/material';
import React, { useState, useEffect } from 'react';
import './scss/UserRegisterEmail.css';
import myImage from '../../assets/images/mobileGif.gif';
import axios from 'axios';
import { useParams } from 'react-router-dom';
import { useNavigate, useLocation } from 'react-router-dom';
import Slider from 'react-slick';
import { MuiOtpInput } from 'mui-one-time-password-input'
import { useHistory } from 'react-router-dom';
import ErrorIcon from '@mui/icons-material/Error';
import useBackdrop from 'hooks/useBackdrop';
import '../OTPFieldCSS/scss/OTPFieldcss.css';
const imageslidedata = [
  {
    id: 1,
    linkImg: 'https://res.cloudinary.com/dtffniutw/image/upload/v1689518380/Mockup_1_1_vilx0a.svg',
  },
  {
    id: 2,
    linkImg: 'https://res.cloudinary.com/dtffniutw/image/upload/v1689518297/Mockup_PSD_FREE___iPad_Pro___Pencil_2018-removebg-preview_1_bnh73w.svg',
  },
  {
    id: 3,
    linkImg: 'https://res.cloudinary.com/dtffniutw/image/upload/v1689518380/Mockup_1_1_vilx0a.svg',
  },
  {
    id: 4,
    linkImg: 'https://res.cloudinary.com/dtffniutw/image/upload/v1689518297/Mockup_PSD_FREE___iPad_Pro___Pencil_2018-removebg-preview_1_bnh73w.svg',
  },

]

function UserRegisterEmail() {
  const { openBackdrop, closeBackdrop, BackdropComponent } = useBackdrop();
  var settings = {
    fade: true,
    dots: true,
    arrows: false,
    infinite: true,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 2000,
    cssEase: 'cubic-bezier(0.7, 0, 0.3, 1)',
  };

  const [otpValue, setOtpValue] = useState('');

  const navigate = useNavigate();
  const location = useLocation();


  const handleKeyPress = (e) => {
    const keyCode = e.keyCode || e.which;
    const keyValue = String.fromCharCode(keyCode);
    // Allow number keys (both top row and numeric keypad) and backspace/delete
    const validInput = /^[0-9\b]+$/.test(keyValue) || (
      (keyCode >= 96 && keyCode <= 105) && // Numeric keypad
      (keyCode !== 110 && keyCode !== 190) // Exclude decimal point
    );
    if (!validInput) {
      e.preventDefault();
    }
  };


  // const history = useHistory();

  // useEffect(() => {
  //   // Prevent navigation using the back button
  //   const unblock = history.block('You cannot go back.');

  //   // Cleanup the effect when the component unmounts
  //   return () => {
  //     unblock();
  //   };
  // }, [history]);

  // const handleNavigate = () => {
  //   // Use history.push to navigate to a different route
  //   history.push('/Sign In');
  // };

  // const [data, setData] = useState({
  //   email: ''
  // });
  // const email = [businessEmail, setBusinessEmail]= ({})
  const { userPhoneNumber, id } = useParams(); // Get businessEmail and id from URL params

  // const handleChange = (event) => {
  //   setData({ ...data, email: event.target.value });
  // };

  // //   ******************8newAPI********************
  // corporate/corporateRegister/{email}

  const [otpError, setOtpError] = useState(false);





  const [isResendButtonDisabled, setIsResendButtonDisabled] = useState(true);
  const [remainingTime, setRemainingTime] = useState(60);

  useEffect(() => {
    if (isResendButtonDisabled) {
      const timer = setInterval(() => {
        setRemainingTime((prev) => prev - 1);
      }, 1000);

      return () => clearInterval(timer);
    }

  }, [isResendButtonDisabled]);

  useEffect(() => {

    if (remainingTime <= 0) {
      setIsResendButtonDisabled(false);
    }
  }, [remainingTime]);



  const handleEmailVerification = async () => {
    openBackdrop()
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_STEELADMIN}/api/auth/verifyMobileOtpOnRegister/${id}/${userPhoneNumber}`,
        {
          verifyMobileOtp: otpValue,

        },
        {
          headers: {
            'Content-Type': 'application/json',
          },
        }
      ).then((res) => {

closeBackdrop()

        console.log("otp verified")

        navigate("/verify",)
      }).catch((err) => {

        // closeBackdrop()
        // console.log(err.response.status)
        if (err.response.status === 400) {
          setOtpError(true);
          closeBackdrop()
        }
        else {
          closeBackdrop()

          alert("soemthing went wrong with processs")
        }

      })
      closeBackdrop()
    } catch (err) {

      closeBackdrop()
      console.log(err);

      // Handle error here if the API call fails
      // You can also set an error state and display a user-friendly error message on the UI.
    }
  };

  // *******************************Resend OTP Start*************************
  const handleResendOtp = async () => {
    setOtpError(false)

    setIsResendButtonDisabled(true);
    setRemainingTime(60);

    const timer = setInterval(() => {
      setRemainingTime((prev) => prev - 1);
    }, 1000);


    try {
      const response = await axios.post(
        `${process.env.REACT_APP_STEELADMIN}/api/auth/resendOtpOnRegisteredMobile/${userPhoneNumber}`,
        {
          verifyMobileOtp: otpValue,

        },
        {
          headers: {
            'Content-Type': 'application/json',
          },
        }
      ).catch((err) => {

        alert("soemthing went wrong with processs")

      })
      // .then( (res)=>{
      //   console.log("otp verified")
      //   navigate("/verify")
      // }).catch((err)=>{
      //   console.log("error occured "+err.response.status)
      // })



    } catch (err) {
      // console.log("error occured")


    }
    return () => clearInterval(timer);
  };

  // *******************************Resend OTP End*************************
  return (
    <div className='optVerification' style={{ width: '100%', display: 'flex' }}>
      <Box className='OTPBox'>
        <form action='' className='OTPFormMob'>
          <div className='OTPItem'>
            <Typography variant='h3'>Lets go!</Typography>
          </div>

          <div className='OTPItem'>
            <img src={myImage} alt="Mail Icon" />
          </div>

          <div className='OTPItem'>
            <Typography variant='h5'>Sms Sent To <strong>{userPhoneNumber}</strong></Typography>
          </div>

          <div className='OTPItem'>
            <MuiOtpInput 
            className='muiOTPPField'
              length={5}
              value={otpValue}
              onChange={(tt) => { setOtpValue(tt) }}
              onKeyDown={handleKeyPress}
            />

            {otpError &&
              <p style={{
                width: '60%',
                border: '2px solid 5F5E5E',
                padding: '1%',
                marginTop: '2%',
                justifyContent: 'center',
                display: 'flex',
                borderRadius: '4px',
                fontSize: '14px',
                color: 'red',
              }}> <ErrorIcon sx={{ color: '#ff5d5d', marginRight: '8px' }} /> Incorrect OTP</p>}
          </div>

          <div className='OTPItem'>
            <Button className='verifyButtonn' variant='contained' onClick={handleEmailVerification}>
              Verify
            </Button>
          </div>

          <div className='OTPItem'>
            <Box>
              <Typography variant='p' className='didntOTPText'>
                Didn't receive OTP?
                <Button className='didntOTPButton'
                  onClick={handleResendOtp} 
                  disabled={isResendButtonDisabled}>
                  {isResendButtonDisabled ? `00:${remainingTime}` : "Resend"}
                </Button>
              </Typography>
            </Box>


          </div>

        </form>
      </Box>

      <Box className='Coporateverification'>
        <Box className='RegiaterBoxStart2' >
          <Slider {...settings}>
            {imageslidedata.map((item) =>
              <div className='RegiaterMediaBox2' >
                <img src={item.linkImg} alt='Computers' />
              </div>
            )}
          </Slider>
        </Box>

        <div className='BoxInfo' >
          <Typography variant='h4' style={{ marginBottom: "1.5%" }}>Join the Steel Revolution!</Typography>
          <Typography variant='h6'>Shop from more than 2000 trusted suppliers <br /> for over 3000+ products</Typography>
        </div>
      </Box>
      <BackdropComponent/>
    </div>
  );
}

export default UserRegisterEmail;