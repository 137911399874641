import React from 'react'
import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography';
import EmailVerification from '../../assets/images/EmailVerification.svg'
import { border } from '@mui/system';
import { Image } from 'cloudinary-react'


function EmailVerifing() {

    return (
        <div>
            <div
                style={{
                    width: '100%',
                    height: '100vh',
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'center',
                    alignItems: 'center',
                }}>
                <Box>
                    <img src={EmailVerification} alt="EmailVerification" />
                </Box>
                <Box
                    sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        justifyContent: 'center',
                        alignItems: 'center',
                    }}>
                    <Typography variant='h5'
                        sx={{
                            fontWeight: '510',
                            fontSize: '20px',
                            lineHeight: '3rem',
                            textAlign: 'center',
                            color: '#585858',
                            fontFamily: `system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif`
                        }}>
                        Verify your email to proceed
                    </Typography>
                    <Typography
                        sx={{
                            width: '60%',
                            textAlign: 'center',
                            fontWeight: '400',
                            fontSize: '15px',
                            lineHeight: '1.2rem',
                            color: '#656363',
                            fontFamily: `system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif`

                        }}>
                        We just emailed you a verification link on your registered email. The link will expire
                        shortly.  So be sure to click on it to get started.
                    </Typography>
                </Box>
            </div>
            <div style={{position: 'fixed', bottom: '2rem', right: '2rem', zIndex: '999', backgroundImage: 'none', background: 'none', boxSizing: 'none', borderStyle: 'none',cursor:'pointer'}}>
                <a href="https://api.whatsapp.com/send?phone=9321714104&text=I%20am%20interested!" target="_blank">
                    <Image style={{height: '7vh', width: '7vh',cursor:'pointer',}} publicId="https://res.cloudinary.com/dxluokypg/image/upload/v1680763106/whatsapp_gqgneb.png" cloudName="dxluokypg" />
                </a>
            </div>
        </div>
    )
}

export default EmailVerifing