import React,{useState} from 'react'
import axios from 'axios';
import { useNavigate,useLocation } from 'react-router-dom';
import { Box, Button, TextField, Typography } from '@mui/material';
import './scss/OTPVerification.css';
import { Image } from 'cloudinary-react';
import { MuiOtpInput } from 'mui-one-time-password-input'
import { useSelector } from 'react-redux';
import { loginUser } from 'services/UserService'
import { doLogin } from 'auth'

const OTPVerification = () => {
 
  // api call with the enter otp
//   verifyMobileOtp

const location = useLocation();
  const { user } = location.state;
  const [otp,setOtp]=useState("");
  const navigate=useNavigate();
  // console.log(user);
  const [showError,setShowError]=useState(false)
  const [loginDetail, setLoginDetail] = useState({
   usernameOrEmail: user.email,
    password: user.password,
})
  const handleInput=(e)=>{
    setOtp(e.target.value)
    
  }
  const handleSubmit=async(event)=>{

   
    event.preventDefault()
    try {
       
      const res=await axios.post(`${process.env.REACT_APP_STEELADMIN}/api/auth/verifyMobileOtp`,{
        verifyMobileOtp:otp
      });
      // console.log(res.data);
      if(res.data.data.otpVerified===true)
      {
      
        
        loginUser(loginDetail)
        .then((data) => {
            // console.log(data)
            doLogin(data, () => {
                // console.log('login detail is saved to localstorage')
            })
            setTimeout(() => {
                navigate('/',)
            }, 500)
        })
        .catch((error) => {
            // console.log(error)
            alert('Something went wrong')
        })
         
      }
      else
      {
        setShowError(true);
      }
    } catch (error) {
        console.error(error);
    }
  }
{/* <TextField className='firstTextSecond' fullWidth label="Full Name" id="fullWidth" /> */}

  return (
<div className='mainClass'>

    <Box className='firstBox'>
    <form 
     onSubmit={handleSubmit}
    >
      <Typography variant='h1'>Lets go!</Typography>
     <Box className='imgBox'>
     <img className='phoneImg' src='https://res.cloudinary.com/dxluokypg/image/upload/v1687886928/70771-otp-blue_1_x4vrjo.png' />
     </Box>
      <Typography variant='h2'>Enter the OTP sent to +91 8169558897</Typography>
     
      {/* <MuiOtpInput type="text" value={otp} onChange={handleInput}
      sx={{marginTop: '15%', color: 'black'}} 
      /> */}
      <input  type="number" value={otp} onChange={handleInput}
      style={{marginTop: '15%', color: 'black'}}  />
      <Button type='submit' 
      variant="contained" fullWidth sx={{mt:'20%',mb:'5%',bgcolor:'#3578DE',textTransform:'capitalize'}}>Verify</Button>
      <Typography variant='h3'>Didn’t receive the OTP? 
      <a>Resend OTP</a></Typography>
      </form>
      {/* {showError && <p>Otp not verified</p>} */}
    </Box>
    
  </div>
  )
}

export default OTPVerification