import { createSlice } from "@reduxjs/toolkit";

const feedbackSlice = createSlice({
    name: 'feedback',
    initialState: {
        feedbackName: '',
        feedbackDesignation: '',
        feedbackEmail: '',
        feedbackSuggestions: '',
        loading: false,
        error: null,
        success: false,
    },
    reducers: {
        setFeedbackName: (state, action) => {
            state.feedbackName = action.payload
        },
        setFeedbackDesignation: (state, action) => {
            state.feedbackDesignation = action.payload
        },
        setFeedbackEmail: (state, action) => {
            state.feedbackEmail = action.payload
        },
        setFeedbackSuggestions: (state, action) => {
            state.feedbackSuggestions = action.payload
        },
        setLoading: (state, action) => {
            state.loading = action.payload
        },
        setError: (state, action) => {
            state.error = action.payload
        },
        setSuccess: (state, action) => {
            state.success = action.payload
        },
    }
})

export const {
    setFeedbackName,
    setFeedbackDesignation,
    setFeedbackEmail,
    setFeedbackSuggestions,
    setLoading,
    setError,
    setSuccess,
} = feedbackSlice.actions

export default feedbackSlice.reducer