import React from 'react'
import { Box, Typography } from '@mui/material'
import PropTypes from 'prop-types'
import Tabs from '@mui/material/Tabs'
import Tab from '@mui/material/Tab'
import '../FaqSection/scss/FaqSection.css'
import ArrowForwardIcon from '@mui/icons-material/ArrowForward'
import axios from 'axios'
import Accordion from '@mui/material/Accordion'
import AccordionDetails from '@mui/material/AccordionDetails'
import AccordionSummary from '@mui/material/AccordionSummary'
// import Typography from '@mui/material/Typography';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'

// import { Scrollbars } from 'react-custom-scrollbars-2'
import { useEffect, useState } from 'react'


function TabPanel(props) {
    const { children, value, index, ...other } = props

    const [expanded, setExpanded] = React.useState(false)

    const handleChange = (panel) => (event, isExpanded) => {
        setExpanded(isExpanded ? panel : false)
    }

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`vertical-tabpanel-${index}`}
            aria-labelledby={`vertical-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box sx={{ p: 3 }}>
                    <Typography>{children}</Typography>
                </Box>
            )}
        </div>
    )
}

TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.number.isRequired,
    value: PropTypes.number.isRequired,
}

function a11yProps(index) {
    return {
        id: `vertical-tab-${index}`,
        'aria-controls': `vertical-tabpanel-${index}`,
    }
}

export default function FaqSection() {
    // accordian start

    const [expanded, setExpanded] = React.useState(false)

    const handleChange1 = (panel) => (event, isExpanded) => {
        setExpanded(isExpanded ? panel : false)
    }
    // accordian end

    const [value, setValue] = React.useState(0)

    const handleChange = (event, newValue) => {
        setValue(newValue)
    }

    // api start
    const [data, setData] = useState([])

    const getCategoryData = async () => {
        try {
            const res = await axios.get(
                `${process.env.REACT_APP_STEELADMIN}/faqCategory/getAllFaq`
            )
            setData(res.data)
            // console.log(data)
        } catch (error) {
            // console.log(error)
        }
    }

    useEffect(() => {
        getCategoryData()
    }, [])

    const dummies = [1, 2, 3]

    const questions = [1, 2, 3, 4]

    return (
        <div className="MainFaq">
            <div className="SecondFaqTab">
                <div className="TabStart1">
                    <Typography variant='h5'>Topics</Typography>
                </div>
                <div className="TabStart">
                    <div className="TabBox1 ">
                        <Tabs className="TabHeadTab"
                            width="100%"
                            orientation="vertical"
                            variant="scrollable"
                            value={value}
                            onChange={handleChange}
                            aria-label="Vertical tabs example"
                            sx={{
                                height: 'auto',
                                borderColor: 'divider',
                                '& :hover': { color: '#16469D' },
                                '& :active': { color: '#16469D' },
                                '& button.Mui-selected': {
                                    color: '#16469D',
                                    backgroundColor: '#F6FAFF',
                                    borderRadius: '4%',
                                },
                                textTransform: 'none',
                                textAlign: 'left',
                            }}
                            TabIndicatorProps={{
                                sx: {
                                    display: 'none',
            
                                },
                            }}
                        >
                            {data.map((category) => (
                                <Tab
                                    // category name
                                    label={category.faqCategory}
                                    // id
                                    {...a11yProps(`${category.faqCategoryId}`)}
                                    className="TabHead"
                                ></Tab>
                            ))}
                        </Tabs>
                    </div>


{/* ---------------------------------------------------------------- */}

                    <div className="TabBoxHide">
                        <Tabs className="TabHeadTab "
                            width="100%"
                            orientation="horizontal"
                            variant="scrollable"
                            value={value}
                            onChange={handleChange}
                            aria-label="Vertical tabs example"
                            sx={{
                                height: 'auto',
                                borderColor: 'divider',
                                '& :hover': { color: '#16469D' },
                                '& :active': { color: '#16469D' },
                                '& button.Mui-selected': {
                                    color: '#16469D',
                                    backgroundColor: '#F6FAFF',
                                    borderRadius: '10px',
                                    margin:'5%',
                                },
                                textTransform: 'none',
                                textAlign: 'left',
                            }}
                            TabIndicatorProps={{
                                sx: {
                                    display: 'none',
                                    margin:'2%',

            
                                },
                            }}
                        >
                            {data.map((category) => (
                                <Tab
                                    // category name
                                    label={category.faqCategory}
                                    // id
                                    {...a11yProps(`${category.faqCategoryId}`)}
                                    className="TabHeadHide"
                                    style={{ margin:'3%'
                                }}
                                ></Tab>
                            ))}
                        </Tabs>
                    </div>


{/* ----------------------------------------------------- */}

                    <div className="TabBox2">
                        <div
                            className="scrollable-container"
                            style={{ borderLeft: '1px solid #bdbdbd' }}
                        >
                            {/* Tabpanel1 start */}
                            {data.map(category => <TabPanel key={category.faqCategoryId} value={value} index={category.faqCategoryId - 1}>
                                <Box className="FaqPanelBox" sx={{ width: '100%' }}>
                                    {category.faq.map(faq => (
                                        <Accordion
                                            sx={{
                                                borderBottom: '1px solid #bdbdbd',
                                                boxShadow: 'none',
                                                padding:'1.5% 0',
                                            }}
                                        >
                                            <AccordionSummary
                                                expandIcon={
                                                    <ExpandMoreIcon
                                                        sx={{ color: '#16469D', }}
                                                    ></ExpandMoreIcon>
                                                }
                                                aria-controls="panel1a-content"
                                                id="panel1a-header"
                                                
                                            >
                                                <Typography>
                                                    {faq.faqQuestion}
                                                </Typography>
                                            </AccordionSummary>
                                            <AccordionDetails>
                                                <Typography>
                                                    {faq.faqAnswer}
                                                </Typography>
                                            </AccordionDetails>
                                        </Accordion>
                                    ))}
                                </Box>
                            </TabPanel>
                            )}
                            {/* Tabpanel1 end */}

                            {/* </Scrollbars> */}
                        </div>
                    </div>
                </div>
            </div>
        </div >
    )
}
