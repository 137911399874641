import { IconButton, InputBase, Paper, Typography } from '@mui/material'
import React from 'react'
import SearchIcon from '@mui/icons-material/Search'
import { useEffect, useState } from 'react';
import '../FaqLandingPage/scss/FaqLandingPage.css'

const FaqLandingPage = () => {
    const [searchQuery, setSearchQuery] = useState('');
    const [searchResults, setSearchResults] = useState([]);

    useEffect(() => {
        const fetchData = async () => {
            const response = await fetch(`https://example.com/api/search?q=${searchQuery}`);
            const data = await response.json();
            setSearchResults(data.results);
        };

        fetchData();
    }, [searchQuery]);

    const handleSearchInput = (event) => {
        setSearchQuery(event.target.value);
    }
    return (
        <div className="FAQLP" >
            <div className="FAQLPContainer1">
            
            <Typography
                    variant="h3"
                    sx={{
                        fontSize: '15px',
                        fontWeight: '590',
                        lineHeight:'44px',
                        color: '#585858',
                        textAlign: 'left',
                    }}
                >
                    FAQs
                </Typography>
                <Typography
                    variant="h1"
                    sx={{
                        fontSize: '30px',
                        fontWeight: '590',
                        color: 'rgba(88, 88, 88, 1)',
                        textAlign: 'left',
                    }}
                >
                    Frequently Asked Questions
                </Typography>
                <Typography variant='h6'
                    sx={{
                        fontSize: '15px',
                        fontWeight: '400',
                        color: 'rgba(83, 104, 106, 1)',
                        textAlign: 'left',
                        pt: '1%',
                    }}
                >
                    Have any questions? We're here to assist you.
                </Typography>
            </div>
        </div>
    )
}

export default FaqLandingPage
