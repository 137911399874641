import {
    Button,
    Checkbox,
    FormControlLabel,
    FormGroup,
    TextField,
    Typography,
    FormLabel,
    FormControl,
    Box,
} from '@mui/material'
import Radio from '@mui/material/Radio'
import RadioGroup from '@mui/material/RadioGroup'
// import { orange } from '@mui/material/colors';
import React, { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import './scss/PartnerForm.css'
import {
    setPartnerName,
    setEmail,
    setMobileNumber,
    setCompanyName,
    setwebsite,
    setRolesDetails,
} from './store/dataSlice'
import { partner } from './store/Partner.actions'
import { useEffect } from 'react'
import { autoBatchEnhancer } from '@reduxjs/toolkit'
import RegSuccess from '../../assets/images/RegisterSuccess.gif'

const PartnerForm = () => {
    const dispatch = useDispatch()

    const partnerState = useSelector((state) => state.partner)

    const [data, setData] = useState({
        rolesDetails: '',
        partnerName: '',
        email: '',
        mobileNumber: '',
        companyName: '',
        website: '',
    })

    const [errors, setErrors] = useState({})
    const [isSubmit, setIsSubmit] = useState(false)
    const [isSuccess, setIsSuccess] = useState(false)

    const [radioError, setRadioError] = useState(false);

    const handleChange = (event, property) => {
        setData({ ...data, [property]: event.target.value })
    }

    const validate = (values) => {
        const errors = {}
        const regexemail = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/
        const regexphone = /^(\+|\d)[0-9]{7,16}$/
        const regexwebsite = /^((ftp|http|https):\/\/)?(www.)?(?!.(ftp|http|https|www.))[a-zA-Z0-9_-]+(\.[a-zA-Z]+)+((\/)[\w#]+)(\/\w+\?[a-zA-Z0-9_]+=\w+(&[a-zA-Z0-9_]+=\w+)*)?\/?$/gm
        if (!values.partnerName) {
            errors.partnerName = 'Name is required!'
        } else if (!/^[A-Za-z\s]+$/.test(values.partnerName)) {
            errors.partnerName = 'Name should only contain letters and spaces.';
        }
        if (!values.email) {
            errors.email = 'Email is required!'
        } else if (!regexemail.test(values.email)) {
            errors.email = 'Please enter a valid email.'
        }
         if (!values.mobileNumber) {
            errors.mobileNumber = 'Mobile Number is required!'
        } else if (!regexphone.test(values.mobileNumber)) {
            errors.mobileNumber = 'Enter a valid phone number.'
        }
        if (!values.companyName) {
            errors.companyName = 'Company name is required!'
        }
        if (!values.website) {
            errors.website = 'Company website is required!'
        }
        return errors
    }

    const handleSubmit = (event) => {
        event.preventDefault();
        // Check if the radio field is empty
        if (!data.rolesDetails) {
            setRadioError(true);
            return;
        }
        // console.log(data)
        setErrors(validate(data))
        setIsSubmit(true)
    }

    useEffect(() => {
        if (Object.keys(errors).length === 0 && isSubmit) {
            dispatch(setRolesDetails(data.rolesDetails))
            dispatch(setPartnerName(data.partnerName))
            dispatch(setEmail(data.email))
            dispatch(setMobileNumber(data.mobileNumber))
            dispatch(setCompanyName(data.companyName))
            dispatch(setwebsite(data.website))
            dispatch(partner(data))
            setIsSuccess(true)
            setData({
                rolesDetails: '',
                partnerName: '',
                email: '',
                mobileNumber: '',
                companyName: '',
                website: '',
            })
            setTimeout(() => {
                setIsSuccess(false)
            }, 2000)
        }
    }, [errors, isSubmit])

    return (
        <div className="contactUs">
            <Box className='ContactBoxPF'
                sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    color: 'black',
                    fontSize: '0.875rem',
                    fontWeight: '700',
                    height: 'auto',
                    width: '38%',
                }}
            >
                <Typography
                    variant="h6"
                    className='BePartnerHead'
                    sx={{
                        fontWeight: {xs:'600', sm: '600', md: '600', lg: '700' },
                        lineHeight: {xs:'20px', sm: '20px', md: '20px', lg: '42px' },
                        fontSize: {xs:'20px', sm: '20px', md: '25px', lg: '30px' },
                        color: "#585858",
                    }}
                >
                    Become a partner
                </Typography>
                <Typography
                    variant="h6"
                    sx={{
                        width:'85%',
                        fontWeight: {xs:'400', sm: '400', md: '400', lg: '400' },
                        lineHeight: {xs:'16px', sm: '17px', md: '18px', lg: '30px' },
                        fontSize: {xs:'12px', sm: '15px', md: '15px', lg: '20px' },
                        color: '#585858',
                        pt: '2%',
                    }}
                >
                    Are you looking to expand your reach and tap into new
                    markets? Partnering with us is the perfect opportunity to do
                    so.
                </Typography>
            </Box>
            {isSuccess ?
                <form className='contactUsForm' action="">
                    <Typography variant='h4' sx={{ fontWeight: '600', fontSize: '30px', opacity: '50%', textAlign: 'center' }}>Thank You!</Typography>
                    <Typography variant='p' sx={{ fontWeight: '300', opacity: '50%', textAlign: 'center' }}>We’ve recieved your message and
                        will get back to you ASAP!</Typography>
                    <img src={RegSuccess} alt="successgif" className='success-gif' />
                </form> :




                <form className="contactUsForm" onSubmit={handleSubmit}>
                    <Typography
                        variant="h5"
                        sx={{
                            fontWeight: '700',
                            textAlign: 'left',
                            color: '#16469D',
                        }}
                    >
                        Partnership Application
                    </Typography>
                    <RadioGroup
                        row
                        required 
                        aria-labelledby="demo-row-radio-buttons-group-label"
                        name="row-radio-buttons-group"
                        value={data.rolesDetails} // Use 'value' prop instead of 'checked'
                        onChange={(e) => {
                            handleChange(e, 'rolesDetails');
                            setRadioError(false);
                        }}
                    >
                        <FormControlLabel
                            value="PARTNER"
                            component="fieldset"
                    error={radioError}
                           
                            checked={data.rolesDetails === 'PARTNER'}
                            control={
                                <Radio
                                    sx={{
                                        color: '#16469D',
                                        '&.Mui-checked': {
                                            color: '#16469D',
                                        },
                                    }}
                                />
                            }
                            label="Partner" sx={{color:'#585858'}}
                        />
                        <FormControlLabel
                            value="FRANCHISE"
                            checked={data.rolesDetails === 'FRANCHISE'}
                            control={
                                <Radio
                                    sx={{
                                        color: '#16469D',
                                        '&.Mui-checked': {
                                            color: '#16469D',
                                        },
                                    }}
                                />
                            }
                            label="Franchise" sx={{color:'#585858'}}
                        />
                        {/* <FormLabel
                        component="legend"
                        sx={{ color: radioError ? 'red' : '#585858' }}
                    >
                        Select Role
                    </FormLabel> */}
                        <FormControlLabel
                            value="INVESTOR"
                            checked={data.rolesDetails === 'INVESTOR'}
                            control={
                                <Radio
                                    sx={{
                                        color: '#16469D',
                                        '&.Mui-checked': {
                                            color: '#16469D',
                                        },
                                    }}
                                />
                            }
                            label="Investor" sx={{color:'#585858'}}
                        />
                    </RadioGroup>
                    {radioError && (
                        <Typography variant="body2" color="red" style={{display: 'flex'}}>
                            Please select a role.
                        </Typography>
                    )}
                    <label
                        htmlFor=""
                        style={{
                            textAlign: 'left',
                            opacity: '0.5',
                            marginTop: '3%',
                            fontSize: errors.partnerName ? '12px' : '15px',
                            color: errors.partnerName ? 'red' : 'black',
                        }}
                    >
                        {errors.partnerName ? errors.partnerName : 'Full name'}
                    </label>
                    <TextField
                        id="outlined-required"
                        size="small"
                        value={data.partnerName}
                        onChange={(e) => handleChange(e, 'partnerName')}
                        sx={{
                            backgroundColor: '#F5F5F5CC',
                            borderRadius: '10px',
                            fieldset: {
                                borderColor: errors.partnerName
                                    ? 'red'
                                    : 'rgba(195, 195, 195, 1)',
                            },
                        }}
                    >
                        {' '}
                    </TextField>
                    <div className='partnerFull'
                        style={{
                            display: 'flex',
                            marginTop: '3%',
                            justifyContent: 'space-between',
                        }}
                    >
                        <div className='partnerFull'
                            style={{
                                display: 'flex',
                                flexDirection: 'column',
                                width: '45%',
                            }}
                        >
                            <label
                                htmlFor=""
                                style={{
                                    textAlign: 'left',
                                    opacity: '0.5',
                                    fontSize: errors.email ? '12px' : '15px',
                                    color: errors.email ? 'red' : 'black',
                                }}
                            >
                                {errors.email ? errors.email : 'Email'}
                            </label>
                            <TextField

                                size="small"
                                id="outlined-required"
                                value={data.email}
                                onChange={(e) => handleChange(e, 'email')}
                                sx={{
                                    backgroundColor: '#F5F5F5CC',
                                    borderRadius: '10px',
                                    fieldset: {
                                        borderColor: errors.email
                                            ? 'red'
                                            : 'rgba(195, 195, 195, 1)',
                                    },
                                }}
                            >
                                {' '}
                            </TextField>
                        </div>
                        <div className='partnerFull'
                            style={{
                                display: 'flex',
                                flexDirection: 'column',
                                width: '45%',
                            }}
                        >
                            <label
                                htmlFor=""
                                style={{
                                    textAlign: 'left',
                                    opacity: '0.5',
                                    fontSize: errors.mobileNumber ? '12px' : '15px',
                                    color: errors.mobileNumber ? 'red' : 'black',
                                }}
                            >
                                {errors.mobileNumber
                                    ? errors.mobileNumber
                                    : 'Contact No.'}
                            </label>
                            <TextField
                                size="small"
                                id="outlined-required"
                                value={data.mobileNumber}
                                onChange={(e) => handleChange(e, 'mobileNumber')}
                                sx={{
                                    backgroundColor: '#F5F5F5CC',
                                    borderRadius: '10px',
                                    fieldset: {
                                        borderColor: errors.mobileNumber
                                            ? 'red'
                                            : 'rgba(195, 195, 195, 1)',
                                    },
                                }}
                            >
                                {' '}
                            </TextField>
                        </div>
                    </div>
                    <div className='partnerFull Partnerfull2'
                        style={{
                            display: 'flex',
                            marginTop: '3%',
                            justifyContent: 'space-between',
                        }}
                    >
                        <div className='partnerFull '
                            style={{
                                display: 'flex',
                                flexDirection: 'column',
                                width: '45%',
                            }}
                        >
                            <label
                                htmlFor=""
                                style={{
                                    textAlign: 'left',
                                    opacity: '0.5',
                                    fontSize: errors.companyName ? '12px' : '15px',
                                    color: errors.companyName ? 'red' : 'black',
                                }}
                            >
                                {errors.companyName
                                    ? errors.companyName
                                    : 'Company Name'}
                            </label>
                            <TextField
                                size="small"
                                id="outlined-required"
                                value={data.companyName}
                                onChange={(e) => handleChange(e, 'companyName')}
                                sx={{
                                    backgroundColor: '#F5F5F5CC',
                                    borderRadius: '10px',
                                    fieldset: {
                                        borderColor: errors.companyName
                                            ? 'red'
                                            : 'rgba(195, 195, 195, 1)',
                                    },
                                }}
                            >
                                {' '}
                            </TextField>
                        </div>
                        <div className='partnerFull'
                            style={{
                                display: 'flex',
                                flexDirection: 'column',
                                width: '45%',
                            }}
                        >
                            <label
                                htmlFor=""
                                style={{
                                    textAlign: 'left',
                                    opacity: '0.5',
                                    fontSize: errors.website
                                        ? '12px'
                                        : '15px',
                                    color: errors.website ? 'red' : 'black',
                                }}
                            >
                                {errors.website
                                    ? errors.website
                                    : 'Company Website'}
                            </label>
                            <TextField
                                size="small"
                                id="outlined-required"
                                value={data.website}
                                onChange={(e) => handleChange(e, 'website')}
                                sx={{
                                    backgroundColor: '#F5F5F5CC',
                                    borderRadius: '10px',
                                    fieldset: {
                                        borderColor: errors.website
                                            ? 'red'
                                            : 'rgba(195, 195, 195, 1)',
                                    },
                                }}
                            >
                                {' '}
                            </TextField>
                        </div>
                    </div>
                    <Button
                        className="contactUsBtn"
                        type="submit"
                        variant="contained"
                        style={{
                            textTransform: 'capitalize',
                            marginTop: '6%',
                            fontSize: '16px',
                            color: '#ffffff',
                            backgroundColor:'#16469D',
                            '& :hover': { color: '#16469D' },
                        }}
                    >
                        Submit
                    </Button>
                </form>}
        </div>
    )
}

export default PartnerForm
