import { Grid, Box, Button } from '@mui/material'
import React from 'react'
import { useState, useEffect } from 'react';
import '../OurProduct/scss/OurProduct.css'
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import Typography from '@mui/material/Typography';
import { CardActionArea } from '@mui/material';
import Vector from '../../assets/images/Vector.svg'
import axios from 'axios';
import { Link } from 'react-router-dom';
import { isLoggedIn } from 'auth';
import { Image } from 'cloudinary-react'
import Slider from 'react-slick';
import ArrowForwardIosOutlinedIcon from '@mui/icons-material/ArrowForwardIosOutlined';

const OurProduct = () => {
    var settings = {
        dots: true,
        infinite: true,
        className: "center",
        centerMode: true,
        speed: 500,
        slidesToShow: 3,
        slidesToScroll: 1,
        initialSlide: 0,
        arrows: false,
        margin: 4,
        autoplay: true,
        autoplaySpeed: 5000,
        pauseOnHover: true,

        responsive: [
            {
                breakpoint: 1024,
                settings: {
                    slidesToShow: 3,
                    slidesToScroll: 3,
                    infinite: true,
                    dots: true
                }
            },
            {
                breakpoint: 600,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 2,
                    initialSlide: 2
                }
            },
            {
                breakpoint: 480,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1
                }
            }
        ]
    };

    const [data, setData] = useState([])

    const getPortalData = async () => {
        try {
            const res = await axios.get(
                `${process.env.REACT_APP_STEELADMIN}/mainportal/getAllPortals`
            )
            setData(res.data)
        } catch (error) {
            // console.log(error)
        }
    }


    useEffect(() => {
        getPortalData()
    }, [])

    let count = 1
    data.forEach((card) => {
        card.count = count
        count = count + 1
    })


    return (
        <div className="ourProd">
            <div className='OurProd2'>
                <Box className='productStart'>
                    <Typography
                        variant="h4"
                        sx={{
                            display: 'flex',
                            alignItems: 'center',
                            fontWeight: '700',
                            fontSize: '30px',
                            color: '#585858',
                            lineHeight: '23px'
                        }}
                    >
                        Our Products
                    </Typography>
                    <Typography
                        className='OurProductSub'
                        variant="h6"
                        color=" #585858"
                        sx={{
                            display: 'flex',
                            alignItems: 'center',
                            fontWeight: '400',
                            fontSize: '15px',
                            lineHeight: '23px',
                            mt: '0.800rem',
                            color: '#585858',

                            textAlign:'justify',

                            width: '100%',
                            maxWidth:"900px"

                        }}
                    >
                        Welcome to our secure and user-friendly website, where we showcase our comprehensive range of high-quality steel products and services, ensuring consistent quality and performance. Namaste 🙏
                    </Typography>
                </Box>
                <div style={{width:"100%",display:"flex",justifyContent:"center"}}>

                
                <Box className='Cardmain'>
                    {/* <Grid
                        container
                        spacing={{ xs: 4, sm: 4, md: 4, lg: 4, xl: 3 }}
                        sx={{
                            display: 'flex',
                            flexDirection: {
                                sm: 'column',
                                md: 'row',
                                alignItems: 'center',
                                justifyContent: 'center',
                            },
                        }}
                    > */}

                        {data.slice(0, 8).map(
                            (card, index) =>
                            (
                                // <Grid item sx={{}}>
                                    <Link
                                        style={{ textDecoration: 'none' }}
                                        to={isLoggedIn() ? card.portal.portalLink ? card.portal.portalLink : '/coming-soon' : '/Signin'}
                                        target='blank'
                                        className={index===7?'lastItemCard':""}
                                    >
                                        <Card className='CardStart'
                                            sx={{
                                                // width: '290px',
                                                // height: '210px',
                                                width: '100%',
                                                height: '100%',
                                                boxShadow:'none',
                                                background: `none, url(${`${process.env.REACT_APP_STEELADMIN}/mainportal/file/${card.portalImageName}`})`,
                                                // backgroundSize: 'fill',
                                                backgroundRepeat: 'no-repeat',
                                                backgroundPosition: 'center',
                                                backgroundSize: 'cover',
                                                // height:'100vh',
                                            }}
                                        >
                                            <CardActionArea className="cardMedia">
                                                <CardContent sx={{ mt: '30%' }}>
                                                    <Typography
                                                        gutterBottom
                                                        variant="h6"
                                                        component="div"
                                                        sx={{
                                                            color: '#ffffff',
                                                            fontWeight: '450',
                                                            fontSize: 'clamp(16px,17px,19px)',
                                                            textShadow: '12px 12px 14px rgba(0, 0, 0, 1.9)', // Add this line for drop shadow
                                                        
                                                        }}
                                                    >
                                                        {card.portal.portalName}
                                                    </Typography>
                                                    <Typography
                                                        variant="body3"
                                                        sx={{
                                                            color: '#ffffff',
                                                            fontWeight: '270',
                                                            fontSize: '12',
                                                            textShadow: '12px 12px 14px rgba(0, 0, 0, 1.9)', // Add this line for drop shadow
                                                        }}
                                                    >
                                                        {card.portal.portalDescription.substr(
                                                            0,
                                                            25
                                                        ) + '...'}
                                                    </Typography>
                                                </CardContent>
                                            </CardActionArea>
                                        </Card>
                                        {
                                            index===7 && 
                                        
                                        <Link to="/products" className="TargetCardBtn22" style={{ textDecoration: 'none',textTransform:'capitalize' }}>
                                            <Button variant="contained" className="productCardBtn22"
                                            >
                                                Explore More
                                            </Button>
                                        </Link>
                                        }
                                    </Link>

                                // </Grid>
                            )

                        )}
                    {/* </Grid> */}

                </Box>
                </div>
            </div>



            <Box className='Cardmain2'>
                <Box className='cardHeader'>
                    <Typography variant='h1'
                     sx={{

                        fontSize: { xs: '10px', sm: '20px', md: '20px', lg: '30px' },
                        fontWeight: { xs: '400', sm: '400', md: '500', lg: '700' },
                        lineHeight: { xs: '20px', sm: '23px', md: '23px', lg: '23px' },
                    }}
                    >Our Products</Typography>
                    <Link to="/products" className='BtnLink' style={{ textDecoration: 'none' }}>
                        <ArrowForwardIosOutlinedIcon className='CardIcon' />
                    </Link>
                </Box>
                <Slider {...settings}>
                    {data.map(
                        (card) =>
                            card.count <= 8 ? (
                                <Link
                                    style={{ textDecoration: 'none' }}
                                    to={isLoggedIn() ? card.portal.portalLink ? card.portalportalLink : '/coming-soon' : '/Signin'}
                                    target='blank'
                                    className='card1234'
                                >
                                    <Card className='CardStart2'
                                        sx={{
                                            width: '85%',
                                            height: '30vh',
                                            background: `linear-gradient(to top, rgba(0, 0, 0, 1),rgba(245, 172, 56, 0)), url(${`${process.env.REACT_APP_STEELADMIN}/mainportal/file/${card.portalImageName}`})`,
                                       
                                            backgroundRepeat: 'no-repeat',
                                            backgroundPosition: 'center',
                                            backgroundSize: 'cover',

                                        }}
                                    >
                                        <CardActionArea className="cardMedia2">
                                            <CardContent >
                                                <Typography
                                                    gutterBottom
                                                    variant="h6"
                                                    component="div"
                                                    sx={{
                                                        color: '#ffffff',
                                                        fontWeight: '450',
                                                        fontSize: '20',
                                                    }}
                                                >
                                                    {card.portal.portalName}
                                                </Typography>
                                                <Typography
                                                    variant="body3"
                                                    sx={{
                                                        color: '#ffffff',
                                                        fontWeight: '270',
                                                        fontSize: '12',
                                                    }}
                                                >
                                                    {card.portal.portalDescription.substr(
                                                        0,
                                                        25
                                                    ) + '...'}
                                                </Typography>
                                            </CardContent>
                                        </CardActionArea>
                                    </Card>
                                </Link>
                            ) : null

                    )}
                </Slider>
            </Box>
        </div>
    )

}

export default OurProduct