import { createSlice } from "@reduxjs/toolkit";

const partnerSlice = createSlice({
    name: 'partner',
    initialState: {
        rolesDetails: '',
        partnerName: '',
        email: '',
        mobileNumber: '',
        companyName: '',
        website: '',
        loading: false,
        error: null,
        success: false,
    },
    reducers: {
        setRolesDetails: (state, action) => {
            state.rolesDetails = action.payload
        },
        setPartnerName: (state, action) => {
            state.partnerName = action.payload
        },
        setEmail: (state, action) => {
            state.email = action.payload
        },
        setMobileNumber: (state, action) => {
            state.mobileNumber = action.payload
        },
        setCompanyName: (state, action) => {
            state.companyName = action.payload
        },
        setwebsite: (state, action) => {
            state.website = action.payload
        },
        setLoading: (state, action) => {
            state.loading = action.payload
        },
        setError: (state, action) => {
            state.error = action.payload
        },
        setSuccess: (state, action) => {
            state.success = action.payload
        },
    }
})

export const {
    setPartnerName,
    setEmail,
    setMobileNumber,
    setCompanyName,
    setwebsite,
    setRolesDetails,
    setLoading,
    setError,
    setSuccess,
} = partnerSlice.actions

export default partnerSlice.reducer