import React from "react";
import NavBar from "components/NavBar/NavBar";
import Footer from "components/Footer/Footer";
import ProductsLandingPage from "components/ProductsLandingPage/ProductsLandingPage";
import { Image } from 'cloudinary-react'
import { Helmet } from "react-helmet";
const ProductPage = () => {
    return (
        <>
          <Helmet>
                <html lang="en" />
                <title>Knowing Steel | Conceiving Steel Plant | Steel plant digital transformation</title>
                <meta name="description" content="KJS Steel is a leading Steel Plant EPC Services. Our team specializes in conceiving Steel Plant Projects executing them in a cost effective and timely manner." />
                <meta name="tite" content="- Knowing Steel | Conceiving Steel Plant | Steel plant digital transformation" />
                <meta name="keywords" content="- Knowing Steel | Conceiving Steel Plant | Steel plant digital transformation" />

            </Helmet>
        <div>
            <NavBar />
            <ProductsLandingPage/>
            <Footer />

            <div style={{position: 'fixed', bottom: '2rem', right: '2rem', zIndex: '999', backgroundImage: 'none', background: 'none', boxSizing: 'none', borderStyle: 'none',cursor:'pointer'}}>
                <a href="https://api.whatsapp.com/send?phone=9321714104&text=I%20am%20interested!" target="_blank">
                    <Image style={{height: '7vh', width: '7vh',cursor:'pointer',}} publicId="https://res.cloudinary.com/dxluokypg/image/upload/v1680763106/whatsapp_gqgneb.png" cloudName="dxluokypg" />
                </a>
            </div>
        </div>
        </>
    )
};

export default ProductPage;