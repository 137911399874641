import Error from 'components/ErrorPage/Error'
import Footer from 'components/Footer/Footer'
import NavBar from 'components/NavBar/NavBar'
import React from 'react'

const ErrorLanding = () => {
  return (
    <div>
        <NavBar/>
        <Error/>
        <Footer/>
    </div>
  )
}

export default ErrorLanding