import ComingSoon from 'components/ComingSoon/ComingSoon'
import Footer from 'components/Footer/Footer'
import NavBar from 'components/NavBar/NavBar'
import React from 'react'
import { Image } from 'cloudinary-react'

const ComingSoonLandingPg = () => {
  return (
    <div>
        <NavBar/>
        <ComingSoon/>
        <Footer/>

        <div style={{position: 'fixed', bottom: '2rem', right: '2rem', zIndex: '999', backgroundImage: 'none', background: 'none', boxSizing: 'none', borderStyle: 'none',cursor:'pointer'}}>
                <a href="https://api.whatsapp.com/send?phone=9321714104&text=I%20am%20interested!" target="_blank">
                    <Image style={{height: '7vh', width: '7vh',cursor:'pointer',}} publicId="https://res.cloudinary.com/dxluokypg/image/upload/v1680763106/whatsapp_gqgneb.png" cloudName="dxluokypg" />
                </a>
            </div>
    </div>
  )
}

export default ComingSoonLandingPg