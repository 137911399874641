import React from 'react'
import CallingImg from '../../assets/images/CallingImg.svg'
import MsgImg from '../../assets/images/MsgImg.svg'
import Locationimg from '../../assets/images/LocationImg.svg'
import Box from '@mui/material/Box'
import { Typography } from '@mui/material'
import { Image } from 'cloudinary-react'
function ContactUs2() {
    return (
        <div className="ContactUsMainPage" style={{ width: '100%' }}>
            <Box
                className="contactMain"
                sx={{
                    display: 'flex',
                    flexDirection: { xs: 'column', md: 'row', lg: 'row' },
                    alignItems: { xs: 'start', lg: 'start' },
                    ml: { lg: '5%' },
                }}
            >
                <Box
                    className="ContactUs"
                    sx={{
                        display: 'flex',
                        flexDirection: 'row',
                        width: { xs: '100%', md: '50%', lg: '22%' },
                        alignContent: 'start',
                        flexDirection: { xs: 'row', md: 'row', lg: 'row' },
                        alignItems: 'center',
                        mt: '5%',
                        // ml: { xs: '3%' },
                    }}
                >
                    <Image publicId="https://res.cloudinary.com/dxluokypg/image/upload/v1680584526/CallingImg_sp8eit.svg" cloudName="dxluokypg" />
                    <div style={{ marginLeft: '3%' }}>
                        <Typography
                            variant="h5"
                            sx={{
                                color: '#656363',
                                fontWeight: '400',
                                fontSize: '20px',
                                lineHeight: '20px',
                            }}
                        >
                            Phone
                        </Typography>
                        <Typography
                            variant="p"
                            className="Typo"
                            sx={{
                                mt: { lg: '20%' },
                                color: '#F5AC38',
                                fontWeight:'590',
                                
                            }}
                        >
                              {/* <a href="https://api.whatsapp.com/send?phone=9321714104&text=I%20am%20interested!" target="_blank">
                    <Image style={{height: '8.26vh', width: '8.26vh',cursor:'pointer',}} publicId="https://res.cloudinary.com/dxluokypg/image/upload/v1680763106/whatsapp_gqgneb.png" cloudName="dxluokypg" />
                </a> */}
                        <a href="https://api.whatsapp.com/send?phone=9321714104&text=I%20am%20interested!" target="_blank" style={{color:'#F5AC38',textDecoration:'none'}}>93217 14104</a>

                            
                        </Typography>
                    </div>
                </Box>

                <Box
                    className="ContactUs"
                    sx={{
                        display: 'flex',
                        flexDirection: 'row',
                        // ml: { xs: '3%' },
                        alignContent: 'start',
                        width: { xs: '100%', md: '50%', lg: '22%' },
                        flexDirection: { xs: 'row', md: 'row', lg: 'row' },
                        alignItems: 'center',
                        mt: '5%',
                    }}
                >
                    <Image publicId="https://res.cloudinary.com/dxluokypg/image/upload/v1680584526/MsgImg_hslvvr.svg" cloudName="dxluokypg" />
                    <div style={{ marginLeft: '3%' }}>
                        <Typography
                            variant="h5"
                            sx={{
                                color: '#656363',
                                fontWeight: '400',
                                fontSize: '20px',
                                lineHeight: '20px',
                            }}
                        >
                            Email
                        </Typography>
                        <Typography
                            className="Typo"
                            sx={{
                                color: '#F5AC38',
                                textDecoration:'none',
                            }}
                        >
                            <a href='/coming-soon' style={{color:'#F5AC38',textDecoration:'none'}}>info@kjssteel.com</a>
                        </Typography>
                    </div>
                </Box>

                <Box
                    className="ContactUs"
                    sx={{
                        display: 'flex',
                        flexDirection: 'row',
                        ml: { xs: '3%' },
                        alignContent: 'start',
                        width: { xs: '100%', md: '50%', lg: '22%' },
                        flexDirection: { xs: 'row', md: 'row', lg: 'row' },
                        alignItems: 'center',
                        mt: '5%',
                    }}
                >
                    <Image publicId="https://res.cloudinary.com/dxluokypg/image/upload/v1680858838/ContactUsIcon_mk3uog.svg" cloudName="dxluokypg" />
                    <div
                        style={{ marginLeft: '3%' }}
                    >
                        <Typography
                            variant="h5"
                            sx={{
                                color: '#656363',
                                fontWeight: '400',
                                fontSize: '20px',
                                lineHeight: '20px',
                            }}
                        >
                            Website
                        </Typography>
                        <Typography
                            className="Typo"
                            sx={{
                                color: '#F5AC38',
                            }}
                        >
                            <a href='https://www.kjsscosmos.com/' style={{color:'#F5AC38',textDecoration:'none'}}>www.kjsscosmos.com</a>

                        </Typography>
                    </div>
                </Box>
                <Box
                    className="ContactUs"
                    sx={{
                        display: 'flex',
                        flexDirection: { xs: 'row', md: 'row', lg: 'row' },
                        width: { xs: '100%', md: '50%', lg: '24%' },
                        // ml: { sm: '3%' },
                        mt: '5%',
                    }}
                >
                    <Image style={{ marginBottom: '20%' }} publicId="https://res.cloudinary.com/dxluokypg/image/upload/v1680584526/LocationImg_r0zz7l.svg" cloudName="dxluokypg" />
                    <div>
                        <Typography
                            variant="h5"
                            sx={{
                                color: '#656363',
                                fontWeight: '400',
                                ml: { xs: '3%' },
                                fontSize: '20px',
                            }}
                        >
                            Visit us
                        </Typography>
                        <Typography
                            className="Typo"
                            sx={{
                                ml: { xs: '3%' },
                                mr: { xs: '20%' },
                                color: '#F5AC38',
                                ineHeight: '20px',
                                width: '100%'
                            }}
                        >
                            {/* <iframe style={{color:'#F5AC38',textDecoration:'none'}} src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3452.7494723869836!2d73.07086202565564!3d19.044358144987267!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3be7c32a15325b2b%3A0x9745134ed2c77a99!2sKJSS%20COSMOS%20PVT%20LTD!5e0!3m2!1sen!2sin!4v1681365270479!5m2!1sen!2sin"   allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe> */}
                            <a href='https://goo.gl/maps/juRs1jBZ6gaZiXxu8' style={{color:'#F5AC38',textDecoration:'none'}}>Head office: Kamdhenu Commerz, 401, 4TH floor, Sector 14, Kharghar, Navi Mumbai, Maharashtra 410210</a>
                        </Typography>
                    </div>
                </Box>
            </Box>
        </div>
    )
}

export default ContactUs2
