import AboutUs from "components/AboutUs/AboutUs"
import Banner from "components/Banner/Banner"
import FaqRevamp from "components/FAQ/FAQRevamp"
import Footer from "components/Footer/Footer"
import NavBar from "components/NavBar/NavBar"
import NewTestimonial from "components/NewTestimonial/NewTestimonial"
import OurProduct from "components/OurProduct/OurProduct"
import OurProject from "components/OurProject/OurProject"
import { Image } from 'cloudinary-react'
import { Helmet } from "react-helmet";
const MainPage = () => {
    return (
        <>
          <Helmet>
                <html lang="en" />
                <title>Steel Plant Engineering Consultants | EPC Services Company | Steel Industry Portal | kjs Steel | kjssteel</title>
                <meta name="description" content="KJS Steel is one of the leading steel plant engineering consultants in India offering EPC services as well as turnkey solutions." />
                <meta name="title" content="Steel Plant Engineering Consultants | EPC Services Company | Steel Industry Portal | kjs Steel | kjssteel" />
                <meta name="keywords" content="Steel Plant Engineering Consultants | EPC Services Company | Steel Industry Portal | kjs Steel | kjssteel" />

            </Helmet>
        <div>
            <NavBar />
            <Banner />
            <AboutUs />
            <OurProduct />
            <OurProject />
            <NewTestimonial/>
            <FaqRevamp />
            <Footer />   

            <div style={{position: 'fixed', bottom: '2rem', right: '2rem', zIndex: '999', backgroundImage: 'none', background: 'none', boxSizing: 'none', borderStyle: 'none',cursor:'pointer'}}>
                <a href="https://api.whatsapp.com/send?phone=9321714104&text=I%20am%20interested!&text=I%20am%20interested!" target="_blank">
                    <Image style={{height: '7vh', width: '7vh',cursor:'pointer',}} publicId="https://res.cloudinary.com/dxluokypg/image/upload/v1680763106/whatsapp_gqgneb.png" cloudName="dxluokypg" />
                </a>
            </div>
        </div>
        </>
    )
}

export default MainPage