import * as React from 'react';
import { styled, alpha } from '@mui/material/styles';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import Menu from '@mui/material/Menu';
import MenuIcon from '@mui/icons-material/Menu';
import Container from '@mui/material/Container';
import Button from '@mui/material/Button';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import EditIcon from '@mui/icons-material/Edit';
import Divider from '@mui/material/Divider';
import ArchiveIcon from '@mui/icons-material/Archive';
import FileCopyIcon from '@mui/icons-material/FileCopy';
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import { useState, useEffect } from 'react';
import { Link, NavLink, useNavigate } from 'react-router-dom'
import { isLoggedIn, getCurrentUserDetail, doLogout } from 'auth';
import UserIcon from '../../assets/images/UserIcon.svg'
import '../NavBar/scss/NavBar.css'
import axios from 'axios';
import { Image } from 'cloudinary-react';
import { setEmail } from 'components/Register/store/dataSlice';
import { useSelector } from 'react-redux';
import { es } from 'date-fns/locale';
import Popover from '@mui/material/Popover';



// const pages = ['Home', 'About-Us', 'Products', 'Projects', 'Partner with us', 'Contact us', 'Sign Up', 'Sign In',];
const pages=[
  {
    name:'Home',
    link:"/home"
  },
  {
    name:'About Us',
    link:"/about-us"
  },
  {
    name:'Products',
    link:"/products"
  },
  {
    name:'Projects',
    link:"/projects"
  },
  {
    name:'Partner With Us',
    link:"/partner-with-us"
  },
  {
    name:'Contact Us',
    link:"/contact-us"
  },
  {
    name:'Sign Up',
    link:"/become-a-buyer"
  },
  {
    name:'Sign In',
    link:"/signin"
  },
]

const StyledMenu = styled((props) => (
  <Menu
    elevation={0}
    anchorOrigin={{
      vertical: 'bottom',
      horizontal: 'right',
    }}
    transformOrigin={{
      vertical: 'top',
      horizontal: 'right',
    }}
    {...props}
  />
))(({ theme }) => ({
  '& .MuiPaper-root': {
    borderRadius: 6,
    marginTop: theme.spacing(1),
    minWidth: 180, 
    width: '13%', 
    color:
      theme.palette.mode === 'light' ? 'rgb(55, 65, 81)' : theme.palette.grey[300],
    boxShadow:
      'rgb(255, 255, 255) 0px 0px 0px 0px, rgba(0, 0, 0, 0.05) 0px 0px 0px 1px, rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px',
    '& .MuiMenu-list': {
      padding: '4px 0',
    },
    '& .MuiMenuItem-root': {
      '& .MuiSvgIcon-root': {
        fontSize: '14px',
        color: theme.palette.text.secondary,
        marginRight: theme.spacing(1.5),
      },
      '&:active': {
        backgroundColor: alpha(
          theme.palette.primary.main,
          theme.palette.action.selectedOpacity,
        ),
      },
    },
  },
}));

function NavBar() {
  const [signUpMenuAnchor, setSignUpMenuAnchor] = useState(null);
  const [isSignUpMenuOpen, setIsSignUpMenuOpen] = useState(false); // New state

  
  const handleSignUpButtonClick = () => {
    console.log('Sign Up button clicked'); // Add this line
    setIsSignUpMenuOpen(!isSignUpMenuOpen);
  };
  




  const [anchorEl, setAnchorEl] = React.useState(null);

  
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const [redirectLink, setRedirectLink] = useState("")
  const [anchorElNav, setAnchorElNav] = React.useState(null);
  const [anchorElUser, setAnchorElUser] = React.useState(null);
  const [token, setToken] = useState(null);
  useEffect(() => {
    try {
      const userDetail = JSON.parse(localStorage.getItem("data"))
      setToken(userDetail.accessToken);
    }
    catch (er) {
      // console.log("error parsing the localstate token")
    }

  }, [])


  const handleOpenNavMenu = (event) => {
    setAnchorElNav(event.currentTarget);
  };
  const handleOpenUserMenu = (event) => {
    setAnchorElUser(event.currentTarget);
  };

  const handleCloseNavMenu = () => {
    setAnchorElNav(null);
  };

  const handleCloseUserMenu = () => {
    setAnchorElUser(null);
  };


  const navigate = useNavigate()
  const [login, setLogin] = useState(false)
  const [user, setUser] = useState(undefined)
  const [email, setEmail] = useState(undefined)
  const [isVendorLogedIn, setIsVendorLogedIn] = useState(false);

  // const email = useSelector((state) => state.registration.email);

  useEffect(() => {
    setIsVendorLogedIn(false);
    setLogin(isLoggedIn())
    const data = JSON.parse(localStorage.getItem('data'))
    setUser(data?.name || data?.userDetail?.name)

    if (data?.userDetail !== null) {

      setEmail(data?.userDetail?.email)
    }
    else if (data?.vendorDetails !== null) {

      setEmail(data?.vendorDetails?.email)
    }
    else {
      setEmail(data?.corporateDetails?.email)
    }

    if (data?.vendorDetails?.roles?.name === "Vendor") {
      setIsVendorLogedIn(true);
    }
    // console.log(data.userDetail);
  }, [login])




  const getRedirectData = async () => {
    try {
      const res = await axios.post(`${process.env.REACT_APP_STEELADMIN}/api/auth/redirectLinkAdminKjssteel`, { token: token }).then((res) => {

        console.log("my Profile",res.data)
        let jsonuserDetails = null

        if (res.data?.data?.userType?.name === "User" || res.data?.data?.userType?.name === "SuperAdmin") {
          jsonuserDetails = JSON.stringify(res.data?.userDetails)


        }
        else if (res.data?.data?.userType.name === "Vendor") {
          jsonuserDetails = JSON.stringify(res.data?.vendorDetails)
        }
        else {
          jsonuserDetails = JSON.stringify(res.data?.corporateDetails)
        }


        setRedirectLink(`${res.data?.redirectLink}&userDetails=${jsonuserDetails}`);
      })
    } catch (error) {
      // console.log(error)
    }
  }



  useEffect(() => {
    if(token!=="" && token!==undefined && token!==null)
    {

      getRedirectData();
    }
  }, [token])



  const logout = () => {
    // doLogout(() => {
    //   setLogin(false)
    //   navigate('/')
    // })
    // try {
    //   const response = await axios.post(`${process.env.REACT_APP_LOGOUT}/api/auth/logout`)
    doLogout(() => {
      setLogin(false)
      navigate('/')
    })
    // } catch (error) {
    //   alert('Could not logout')
    // }
  }

  const navLinkStyles = ({ isActive }) => {
    return {
      textDecoration: isActive ? 'none' : 'none',
      color: isActive ? '#16469D' : '#001926',
      fontSize: '14px',
      fontWeight: '500',
      lineHeight: '14px',
      '&:hover': { color: "#16469D" }
    }
  }
  const navLinkStylesMob = ({ isActive }) => {
    return {
      // width:'100px',
      // height:'auto',
      textDecoration: isActive ? 'none' : 'none',
      color: isActive ? '#16469D' : '#001926',

      fontSize: '40px',
      fontWeight: '500',
      lineHeight: '21px',
      textAlign: 'left',
      '&:hover': { color: "#16469D", }
    }
  }

  const signupButtonStyles = {
    // background: '#E9F1FF', // Customize the background color
    height: '42px',
    color: 'rgba(88, 88, 88, 1)',
    py: 2,
    // mr:'1%',
    // px:'2%',
    borderRadius: '3px',
    textTransform: 'capitalize',
    '&:hover': {
      backgroundColor: '#fff',
      color: 'rgba(88, 88, 88, 1)',
      py: 2,
      borderRadius: '7px'
      // mr:'1%', // Customize the hover background color
    },
  };

  return (
    <>
      <AppBar position="sticky" className='NavBarStartMain' sx={{ backgroundColor: 'white', boxShadow: '10px solid black', paddingTop: '0.8%', paddingBottom: '0.5%' }}>
        <Container maxWidth="xl">
          <Toolbar disableGutters>
            <NavLink to="/home">
              <Image style={{ marginLeft: '25%' }} publicId="https://res.cloudinary.com/dxluokypg/image/upload/v1682423845/steel_1_lgk5mz.svg" cloudName="dxluokypg" />
            </NavLink>
            <Box className='NavBarStartMain2' sx={{ flexGrow: 1, gap: '2%', display: { xs: 'flex', md: 'none' }, justifyContent: { xs: 'end' } }}>
              <IconButton
                size="large"
                aria-label="account of current user"
                aria-controls="menu-appbar"
                aria-haspopup="true"
                onClick={handleOpenNavMenu}
                color="black"
              >
                <MenuIcon />
              </IconButton>
              <Menu
                id="menu-appbar"
                anchorEl={anchorElNav}
                anchorOrigin={{
                  vertical: 'bottom',
                  horizontal: 'left',
                }}
                keepMounted
                transformOrigin={{
                  vertical: 'top',
                  horizontal: 'left',
                }}
                open={Boolean(anchorElNav)}
                onClose={handleCloseNavMenu}
                sx={{
                  display: { xs: 'block', md: 'none' },
                }}
              >
                {pages.map((page) => {
                  // console.log(page)
                  //mobile link

                  return (
                    <NavLink style={navLinkStylesMob} 
                    to={page.link}
                    >
                      <MenuItem key={page.name} onClick={handleCloseNavMenu}   >
                        <Typography variant='h6' textAlign="center" sx={{ width: '400px', textAlign: 'left' }}>{page.name}</Typography>
                      </MenuItem>
                    </NavLink>
                  )



                })}
              </Menu>
            </Box>
            <Box sx={{ flexGrow: 1, display: { xs: 'none', md: 'flex', }, justifyContent: { xs: 'end' }, marginRight: '5%', alignItems: 'center' }}>
              {pages.map((page,index) => (
                page.name === 'Sign In' ?
                  (login && <div style={{ display: 'flex', alignItems: 'center', cursor: 'pointer', ml: '1%', }}><img src={UserIcon} alt="usericon" />&nbsp;
                    <Button
                      id="demo-customized-button"
                      aria-controls={open ? 'demo-customized-menu' : undefined}
                      aria-haspopup="true"
                      aria-expanded={open ? 'true' : undefined}
                      variant="text"
                      disableElevation
                      onClick={handleClick}
                      endIcon={<KeyboardArrowDownIcon />}
                      sx={{ color: '#585858', textTransform: 'capitalize', '&:hover': { background: 'none' } }}
                      disableRipple
                    >
                      Hi, {user}
                    </Button>
                    <StyledMenu
                      // style={{
                      //   padding
                      // }}
                      id="demo-customized-menu"
                      MenuListProps={{
                        'aria-labelledby': 'demo-customized-button',
                      }}
                      sx={{
                        padding: '6%'
                      }}
                      anchorEl={anchorEl}
                      open={open}
                      onClose={handleClose}
                    >
                      {/* <Box > */}
                      <Typography variant="h3" sx={{ fontSize: '12px', ml: '2%', mt: '2%', p: '2% 2% 2% 4%' }}>
                        {user}
                      </Typography>
                      <Typography variant="h4" sx={{ fontSize: '12px', pl: '6%' }}>

                        {email}
                      </Typography>

                      <Link to={redirectLink} sx={{ textDecoration: 'none !important' }} target="_blank">
                        <MenuItem sx={{ mt: '2%', }}>
                          My Profile
                        </MenuItem>
                      </Link>
                      <MenuItem onClick={logout} disableRipple>
                        Logout
                      </MenuItem>
                    </StyledMenu></div>) ||
                  (!login && <NavLink style={{ textDecoration: 'none', color: 'white' }} to={page.link}>
                    <Button sx={{ background: '#16469D', height: '42px', color: 'white', px: 5, borderRadius: '3px', textTransform: 'capitalize', "&:hover": { backgroundColor: "#2458B7" } }}>
                      {page.name}
                    </Button>
                  </NavLink>
                  )
                  :
                  page.name === 'Sign Up' ?
                  !login &&

                    <NavLink className='dropDownLink' style={{ textDecoration: 'none', color: 'white', marginRight: '2%', marginLeft: '1%', padding: '0', color: 'black' }}
                     
                    >
                      <Button
                        key="Sign Up"
                        sx={signupButtonStyles}
                        onClick={handleSignUpButtonClick}
                      >
                        Sign Up
                        
                      </Button>
                      {isSignUpMenuOpen && (

                      <ul className='dropDownMenu'>
                      <Link to='/become-a-buyer'> Buyer</Link>
                        <Link to='/become-a-seller'> Seller </Link>
                        <Link to='/corporate'> Corporate</Link>
                      </ul>
                        )}
                    </NavLink>






                    // Add custom styling for "Become a seller" button

                    :
                    <NavLink style={navLinkStyles} 
                    to={page.link}
                    
                    >
                      <Typography
                        key={page.name}
                        onClick={handleCloseNavMenu}
                        sx={{ my: 2, mx: 3 }}
                      >
                        {page.name}
                      </Typography>
                    </NavLink>
              ))}
            </Box>

          </Toolbar>
        </Container>
      </AppBar>
    </>

  );
}
export default NavBar;
