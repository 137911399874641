import { Container, Typography } from '@mui/material'
import '../OurProjectsPageSection/scss/OurProjectsPageSection.css'
import Card from '@mui/material/Card'
import CardContent from '@mui/material/CardContent'
import CardMedia from '@mui/material/CardMedia'
import { useState, useEffect } from 'react'
import axios from 'axios'
import { useNavigate, Link } from 'react-router-dom'
import Box from '@mui/material/Box';



const OurProjectsPageSection = () => {

    const id = 5
    const navigate = useNavigate()

    const [data, setData] = useState([])


    const getProjectData = async () => {
        try {
            const res = await axios.get(
                `${process.env.REACT_APP_STEELADMIN}/project/getAllProjectDetails`
            )
            setData(res.data)
        } catch (error) {
            // console.log(error)
        }
    }

    useEffect(() => {
        getProjectData()
    }, [])

    // const projectDesc = () => {
    //     navigate(`/projects/${data?.projId}`, {state: {data: data}})
    // }

    return (
        <div className='OurProjMain'>

            <div className='OurProjectCardStart'>
                {data.map((card) => (

                    <Link
                        to={{pathname:`/projects/${card?.projHeading.replace(/\s+/g, '-').toLowerCase()}/${card?.projectId}`}}
                        // onClick={(e) => { e.preventDefault(); navigate(`/projects/${card?.projHeading.replace(/\s+/g, '-').toLowerCase()}`, { state: { card } }) }}
                        className='projectCard' >
                        <div className='cardImg'>
                            <img src={`${process.env.REACT_APP_STEELADMIN}/project/file/${card.projCover.newCoverImageName}`}
                                alt={`${card.projHeading} picture`} />
                        </div>




                        <div className='cardContent'>
                            <h2>
                                {card.projHeading}
                            </h2>
                            <p>
                                {card.projCategory}
                            </p>
                        </div>

                    </Link>

                ))}
            </div>

            {/* OurProjectsPageSection end */}
        </div>
    )
}

export default OurProjectsPageSection
