import React, { useEffect, useState } from 'react'
import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'
import Button from '@mui/material/Button'
import { Link } from 'react-router-dom'
import './Scss/FAQ.css'
import axios from 'axios'
import { margin } from '@mui/system'
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';

const faqData = [
    {
        faqQue: `What is KJSS Group's mission?`,
        faqAns: 'Knowing, Judging & Sourcing. Your success is our goal.',
    },
    {
        faqQue: 'When was KJSS Group founded?',
        faqAns: 'KJSS is founded by a group of well-experienced technocrats from the steel industry on 21st June 2022.',
    },
    {
        faqQue: 'How to place an order with KJSS?',
        faqAns: 'Through order placing page.',
    },
]

const FaqRevamp = () => {
    // const [data, setData] = useState([])

    // const getFaqData = async () => {
    //     try {
    //         const res = await axios.get(
    //             `${process.env.REACT_APP_FAQAPI}/faq/top-faq`
    //         )
    //         setData(res.data)
    //         // console.log(data)
    //     } catch (error) {
    //         // console.log(error)
    //     }
    // }

    // useEffect(() => {
    //     getFaqData()
    // }, [])

    // let count = 1
    // data.forEach((que) => {
    //     que.count = count
    //     count = count + 1
    // })
    const [selected, setSelected] = useState(null)

    const toggle = (i) => {
        if (selected === i) {
            return setSelected(null)
        }
        setSelected(i)
    }
    return (
        <div className="findyouranswers" >
            <Box className='findyouranswersStart'>
                <Box className='mobileview'>
                    <Typography
                        variant="h2"
                        sx={{
                            width: '100%',
                            display: 'flex',
                            fontSize: '30px',
                            lineHeight: '-0.43px',
                            color: '#585858',
                            fontWeight: '700',
                          
                            color: '#585858',
                        }}
                    >
                        Find the answers you need
                    </Typography>

                    <Box  className="AnsContact"
                        // sx={{
                        //     display: 'flex',
                        //     flexDirection: 'column',
                        //     fontSize: '0.875rem',
                        //     fontWeight: '700',
                        //     border: '1px solid #16469D',
                        //     borderRadius: '6px',
                        //     backgroundColor: '#FDFDFD',
                        //     padding: '5% 5% 5% 5%',
                        //     width: '100%',
                        //     marginTop:'5%',
                        //     gap:"10px"
                        // }}
                    >
                        
                        <Typography
                            variant="h4"
                            sx={{

                                // fontWeight: { sm: '400', md: '500', lg: '510',xl:'510' },
                                // fontSize: {xs:'10px', sm: '11px', md: '18px', lg: '20px',xl:'20px' },
                               fontSize:"20px",
                                lineHeight: { sm: '22px', md: '25px', lg: '28px',xl:'28px' },
                                color: '#585858',

                            }}
                        >
                            Need more help?
                        </Typography>
                        <Typography
                            variant="h6"
                            sx={{


                                fontSize: '15px',
                                lineHeight: '28px',
                                color: '#5F6368',
                                fontWeight: '400',
                                mt: '4%',
                                mb: '4%'
                            }}
                        >
                            Browse tips and step-by-step guides made for both new
                            users and power users.
                        </Typography>
                        <Box
                            sx={{
                                display: 'flex',
                                flexDirection: 'row',
                                justifyContent:'space-between',
                                // mt: '5%',
                                // mb: '2%',
                            }}>
                            <Link to="/contact-us" className='contbtnStart' style={{ textDecoration: 'none', width: '45%' }}>
                                <Button className='contact_btn'
                                    variant="outlined"
                                    sx={{

                                        borderColor: '#DADCE0',
                                        fontSize: '16px',
                                        lineHeight: '24px',
                                        color: '#16469D',
                                        fontWeight: '500',
                                        textTransform: 'none',
                                            width:"100%",
                                            height:"45px",
                                        '&:hover': { borderColor: '#16469D' },
                                    }}
                                >

                                    Talk to us
                                </Button>
                            </Link>
                            <a href="https://api.whatsapp.com/send?phone=9321714104&text=I%20am%20interested!" className='contbtnStart' target="_blank" style={{ width: '45%' }}>

                                <Button className='contact_btn '
                                    variant="outlined"
                                    sx={{

                                   
                                        borderColor: '#DADCE0',
                                        fontSize: '16px',
                                        lineHeight: '24px',
                                        color: '#16469D',
                                        fontWeight: '500',
                                        textTransform: 'none',
                                        width:"100%",
                                        height:"45px",
                                        '&:hover': { borderColor: '#16469D' },
                                    }}
                                >
                                    Ping us
                                </Button>
                            </a>
                        </Box>
                    </Box>
                </Box>
                <Box className='AccordianStart'
                    // sx={{
                    //     display: 'flex',
                    //     flexDirection: 'column',
                    //     width: { xs: '90%', md: '40%', lg: '45%', xl: '55%' },
                    //     height:'300px',
                    //     position:'relative',
                    //     marginTop:'4%',

                    // }}
                >
                    <div className="accordion">
                    {/* <Typography
                        variant="h2"
                        sx={{
                            width: '65%',
                            display: 'flex',
                            fontSize: '30px',
                            lineHeight: '-0.43px',
                            color: '#585858',
                            fontWeight: '700',
                            mb: '3%',
                            color: '#585858',
                        }}
                    >
                        Find the answers you need
                    </Typography> */}
                        {faqData.map((item, i) =>
                            // item.count <= 3 ? (
                            <div className="acordRow">
                                <div
                                    className="accordhead"
                                    onClick={() => toggle(i)}
                                >
                                    <p>{item.faqQue}</p>
                                    <span className="drop">
                                        {selected === i ? <KeyboardArrowUpIcon/> : <KeyboardArrowDownIcon/>}
                                    </span>
                                </div>
                                {  selected === i && <div className='accordBody'
                                // style={{
                                //     fontWeight: {xs:'400', sm: '400', md: '400', lg: '500' },
                                //     fontSize: {xs:'11px', sm: '12px', md: '12px', lg: '15px' },
                                //     lineHeight: {xs:'20px', sm: '21px', md: '21px', lg: '21px' },
                                //     color: 'rgba(88, 88, 88, 1)',
                                // }}
                                //     className={
                                //         selected === i ? `answer` : `content`
                                //     }
                                >
                                    {item.faqAns}
                                </div>}
                            </div>
                            // ) : null
                        )}


                       



                    </div>
                    <Link to="/faq" className="AccBtn" style={{ textDecoration: 'none' }}>
                            <Button
                                variant="outlined"
                                // sx={{
                                //     borderColor: '#16469D',
                                //     color: '#16469D',
                                //     textTransform: 'none',
                                //     position:'absolute',
                                //     bottom:'0',
                                //     fontWeight: { sm: '400', md: '400', lg: '400' },
                                //     fontSize: { sm: '12px', md: '12px', lg: '15px' },
                                //     lineHeight: { sm: '21px', md: '21px', lg: '21px' },
                                //     '&:hover': { borderColor: '#16469D' },
                                // }}
                                className='accBtn'>
                                Show More
                            </Button>
                        </Link>
                </Box>
            </Box>
        </div>
    )
}

export default FaqRevamp
