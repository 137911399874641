import React from 'react'
import { Box, Button, Typography } from '@mui/material';
import { Image } from 'cloudinary-react'
import './scss/AllVerification.css'
import { Link } from 'react-router-dom';
// import "../AllVerification/scss/AllVerification.css"
function AllVerification() {
  return (
    <div className='Firstclass'>
        <Box className='secondMainClass'>
        <Image style={{cursor:'pointer',}} publicId="https://res.cloudinary.com/duzmzc074/image/upload/v1689399553/animation_640_lj5kpmcf_1_c1iszu.svg" cloudName="duzmzc074" />

        
        </Box>
        <Box className='secondMainText'>
        <Typography variant='h1'>
        Your account is currently under verification.<br />
Feel free to explore our services in the meantime!
        </Typography>
        <Link to='/Home'>
        <Button variant='contained' className='buttonClass'>Explore
        </Button>
        </Link>
        </Box>
    </div>
  )
}

export default AllVerification
