import React from 'react'
import { Box, Typography } from '@mui/material'
import "./sass/Footer.css"
import { Image } from 'cloudinary-react'
import { Link } from 'react-router-dom'
import { isLoggedIn } from 'auth'
import { NavLink, useNavigate } from 'react-router-dom'


const Footer = () => {
    const runAlert = (e) => {
        if (!isLoggedIn()) {
            alert('Please login.')
        }
    }
    return (
        <div className='FooterMain'>
            <div className='FooterStart'>
                <div className='FooterContainer1'>
                    <Box className="FooterBox1">
                        <Box className="FooterIcon">
                            <NavLink to="/home">
                                <Image publicId="https://res.cloudinary.com/dxluokypg/image/upload/v1683263520/white_1_1_rxdrtj.svg" cloudName="dxluokypg" />
                            </NavLink>
                        </Box>
                        <Typography
                        //  style={{textAlign: "justify"}} 
                         variant='h6'>Kamdhenu Commerz , 401 , 4TH FLOOR, Sector 14, Kharghar, Navi Mumbai, Maharashtra 410210</Typography>


                    </Box>
                    <div className="FooterBox2">
                    <Box className="FooterBox">
                        <Typography variant='h4'>Company</Typography>
                        <label>
                            <ul>
                                <Link to="/" style={{ textDecoration: 'none' }}><li>Home</li></Link>
                                <Link to="/about-us" style={{ textDecoration: 'none' }}><li>About Us</li></Link>
                                <Link to="/partner-with-us" style={{ textDecoration: 'none' }}><li>Partners</li></Link>
                                <Link to="/product-catalogue" style={{ textDecoration: 'none' }}><li>Product catalogue</li></Link>
                            </ul>
                        </label>
                    </Box>
                    <Box className="FooterBox">
                        <Typography variant='h4'>Help</Typography>
                        <label>
                            <ul>
                                <Link to="/faq" style={{ textDecoration: 'none' }}><li>FAQs</li></Link>
                                <Link to="/contact-us" style={{ textDecoration: 'none' }}><li>Contact us</li></Link>
                                <Link to={isLoggedIn() ? "/feedback" : '/'} style={{ textDecoration: 'none', color: '#656363' }}
                                    onClick={(e) => runAlert(e)}> <li>Feedback</li>
                                </Link>
                            </ul>
                        </label>
                    </Box>
                    <Box className="FooterBox">
                        <Typography variant='h4'>Media</Typography>
                        <label>
                            <ul>
                                <Link to="/coming-soon" style={{ textDecoration: 'none' }} target="_blank"><li>Blogs</li></Link>
                                <a href='https://www.kjsscosmos.com/media' target="_blank" style={{ textDecoration: 'none'}}><li>News Articles</li></a>
                                <Link to="/coming-soon" style={{ textDecoration: 'none', }} target="_blank"><li>Press releases</li></Link>
                            </ul>
                        </label>
                    </Box>
                    <Box className="FooterBox">
                        <Typography variant='h4'>Career</Typography>
                        <label>
                            <ul>
                         
                            <a href='https://www.kjsscosmos.com/careers' target="_blank" style={{ textDecoration: 'none' }}><li>Job opening</li></a>
                                <a href='https://www.kjsscosmos.com/careers' target="_blank" style={{ textDecoration: 'none'  }}><li>Job application</li></a>
                            </ul>
                        </label>
                    </Box>
                    <Box className="FooterBox ">
                        <Typography variant='h4'>Contact us</Typography>
                        <label>
                            <ul>
                                <a href="https://api.whatsapp.com/send?phone=9321714104&text=I%20am%20interested!" target="_blank" style={{ textDecoration: 'none' }}><li>+91 9321714104</li></a>
                                <a href="mailto:info@kjssteel.com" target='blank' style={{ textDecoration: 'none' }}><li>info@kjssteel.com</li></a>
                            </ul>
                        </label>
                    </Box>
                    <Box className='FooterBox FooterBoxHide'>
                        {/* <Box className={FootStyle.FooterBox} > */}
                            <Typography variant='h4' sx={{display:'flex',marginBottom:'-5%'}}>Legal</Typography>
                            <label style={{paddingBottom:'0'}}>
                                <ul>
                                <Link target='_blank' to='/disclaimer' style={{ textDecoration: 'none' }} ><li>Disclaimer</li></Link>
                                <Link to='/privacy' style={{ textDecoration: 'none' }} target="_blank"><li>Privacy Policy</li></Link>
                                <Link to='/terms' style={{ textDecoration: 'none' }} target="_blank"><li>Terms of Service</li></Link>
                                </ul>
                            </label>
                        </Box>
                    </div>
                </div>
                <div className='FooterContainer2'>
                    <Box className='FooterDownBox1'>
                        <Typography variant='h6'>Follow us on</Typography>

                        <Box className="SocialIcons">
                            <a href="https://www.linkedin.com/in/kjss-cosmos-pvt-ltd-056613258/" target="_blank">
                                <Image publicId="https://res.cloudinary.com/dxluokypg/image/upload/v1680581659/LinkedIn_qyemfg.svg" cloudName="dxluokypg" />
                            </a>

                            <a href="https://twitter.com/KjssCosmos" target="_blank">
                                <Image publicId="https://res.cloudinary.com/dxluokypg/image/upload/v1680581659/Twitter_ujajbb.svg" cloudName="dxluokypg" />
                            </a>
                            <a href="https://www.instagram.com/pr.kjsscosmospvtltd/" target="_blank">
                                <Image publicId="https://res.cloudinary.com/dxluokypg/image/upload/v1680581659/Instagram_cebpv7.svg" cloudName="dxluokypg" />
                            </a>

                            <a href="https://www.facebook.com/profile.php?id=100086595487175" target="_blank">
                                <Image publicId="https://res.cloudinary.com/dxluokypg/image/upload/v1680581658/Facebook_d6ws5h.svg" cloudName="dxluokypg" />
                            </a>

                        </Box>

                        <Box className='footercopyright'>&copy;  KJSSTEEL Pvt. Ltd.</Box>

                    </Box>
                    <Box className="footertos">
                            <Link target='_blank' to='/disclaimer' style={{ textDecoration: 'none' }}>
                            <span style={{color: '#c8c4c4'}}>Disclaimer</span>
                            </Link>
                            <span>|</span>
                            <Link to='/privacy' target='_blank' style={{ textDecoration: 'none' }}>
                                <span style={{color: '#c8c4c4'}}>Privacy Policy</span>
                            </Link>
                            <span>|</span>
                            <Link to='/terms' target='_blank' style={{ textDecoration: 'none' }}>
                                <span style={{color: '#c8c4c4'}}>Terms of Service</span>
                            </Link>
                    </Box>
                </div>
            </div>
        </div>
    )
}

export default Footer