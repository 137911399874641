import ContactUs from 'components/ContactUs/ContactUs'
import ContactUs2 from 'components/ContactUs/ContactUs2'
import ContactUsSchedule from 'components/ContactUsSchedule/ContactUsSchedule'
import Footer from 'components/Footer/Footer'
import NavBar from 'components/NavBar/NavBar'
import React from 'react'
import { Image } from 'cloudinary-react'
import { Helmet } from "react-helmet";
const ContactUsPage = () => {
  return (
    <>
          <Helmet>
                <html lang="en" />
                <title>Contact-us | kjssteel | kjs steel</title>
                <meta name="description" content="kjssteel strive to provide you with the best solutions to realize your project goals. So, contact us today and let us help you conceive your Steel Plant EPC project." />
                <meta name="title" content="contact-us | kjssteel | kjs steel" />
                <meta name="keywords" content="contact-us | kjssteel | kjs steel" />

            </Helmet>
    <div>
        <NavBar/>
        <ContactUs/>
        {/* <ContactUs2/> */}
        <ContactUsSchedule/>
        <Footer/>

        
<div style={{position: 'fixed', bottom: '2rem', right: '2rem', zIndex: '999', backgroundImage: 'none', background: 'none', boxSizing: 'none', borderStyle: 'none',cursor:'pointer'}}>
                <a href="https://api.whatsapp.com/send?phone=9321714104&text=I%20am%20interested!" target="_blank">
                    <Image style={{height: '7vh', width: '7vh',cursor:'pointer',}} publicId="https://res.cloudinary.com/dxluokypg/image/upload/v1680763106/whatsapp_gqgneb.png" cloudName="dxluokypg" />
                </a>
            </div>
    </div>
    </>
  )
}

export default ContactUsPage