import React, { useEffect } from 'react'
import axios from 'axios'
import { useLocation, useParams, useNavigate } from 'react-router-dom'
import { doLogin } from 'auth'
import { Image } from 'cloudinary-react'
import RegSuccess from '../../assets/images/RegisterSuccess.gif'


const VerifyEmail = () => {

  const navigate = useNavigate()
  const location = useLocation()
  const searchParams = new URLSearchParams(location.search)
  const verificationCode = searchParams.get('verificationCode')
  // console.log(verificationCode)

  useEffect(() => {
    const verifyEmail = async () => {
      try {
        // console.log('hello')
        const response = await axios.post(`${process.env.REACT_APP_STEELADMIN}/api/auth/verifyEmail/${verificationCode}`).then((response)=>{
          // console.log(response.data.statusString)
          // console.log(response.data)
          if (response.status=== 200) {
            doLogin(response.data, () => {
              navigate('/home',)
              // console.log("hey",response.data);
            })
          }
          else
          {
            navigate('/Signin',)
          }
        }).catch((err)=>{
          // console.log(err.response.status)
          navigate('/Signin',)
          
        })
        // alert(response.data.statusString)
      
      } catch (error) {
        // alert('Could not verify')
      }
    }
    verifyEmail()
  }, [verificationCode])
  return (
    <div>
      {/* <p>
        
     
      hello
      </p> */}
      <div style={{position: 'fixed', bottom: '2rem', right: '2rem', zIndex: '999', backgroundImage: 'none', background: 'none', boxSizing: 'none', borderStyle: 'none',cursor:'pointer'}}>
                <a href="https://api.whatsapp.com/send?phone=9321714104&text=I%20am%20interested!" target="_blank">
                    <Image style={{height: '7vh', width: '7vh',cursor:'pointer',}} publicId="https://res.cloudinary.com/dxluokypg/image/upload/v1680763106/whatsapp_gqgneb.png" cloudName="dxluokypg" />
                </a>
            </div>
    </div>
  )
}

export default VerifyEmail