import AboutUsPageBG from "components/AboutUsPageBG/AboutUsPageBG"
import AboutUsPageContent from "components/AboutUsPageContent/AboutUsPageContent"
import AboutUsPageNewletter from "components/AboutUsPageNewsletter/AboutUsPageNewletter"
import NavBar from 'components/NavBar/NavBar'
import Footer from 'components/Footer/Footer'
import { Image } from 'cloudinary-react'
import { Helmet } from "react-helmet";

const AboutUsPage = () => {
    return (
        <>
          <Helmet>
                <html lang="en" />
                <title>Knowing Judging & Sourcing Steel | Steel Plant EPC | Steel plant Project execution.</title>
                <meta name="description" content="kjs Steel is a leading EPC Services Company with expertise in Steel Plant EPC services. Our team of experienced professionals possesses extensive expertise and experience in this field" />
                <meta name="title" content="Knowing Judging & Sourcing Steel | Steel Plant EPC | Steel plant Project execution." />
                <meta name="keywords" content="Knowing Judging & Sourcing Steel | Steel Plant EPC | Steel plant Project execution." />

            </Helmet>
           <div>
            <NavBar/>
           <AboutUsPageBG/>
           <AboutUsPageContent/>
           <AboutUsPageNewletter/>
           <Footer/>

           <div style={{position: 'fixed', bottom: '2rem', right: '2rem', zIndex: '999', backgroundImage: 'none', background: 'none', boxSizing: 'none', borderStyle: 'none',cursor:'pointer'}}>
                <a href="https://api.whatsapp.com/send?phone=9321714104&text=I%20am%20interested!" target="_blank">
                    <Image style={{height: '7vh', width: '7vh',cursor:'pointer',}} publicId="https://res.cloudinary.com/dxluokypg/image/upload/v1680763106/whatsapp_gqgneb.png" cloudName="dxluokypg" />
                </a>
            </div>
           </div>
           </>
    )
}

export default AboutUsPage
