import axios from "axios";

import{
    setLoading,
    setError,
    setSuccess,
} from './dataSlice'

export const partner = (userData) => async (dispatch) => {
    dispatch(setLoading(true))
    try{
        const response = await axios.post(`${process.env.REACT_APP_STEELADMIN}/partnerUs/addPartner`, userData)
        dispatch(setSuccess(true))
        // alert(response.data)
    } catch (error) {
        dispatch(setError(error.message))
    } finally {
        dispatch(setLoading(false))
    }
}