import React, { useState, useEffect } from 'react';
import "./scss/VendorRegister.css";
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Slider from "react-slick";
import { Typography } from '@mui/material';
import { TextField } from '@mui/material';
import Autocomplete from '@mui/material/Autocomplete';
import { countries } from "./VendorData";
import { useNavigate, useLocation, useParams } from 'react-router-dom';
import axios from "axios";
import useBackdrop from 'hooks/useBackdrop';

const venderOptions = [
  { value: 'buy', label: 'Buyer' },
  { value: 'sell', label: 'Seller' },
  { value: 'us', label: 'User' },
];

const venderSlide = [
  {
    id: 1,
    linkImg: 'https://res.cloudinary.com/dtffniutw/image/upload/v1689518380/Mockup_1_1_vilx0a.svg',
  },
  {
    id: 2,
    linkImg: 'https://res.cloudinary.com/dtffniutw/image/upload/v1689518297/Mockup_PSD_FREE___iPad_Pro___Pencil_2018-removebg-preview_1_bnh73w.svg',
  },
  {
    id: 3,
    linkImg: 'https://res.cloudinary.com/dtffniutw/image/upload/v1689518380/Mockup_1_1_vilx0a.svg',
  },
  {
    id: 4,
    linkImg: 'https://res.cloudinary.com/dtffniutw/image/upload/v1689518297/Mockup_PSD_FREE___iPad_Pro___Pencil_2018-removebg-preview_1_bnh73w.svg',
  },
];

const VendorRegister = () => {
  const { openBackdrop, closeBackdrop, BackdropComponent } = useBackdrop();
  var settings = {
    fade: true,
    dots: true,
    arrows: false,
    infinite: true,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 2000,
    cssEase: 'cubic-bezier(0.7, 0, 0.3, 1)',
  };

  const navigate = useNavigate();
  const location = useLocation();
  const [checkData, setCheckData] = useState(null);
  const { businessData } = location.state;
  const { businessEmail } = useParams();
  const [selectedCountry, setSelectedCountry] = useState(null);
  const [data, setData] = useState({
    password: "",
    businessEmail: "",
    name: "",
    website: "",
    country: '',
    businessAddress: '',
    gstNo: '',
    panNo: '',
    vatNo: '',
  });

  useEffect(() => {
    if (businessData) {
      setData((prev) => ({
        ...prev,
        password: businessData.password,
        businessEmail: businessData.businessEmail,
        name: businessData.name,
        website: businessData.website,
      }));
    }
  }, [businessData]);

  const handleChange = (event) => {
    const { name, value } = event.target;
    setData((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };


  const handleCountryChange = (event, newValue) => {

    setSelectedCountry(newValue);
    setData((prevState) => ({
      ...prevState,
      country: newValue.label,
    }));
    
  };



  const handleRegister = async (e) => {
    e.preventDefault();
    openBackdrop();
    // Check if all required fields are filled
    if (
      data.country.trim() !== '' &&
      data.businessAddress.trim() !== '' &&
      data.gstNo.trim() !== '' &&
      data.vatNo.trim() !== '' &&
      data.panNo.trim() !== ''
    ) {
      try {
        const response = await axios.post(
          `${process.env.REACT_APP_STEELADMIN}/vendor/vendorRegister/${businessEmail}`,
          data,
          {
            headers: {
              'Content-Type': 'application/json',
            },
          }

        ).then((res) => {

          if (res.status === 201) {
            console.log("went in if")
            navigate('/allverification');
            closeBackdrop()
          } else {
            console.log("went in else")
            closeBackdrop()
          }

          closeBackdrop()
        })

      } catch (err) {
        closeBackdrop()

        // console.log(err);
      }
    }
    else {
      closeBackdrop()
      console.log('Please fill all required fields.');
      return;
    }



  };

  const getData = async () => {
    try {
      const res = await axios.get(`${process.env.REACT_APP_STEELADMIN}/form/GetAllFormCheck`).then((res) => {
        setCheckData(res.data.map((data) => {
          let per = JSON.parse(data.permission);

          return {
            ...data,
            permission: per,
          };
        }));
      });
    } catch (error) {
      // console.log(error);
    }
  };

  useEffect(() => {
    getData();
  }, []);

  return (
    <div className='VendorMainvenDOR'>
      <div className='VendorStartVenDOR'>
        {checkData != null && (
          <form className='VendorBoxvender' action='' onSubmit={handleRegister}>
            <Box className='VendOrFormvendOr'>
              <div className='VendorBox1Vendor'>
                <h1>Welcome, let's create a free Seller account</h1>
              </div>
              {checkData[5]?.permission[0] && (
                <>
                  <Autocomplete
                    className='countryinput'
                    id='country-select-demo'
                    options={countries}
                    value={selectedCountry}
                    onChange={handleCountryChange}
                    autoHighlight
                    getOptionLabel={(option) => option.label}
                    renderOption={(props, option) => (
                      <Box component='li' sx={{ '& > img': { mr: 2, flexShrink: 0 } }} {...props}>
                        <img
                          loading='lazy'
                          width='20'
                          src={`https://flagcdn.com/w20/${option.code.toLowerCase()}.png`}
                          srcSet={`https://flagcdn.com/w40/${option.code.toLowerCase()}.png 2x`}
                          alt='Flagcdn'
                        />
                        {option.label} ({option.code}) +{option.phone}
                      </Box>
                    )}
                    renderInput={(params) => (
                      <TextField
                        required
                        {...params}
                        label='Choose a country'
                        {...(checkData[5].permission[1] ? { required: 'required' } : {})}
                        inputProps={{
                          ...params.inputProps,
                          autoComplete: 'new-password',
                        }}
                      />
                    )}
                  />
                </>
              )}

              <div className='VendorNumberBox'>
                {checkData[6]?.permission[0] && (
                  <>
                    <label>Address</label>
                    <textarea
                      required
                      {...(checkData[6].permission[1] ? { required: 'required' } : {})}
                      name='businessAddress'
                      value={data.businessAddress}
                      onChange={handleChange}
                    />
                  </>
                )}
                {checkData[7]?.permission[0] && (
                  <>
                    <label>GST</label>
                    <input
                      required
                      type='text'
                      name='gstNo'
                      {...(checkData[7].permission[1] ? { required: 'required' } : {})}
                      value={data.gstNo}
                      onChange={handleChange}
                      placeholder='GST'
                    />
                  </>
                )}
                {checkData[8]?.permission[0] && (
                  <>
                    <label>VAT NO</label>
                    <input
                      required
                      type='text'
                      name='vatNo'
                      {...(checkData[8].permission[1] ? { required: 'required' } : {})}
                      value={data.vatNo}
                      onChange={handleChange}
                      placeholder='Your VAT number'
                    />
                  </>
                )}
                {checkData[9]?.permission[0] && (
                  <>
                    <label>PAN NO</label>
                    <input
                      required
                      type='text'
                      name='panNo'
                      {...(checkData[9].permission[1] ? { required: 'required' } : {})}
                      value={data.panNo}
                      onChange={handleChange}
                      placeholder='Your PAN number'
                    />
                  </>
                )}
              </div>
              <Button variant='contained' type='submit' >
                Register
              </Button>
            </Box>
          </form>
        )}

        <Box className='Vendorsecound'>
          <Box className='VendorBoxStart2'>
            <Slider {...settings}>
              {venderSlide.map((item) => (
                <div className='VendorMediaBox2'>
                  <img src={item.linkImg} alt='Computer' />
                </div>
              ))}
            </Slider>
          </Box>
          <div className='VendorUSerBOxInfo'>
            <Typography variant='h4' style={{ marginBottom: '1.5%' }}>
              Join the Steel Revolution!
            </Typography>
            <Typography variant='h6'>
              Shop from more than 2000 trusted suppliers <br />for over 3000+ products
            </Typography>
          </div>
        </Box>
      </div>
      <BackdropComponent />
    </div>
  );
};

export default VendorRegister;
