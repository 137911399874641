import { Card, CardActionArea, CardContent, Grid, Typography } from '@mui/material'
import { Box } from '@mui/system'
import { isLoggedIn } from 'auth'
import axios from 'axios'
import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import { Image } from 'cloudinary-react';

import "../ProductsLandingPage/scss/ProductsLandingPage.css"

const ReadMore = ({ children }) => {
    const text = children;
    const [isReadMore, setIsReadMore] = useState(true);
    const toggleReadMore = () => {
        setIsReadMore(!isReadMore);
    };
    return (
        <p className="text" >
            {isReadMore ? text.slice(0, 405) : text}
            <span onClick={toggleReadMore} className="read-or-hide" style={{ color: '#16469D', cursor: 'pointer' }}>
                {isReadMore ? " Read more" : " Show less"}
            </span>
        </p>
    );
};

const handleCardClick = (portalLink) => {
    if (!isLoggedIn()) {
        alert("Please login to continue");
    } else if (!portalLink) {
        alert("This feature is coming soon.");
    }
};

const ProductsLandingPage = () => {
    const [data, setData] = useState([])

    const getPortalData = async () => {
        try {
            const res = await axios.get(`${process.env.REACT_APP_STEELADMIN}/mainportal/getAllPortals`)
            setData(res.data)
        } catch (error) {
            // console.log(error)
        }
    }


    useEffect(() => {
        getPortalData()
    }, [])

    return (
        <div className='ourProds'>
            <div>

           
            <Box 
            // sx={{ mt: '6%', mx: '7%' }}
            >
                <Typography variant='h4' sx={{ display: 'flex', flexWrap: 'wrap', alignItems: 'center', fontWeight: '700', fontSize: '40px', lineHeight: '42px', letterSpacing: '6%', color: '#585858 !important' }}>Our Products</Typography>


                <Typography variant='h6' color=' #585858' sx={{ display: 'flex',textAlign:"justify", flexWrap: 'wrap', alignItems: 'center', fontWeight: '400', fontSize: '15px', lineHeight: '23px', letterSpacing: '6%', width: '100%', py: '0.800rem' }}>

                    <ReadMore>
                    Our KJSS Steel site offers you a range of value-added EPC services, as highlighted in the portal. With our extensive experience in the steel industry, we are well-equipped to provide customized steel guidance on design, material selection, and all steel-related techniques that meet their specific requirements. Our team of experts is committed to delivering exceptional customer service and support 24 hours a day. We work closely with our clients to understand their needs and provide tailored solutions that meet their requirements while staying within budget and timelines. Our commitment to sustainability is evident in all our products and services. We prioritize the use of eco-friendly and energy-efficient technologies and materials, and we continuously strive to minimize our environmental impact. Our dedication to quality, customer service, and sustainability is to earn us a reputation as a reliable and trusted supplier of steel products and services. Thank you for visiting our product web page. Please feel free to contact us to learn more about how we can help you achieve your goals in the steel industry.
                    </ReadMore>

                </Typography>
            </Box>
            <div style={{ width: "100%", display: "flex", justifyContent: "center" }}>


                <Box className='CardmainPro'>
                          {data.map(
                        (card, index) =>
                        (
                         
                            <Link
                                style={{ textDecoration: 'none' }}
                                to={isLoggedIn() ? card.portal.portalLink ? card.portal.portalLink : '/coming-soon' : '/Signin'}
                                target='blank'
                                className={index === 7 ? 'lastItemCard' : ""}
                            >
                                <Card className='CardStart'
                                    sx={{
                                      
                                        width: '100%',
                                        height: '100%',
                                        boxShadow: 'none',
                                        background: `none, url(${`${process.env.REACT_APP_STEELADMIN}/mainportal/file/${card.portalImageName}`})`,
                                     
                                        backgroundRepeat: 'no-repeat',
                                        backgroundPosition: 'center',
                                        backgroundSize: 'cover',
                                      
                                    }}
                                >
                                    <CardActionArea className="cardMedia">
                                        <CardContent sx={{ mt: '30%' }}>
                                            <Typography
                                                gutterBottom
                                                variant="h6"
                                                component="div"
                                                sx={{
                                                    color: '#ffffff',
                                                    fontWeight: '450',
                                                    fontSize: 'clamp(16px,17px,19px)',
                                                }}
                                            >
                                                {card.portal.portalName}
                                            </Typography>
                                            <Typography
                                                variant="body3"
                                                sx={{
                                                    color: '#ffffff',
                                                    fontWeight: '270',
                                                    fontSize: '12',
                                                }}
                                            >
                                                {card.portal.portalDescription.substr(
                                                    0,
                                                    25
                                                ) + '...'}
                                            </Typography>
                                        </CardContent>
                                    </CardActionArea>
                                </Card>
                              
                            </Link>

                        
                        )

                    )}
                   

                </Box>
            </div>
            </div>
        </div >
    )
}

export default ProductsLandingPage