import React from 'react'
import Box from '@mui/material/Box';
import { Typography } from '@mui/material'
import { Button } from '@mui/material'
import aadhaar1 from '../../assets/images/aadhaar1.svg'
import { display, width } from '@mui/system';
import './scss/RateContract.css'
import Group from '../../assets/images/Group.svg'
import GroupMsg from '../../assets/images/GroupMsg.svg'
import GroupInternet from '../../assets/images/GroupInternet.svg'
import NavBar from 'components/NavBar/NavBar';
import Footer from 'components/Footer/Footer';
import { Link, useLocation } from 'react-router-dom';
import LanguageIcon from '@mui/icons-material/Language';
import CallIcon from '@mui/icons-material/Call';
import EmailIcon from '@mui/icons-material/Email';
import { Image } from 'cloudinary-react'

function RateContractPage() {

  const location = useLocation()
  const data = location.state
  // console.log(data)

  return (
    <div> <NavBar />
      <div className='RateContractMainPage'>
        <Box className='MainPage'
          sx={{
            my: '5%',
            mx: '7%'
          }}>
          <div className='box1'
            style={{
              display: 'flex',
              flexDirection: 'row'
            }}>

        

            <div className='abs'
              style={{
                display: 'flex',
                flexDirection: 'column',
                width: '70%',
                marginLeft: '3%'
              }}>
              <Typography variant='h2'
                sx={{
                  fontSize: { xs: '18px', md: '24px', lg: '24px' },
                  fontWeight: '600',
                  mb: '2%',
                  color:'#585858',
                  lineHeight:'21px',
                  fontFamily: `system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif`
                }}>{data.banner.bannerName}</Typography>
              <Typography variant='h6'
                sx={{
                  fontSize: '14px',
                  fontWeight:'400',
                  mb: '2%',
                  color:'#585858',
                  fontFamily: `system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif`
                }}>{data.banner.bannerDescription}</Typography>
                <Link to={data.banner.bannerBtnLink ? data.banner.bannerBtnLink : '/coming-soon'} style={{textDecoration: 'none'}}>
              <Button variant="contained" sx={{
                width: '150px',
                backgroundColor: '#16469D',
                "&:hover": { backgroundColor: "#2458B7" },
                fontFamily: `system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif`
              }}
              >Visit now</Button></Link>
            </div>
          </div>

          <div className='ContactUsMainPage'>

            <div>
              <Typography variant='h1'
                sx={{
                  fontSize: '22px',
                  fontWeight: '600',
                  mt: '40px',
                  ml: '3%',
                  color: '#585858',
                  fontFamily:`system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif`
                }}>Contact Us
              </Typography>
            </div>

            <Box className='contactMain'
              sx={{
                display: 'flex',
                width: { xs: '100%', lg: '50%' },
                flexDirection: { xs: 'column', lg: 'row' },
                textAlign: { xs: 'start', lg: 'center' },
                alignItems: { xs: 'start', lg: 'center' },
                justifyContent: 'space-around',
                color: '#585858'
              }}>

              <Box className='ContactUs'
                sx={{
                  display: 'flex',
                  justifyContent: 'center',
                  alignContent: 'start',
                  flexDirection: { xs: 'row', lg: 'column' },
                  alignItems: 'center',
                  mt: '40px',
                  ml: { xs: '3%', lg: '0%' }
                }}>
                  <div className='rateIcon'>
                  <CallIcon />
                  </div>
                <Typography className='Typo'
                  sx={{
                    mt: { lg: '20%' },
                    ml: { xs: '10px' },
                    fontFamily: `system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif`
                  }}>+91 9321714104</Typography>
              </Box>

              <Box className='ContactUs'
                sx={{
                  display: 'flex',
                  textAlign: 'center',
                  justifyContent: 'center',
                  flexDirection: { xs: 'row', lg: 'column' },
                  alignItems: 'center',
                  mt: '40px',
                  ml: { xs: '3%' }
                }}>
                  <div className='rateIcon'>
                  <EmailIcon />
                  </div>
                <Typography className='Typo'
                  sx={{
                    mt: { lg: '20%' },
                    ml: { xs: '3%' },
                    fontFamily: `system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif`
                  }}>info@kjssteel.com</Typography>
              </Box>

              <Box className='ContactUs'
                sx={{
                  display: 'flex',
                  textAlign: 'center',
                  justifyContent: 'center',
                  flexDirection: { xs: 'row', md: 'row', lg: 'column' },
                  alignItems: 'center',
                  mt: '40px',
                  ml: { xs: '3%' }
                }}>
                  <div className='rateIcon'>
                  <LanguageIcon/>
                  </div>
                {/* <img src={GroupInternet} alt="KJSSteelLogo" /> */}
                <Typography className='Typo'
                  sx={{
                    mt: { lg: '10%' },
                    ml: { xs: '3%' },
                    fontFamily: `system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif`
                  }}>{data.banner.bannerBtnLink ? data.banner.bannerBtnLink : 'Coming Soon'}</Typography>
              </Box>

            </Box>
          </div>
          <div className='working-hours' >
            <Box sx={{ ml: '3%' }}>
              <Typography variant='h3'
                sx={{
                  fontSize: '18px',
                  fontWeight: '510',
                  paddingBottom:'1%',
                  mt: '5%',
                  color: '#585858',
                  fontFamily: `system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif`
                }}>Working Hours:
              </Typography>
              <Typography variant='h6'
                sx={{
                  lineHeight: '3',
                  fontSize:'15px',
                  lineHeight:'22px',
                  fontWeight:'400',
                  color: '#585858',
                  fontFamily: `system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif`
                }}>Monday to Saturday (09:00 AM - 06:00 PM)
              </Typography>
            </Box>

            <Box className='RateBox'
              sx={{
                width: '40%',
                ml: '3%'
              }}>
              <Typography variant='h5'
                sx={{
                  fontSize: '18px',
                  fontWeight: '510',
                  my: '3%',
                  color: '#585858',
                  fontFamily: `system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif`
                }}>Address:
              </Typography>
              <Typography variant='h6'
              style={{textAlign: "justify"}}
                sx={{
                  fontSize:'15px',
                  lineHeight:'22px',
                  fontWeight:'400',
                  color: '#585858',
                  fontFamily: `system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif`
                }}>Kamdhenu Commerz Commercial Business Park, 401 , 4TH FLOOR, Block J,
                Raghunath Vihar, Sector 14, Kharghar, Navi Mumbai, Maharashtra 410210
              </Typography>
            </Box>
          </div>

        </Box>
      </div>
      <Footer />


      <div style={{position: 'fixed', bottom: '2rem', right: '2rem', zIndex: '999', backgroundImage: 'none', background: 'none', boxSizing: 'none', borderStyle: 'none',cursor:'pointer'}}>
                <a href="https://api.whatsapp.com/send?phone=9321714104&text=I%20am%20interested!" target="_blank">
                    <Image style={{height: '7vh', width: '7vh',cursor:'pointer',}} publicId="https://res.cloudinary.com/dxluokypg/image/upload/v1680763106/whatsapp_gqgneb.png" cloudName="dxluokypg" />
                </a>
            </div>
    </div>



  )
}

export default RateContractPage



















